import React from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Input,
  Card,
  CardBody,
  Label,
} from "reactstrap";
import { Link } from "react-router-dom";
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import FeatherIcon from "feather-icons-react";
import InputLabel from '@material-ui/core/InputLabel';

import Typography from '@mui/material/Typography';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Switch from '@mui/material/Switch';
import WifiIcon from '@mui/icons-material/Wifi';
import BluetoothIcon from '@mui/icons-material/Bluetooth';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';

import fire from "../Fire.js";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

function useProfile() {
  const [profile, setProfile] = React.useState({});
  const user = fire.auth().currentUser;

  React.useEffect(() => {
    const unsubscribe = fire
    .firestore()
    .collection("users")
    .doc(user.uid)
    .onSnapshot(document => {
      setProfile(document.data());
    });
    return () => unsubscribe;
  }, [user.uid]);
  
  return profile;
};

function useStore() {
  const [store, setStore] = React.useState({});

  const user = fire.auth().currentUser;

  React.useEffect(() => {
    const unsubscribe = fire
    .firestore()
    .collection("users")
    .doc(user.uid)
    .collection("stores")
    .onSnapshot(querySnapshot => {
      querySnapshot.forEach(doc => {
        fire
        .firestore()
        .collection("users")
        .doc(user.uid)
        .collection("stores")
        .doc(doc.id)
        .collection("shippingMethods")
        .onSnapshot(collection => {
          let shippingMethods = [];
          collection.forEach(doc => {
            shippingMethods.push(Object.assign(doc.data(), {id: doc.id,}));
          });
          setStore(Object.assign(doc.data(), {storeId: doc.id, shippingMethods: shippingMethods}));
        });
      })
    });
    return () => unsubscribe;
  }, [user.uid]);
  
  return store;
};


const Shipping = props => {
  const user = fire.auth().currentUser;
  const profile = useProfile();
  const store = useStore();
  const [openAlert, setOpenAlert] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");


  const createShippingMethod = (e) => {
    e.preventDefault();
    const shippingMethodTitle = document.getElementById("shippingMethodTitle").value;
    const shippingMethodPrice = document.getElementById("shippingMethodPrice").value;
    const shippingMethodDescription = document.getElementById("shippingMethodDescription").value;

    const shippingMethodRef = fire.firestore().collection("users").doc(user.uid).collection("stores").doc(store.storeId).collection("shippingMethods").doc();
    const publicDiscountsRef = fire.firestore().collection("stores").doc(store.storeId).collection("shippingMethods").doc(shippingMethodRef.id);

    shippingMethodRef.set({
      shippingMethodTitle: shippingMethodTitle,
      shippingMethodPrice: Number(shippingMethodPrice),
      shippingMethodDescription : shippingMethodDescription,
      isActive: true, 
    });
    publicDiscountsRef.set({
      shippingMethodTitle: shippingMethodTitle,
      shippingMethodPrice: Number(shippingMethodPrice),
      shippingMethodDescription : shippingMethodDescription,
      isActive: true, 
    });

    setAlertMessage("Shipping method created")
    setOpenAlert(true)
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert(false);
  };

  const handleChangeShippingMethod = (e, item) => {
    fire
    .firestore()
    .collection("users")
    .doc(user.uid)
    .collection("stores")
    .doc(store.storeId)
    .collection("shippingMethods")
    .doc(item.id)
    .update({
      isActive: !item.isActive,
    });

    fire
    .firestore()
    .collection("stores")
    .doc(store.storeId)
    .collection("shippingMethods")
    .doc(item.id)
    .update({
      isActive: !item.isActive,
    });
  }

  const deleteShippingMethod = (item) => {
    fire
    .firestore()
    .collection("users")
    .doc(user.uid)
    .collection("stores")
    .doc(store.storeId)
    .collection("shippingMethods")
    .doc(item.id).delete()

    fire
    .firestore()
    .collection("stores")
    .doc(store.storeId)
    .collection("shippingMethods")
    .doc(item.id)
    .delete()

    setAlertMessage("Shipping method deleted")
    setOpenAlert(true)
  }

  return (
    <React.Fragment>
      <section  style={{width: "100%", flex: 1, height: "100%", minHeight: "100vh", backgroundColor: '#f2f8fb'}} className="section mt-60">
        <Container className="mt-lg-3">
          <Row className="justify-content-center">
            <Col xs={12}>
              <Card className="border-0 rounded shadow">
                <CardBody>
                  <h5 className="text-md-start text-center">
                    Shipping methods :
                  </h5>

                  <Row className="mt-4">
                    <Col md="6">
                      <Form onSubmit={createShippingMethod}>
                        <Row className="mt-4">
                          <Col md="12">
                            <div className="mb-3">
                              <Label className="form-label">Shipping Method Title</Label>
                              <Input
                                name="name"
                                id="shippingMethodTitle"
                                type="text"
                                className="form-control ps-5"
                                placeholder="Shipping Method Title :"
                                required
                              />
                            </div>
                          </Col>
                          <Col md="12">
                            <div className="mb-3">
                              <Label className="form-label">Shipping Method description</Label>
                              <textarea
                                id="shippingMethodDescription"
                                type="text"
                                className="form-control ps-5"
                                rows={2}
                                required
                              />
                            </div>
                          </Col>
                          <Col md="12">
                            <div className="mb-3">
                              <Label className="form-label">Shipping Method Price</Label>
                              <Input
                                name="number"
                                id="shippingMethodPrice"
                                type="number"
                                placeholder="1000"
                                className="form-control ps-5"
                                required
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm="12">
                            <input
                              type="submit"
                              id="submit"
                              name="send"
                              className="btn btn-primary"
                              value="Create shipping method"
                            />
                          </Col>
                        </Row>
                      </Form>
                    </Col>
                    <Col md="6">
                      {store.shippingMethods && (
                        <List
                          sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', border: 1 }}
                        >
                        {store.shippingMethods.map((item, i) => {
                          return (
                            <div key={i}>
                              <ListItem>
                                <ListItemIcon>
                                  <Button title="Delete" onClick={()=> deleteShippingMethod(item)}>
                                    <DeleteIcon style={{color:'red'}} />
                                  </Button>
                                </ListItemIcon>
                                <ListItemText id="switch-list-label-wifi" primary={item.shippingMethodTitle + " ₦"+item.shippingMethodPrice} secondary={item.shippingMethodDescription} />
                                <Switch
                                  edge="end"
                                  checked={item.isActive}
                                  onChange={(e) => handleChangeShippingMethod(e,item)}
                                  inputProps={{
                                    'aria-labelledby': 'switch-list-label-wifi',
                                  }}
                                />
                              </ListItem>
                              {i !== store.shippingMethods.length - 1 && (
                                <Divider />
                              )}
                            </div>
                          );
                        })}
                        </List>
                        )}
                    </Col>
                  </Row>


                </CardBody>
              </Card>
            </Col>
            <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleClose}>
              <Alert onClose={handleClose} severity="success">
                {alertMessage}
              </Alert>
            </Snackbar>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};


export default Shipping;
