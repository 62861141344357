import React from "react";
import { Switch } from "react-router-dom";
import { Route } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import ProductList from "./dashboard/ShopProductList.js";
import Store from "./dashboard/Store.js";
//import Merchant from "./dashboard/Merchant.js";
import Settings from "./dashboard/Settings.js";
import Orders from "./dashboard/Orders.js";
import MyProducts from "./dashboard/MyProducts.js";
//import Analytics from "./dashboard/Analytics.js";
import DiscountCodes from "./dashboard/DiscountCodes.js";
import Drawer from './dashboard/Drawer';
import Shipping from "./dashboard/Shipping.js";
import Referrals from "./dashboard/Referrals.js";
import MyCampaigns from "./dashboard/MyCampaigns.js";

const useStyles = makeStyles({
  container: {
    display: 'flex'
  }
});

export default () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Drawer />
        <Switch>
          <Route exact from="/" render={props => <ProductList {...props} />}/>
          {/* <Route exact from="/ProductList" render={props => <ProductList {...props} />}/>
          <Route exact from="/Store" render={props => <Store {...props} />}/> */}
          {/* <Route exact from="/Merchant" render={props => <Merchant {...props} />}/> */}
          <Route exact from="/Products" render={props => <MyProducts {...props} />}/>
          <Route exact from="/Orders" render={props => <Orders {...props} />}/>
          <Route exact from="/Settings" render={props => <Settings {...props} />}/>
          <Route exact from="/Store" render={props => <Store {...props} />}/>
          <Route exact from="/Discounts" render={props => <DiscountCodes {...props} />}/>
          <Route exact from="/Shipping" render={props => <Shipping {...props} />}/>
          <Route exact from="/Referrals" render={props => <Referrals {...props} />}/>
          <Route exact from="/MyCampaigns" render={props => <MyCampaigns {...props} />}/>
        </Switch>
    </div>
  );
}