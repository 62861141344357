import React from "react";
import {
    Pagination,
    PaginationItem,
    PaginationLink,
  } from "reactstrap";

const MyPagination = ({ productsPerPage, totalProducts, paginate, currentPage}) => {
  const pageNumbers = [];

  for( let i = 1; i<= Math.ceil(totalProducts / productsPerPage); i++){
    pageNumbers.push(i);
  };

  return(
    <Pagination listClassName="justify-content-center mb-0">
      <PaginationItem>
        <PaginationLink to="#" onClick={() => paginate(currentPage - 1)}>
          <i className="mdi mdi-arrow-left me-1"></i>Prev
        </PaginationLink>
      </PaginationItem>
      {pageNumbers.map(number => {
        var isActive = false;
        if (number === currentPage) {
          isActive = true;
        } else {
          isActive = false;
        };
        return (
          <PaginationItem key={number} active={isActive}>
            <PaginationLink to="#" onClick={() => paginate(number)}>{number}</PaginationLink>
          </PaginationItem>
        );
      })}
      <PaginationItem>
        <PaginationLink to="#" onClick={() => paginate(currentPage + 1)}>
          Next<i className="mdi mdi-arrow-right ms-1"></i>
        </PaginationLink>
      </PaginationItem>
    </Pagination>
  );
};

export default MyPagination