import React from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Input,
  Label,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody
} from "reactstrap";
import { Link } from "react-router-dom";
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

//Import Icons
import FeatherIcon from "feather-icons-react";

import { useHistory } from "react-router-dom";
import fire from "../Fire.js";
import MyPagination from "./MyPagination.js";
import Pricing from "./Pricing.js";
import { PaystackButton } from 'react-paystack';
import ReactPixel from 'react-facebook-pixel';
import firebase from 'firebase';
import {fetchProducts, fetchMoreProducts,} from './getProducts';

const options = {
  autoConfig: true, // set pixel's autoConfig
  debug: false, // enable logs
};
ReactPixel.init('782596949083299', options);

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

function useProfile() {
  const [profile, setProfile] = React.useState({});

  const user = fire.auth().currentUser;

  React.useEffect(() => {
    const unsubscribe = fire
    .firestore()
    .collection("users")
    .doc(user.uid)
    .onSnapshot(document => {
      setProfile(document.data());
    });
    return () => unsubscribe;
  }, [user.uid]);
  
  return profile;
};

function useStores() {
  const [stores, setStores] = React.useState([]);


  const user = fire.auth().currentUser;

  React.useEffect(() => {
    const unsubscribe = fire
    .firestore()
    .collection("users")
    .doc(user.uid)
    .collection("stores")
    .get()
    .then(querySnapshot => {
      querySnapshot.forEach(queryDocumentSnapshot => {
        setStores(currentStores => [...currentStores, Object.assign(queryDocumentSnapshot.data(), {id: queryDocumentSnapshot.id, storeId: queryDocumentSnapshot.id})]);
      });
    });
    return () => unsubscribe;
  }, [user.uid]);
  
  return stores;
};

function useStore() {
  const [store, setStore] = React.useState({});
  const user = fire.auth().currentUser;

  React.useEffect(() => {
    const unsubscribe = fire
    .firestore()
    .collection("users")
    .doc(user.uid)
    .collection("stores")
    .onSnapshot(querySnapshot => {
      querySnapshot.forEach(doc => {
        setStore(Object.assign(doc.data(), {storeId: doc.id,}));
      })
    });
    return () => unsubscribe;
  }, [user.uid]);
  
  return store;
};

const ProductList = props => {
  const user = fire.auth().currentUser;
  let history = useHistory();
  const profile = useProfile();
  const stores = useStores();
  const store = useStore();
  const [product, setProduct] = React.useState({});
  const [isModalOpen, setOpenModal] = React.useState(false);
  const [isApplyModalOpen, setOpenApplyModal] = React.useState(false);
  const [isPricingModalOpen, setPricingModalOpen] = React.useState(false);
  const [isRequestModalOpen, setRequestOpenModal] = React.useState(false);
  const [isSnackbarOpen, setOpenSnackbar] = React.useState(false);
  const [searchField, setSearchField] = React.useState("");
  const [myFilter, setMyFilter] = React.useState("All");

  const publicKey = "pk_live_9296b30945eba87a279e3d7ca33cefb6fc2fa261";
  const [plan, setPlanCode] = React.useState(null);
  const [planTitle, setPlanTitle] = React.useState(null);
  const [planPrice, setPlanPrice] = React.useState(null);

  const [currentPage, setCurrentPage] = React.useState(1);
  const [productsPerPage, setProductsPerPage] = React.useState(100);

  const [products, setProducts] = React.useState([]); 
  const [productsPerLoad] = React.useState(100);
  const [startAfter, setStartAfter] = React.useState({});
  const [lastProduct, setLastProduct] = React.useState(false);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");

  React.useEffect(() => {
    getProducts();
  }, []);

  async function getProducts() {
    const productsData = await fetchProducts(15);
    setProducts(currentProducts => [...currentProducts, ...(productsData.products)]);
    setStartAfter(productsData.lastVisible);
    getMoreProducts(productsData.lastVisible)
  };

  async function getMoreProducts(startafter) {
    if (!lastProduct) {
      const productsData = await fetchMoreProducts(startafter, productsPerLoad);
      setProducts(currentProducts => [...currentProducts, ...(productsData.products)]);
      setStartAfter(productsData.lastVisible);
      productsData.products.length == 0 ? setLastProduct(true) : setLastProduct(false);
      productsData.products.length == 0 ?  console.log(""): getMoreProducts(productsData.lastVisible);
    };
  };

  const requestProduct = () => {
    const firstName = document.getElementById("name").value;
    const price = document.getElementById("price").value;
    const product = document.getElementById("productName").value;
    const newProduct = fire.functions().httpsCallable('newProduct');
    newProduct({ firstName: firstName, price: price, email: user.email, product: product }).then(res => {
      console.log(res.data);
    });
    setOpenSnackbar(true);
    setRequestOpenModal(false);
  };

  const openRequestModal = () => {
    setRequestOpenModal(true);
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
    setOpenAlert(false)
  };

  const changePlan = (plan, title, amount) => {
    setPlanCode(plan);
    setPlanPrice(amount);
    setPlanTitle(title);
    setPricingModalOpen(false);
  };

  const componentProps = {
    email: user.email,
    publicKey,
    plan,
    onSuccess: () => {
      fire.firestore().collection("users").doc(user.uid).update({
        subscribed: true,
        plan: planTitle,
        planPrice: planPrice,
        dateSubscribed: firebase.firestore.FieldValue.serverTimestamp(),
      });
      setPlanCode(null);
    },
    onClose: () => null,
  };
  const componentProps2 = {
    email: user.email,
    publicKey,
    amount: plan,
    onSuccess: () => {
      fire.firestore().collection("users").doc(user.uid).update({
        subscribed: true,
        plan: planTitle,
        planPrice: planPrice,
        dateSubscribed: firebase.firestore.FieldValue.serverTimestamp(),
      });
      setPlanCode(null);
    },
    onClose: () => null,
  };
  let payButton;

  if (plan !== null) {
    if (plan === 199900) {
      payButton = (
        <PaystackButton {...componentProps2} className="btn btn-primary">
          Pay one-off for {planTitle} plan
        </PaystackButton>
      )
    } else {
      payButton = (
        <PaystackButton {...componentProps} className="btn btn-primary">
          Subscribe to {planTitle} plan
        </PaystackButton>
      )
    };
  };

  const search = (products) => {
    const isTag = (tags) => {
      if(tags){
        return tags.findIndex(tag => {
          if (tag.includes(searchField)) {
            return true;
          }
        })
      } else{
        return -1;
      }
    };
    const x = products.filter(
      (product) => 
      (
        (product.name.toLowerCase().indexOf(searchField) > -1 ||
        isTag(product.productTags) > -1
        ) 
        &&
        (product.category === myFilter || myFilter === "All")
        && 
        (product.published === true)
        &&
        (product.picUrl !== "https://firebasestorage.googleapis.com/v0/b/swhag-1a9e3.appspot.com/o/products%2F--default--%2Fdefault-product.jpg?alt=media&token=e5702835-a4b6-4131-a789-5d0207286b42")
      )
    );
    return x;
  };

  const changeFilter = () => {
    const category = document.getElementById("category").value;
    setMyFilter(category);
  };

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const curentProducts = search(products).slice(indexOfFirstProduct, indexOfLastProduct);

  const paginate = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= Math.ceil(products.length / productsPerPage)){
      setCurrentPage(pageNumber);
    } 
  };

  const openModal = (e) => {
    if(profile.store === true) {
      setOpenModal(true);
      setProduct(e);
    } else {
      history.push("/Store");
    };
  };

  const closeModal = () => {
    setOpenModal(false);
    setRequestOpenModal(false);
    setPricingModalOpen(false);
    setOpenApplyModal(false);
  };

  const addProduct = (e) => {
    const storeId = store.storeId;
    const price = document.getElementById("price").value;
    var obj ={};
    const rightNow = new Date(Date.now());

    let lastPaymentDate = new Date(Date.now());

    if(store.lastPaymentDate) {
      lastPaymentDate = new Date(store.lastPaymentDate.seconds * 1000)
    }

    const Difference_In_Time = rightNow.getTime() - lastPaymentDate.getTime();
  
    const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

    let subscriptionStillValid = false;

    if (store.plan === "trial") {
      if ((Difference_In_Days < 7) && (store.lastPaymentDate)) {
        subscriptionStillValid = true
      }
    } else {
      if ((Difference_In_Days < 30) && (store.lastPaymentDate) && (store.isSubscribed === false)) {
        subscriptionStillValid = true
      }
    }

    //if ((store.isSubscribed === true) || (subscriptionStillValid === true)) { 
    obj = Object.assign(e, {price: parseFloat(price), maxPrice: parseFloat(price), published: false, dropshipping: true, featured: true});
    // } else {
    //   obj = Object.assign(e, {price: parseFloat(e.maxPrice), maxPrice: parseFloat(e.maxPrice), published: false, dropshipping: true, featured: true});
    // }
    if ((store.storeName) && ((store.isSubscribed === true) || (subscriptionStillValid === true))) {
    
    fire
    .firestore()
    .collection("users")
    .doc(user.uid)
    .collection("stores")
    .doc(storeId)
    .collection("products")
    .doc(e.productId)
    .set(obj);

    fire
    .firestore()
    .collection("users")
    .doc(e.useruid)
    .collection("stores")
    .doc(e.storeId)
    .collection("products")
    .doc(e.productId)
    .collection("stores")
    .doc(storeId)
    .set({
      storeId: storeId,
      userUid: user.uid,
      storeEmail: user.email
    });

    if (store.collections) {
      const collection = document.getElementById("collection").value;
      fire
      .firestore()
      .collection("users")
      .doc(user.uid)
      .collection("stores")
      .doc(storeId)
      .collection("products")
      .doc(e.productId)
      .update({
        collection: collection,
      });
    };

    setOpenModal(false);
    setProduct({});
    setAlertMessage("Product added succesfully")
    setOpenAlert(true);
    } else {
      history.push("/Store")
    }
  };

  const apply = () => {
    const profileRef = fire.firestore().collection("users").doc(user.uid);

    const businessName = document.getElementById("businessName").value;
    const websiteLink = document.getElementById("websiteLink").value;
    const businessDescription = document.getElementById("businessDescription").value;
    const instagram = document.getElementById("instagram").value;

    profileRef.update({
      businessStatus: "Pending verification",
      businessName: businessName,
      businessDescription: businessDescription,
      websiteLink: websiteLink,
      instagram: instagram,
    });

    const newMerchantRequest = fire.functions().httpsCallable('newMerchantRequest');
    newMerchantRequest({ businessName: businessName, websiteLink: websiteLink, email: user.email, businessDescription: businessDescription, instagram: instagram, }).then(res => {
      console.log(res.data);
    });
    ReactPixel.trackSingle('782596949083299', 'BrandApply', {currency: "NGN", value: 1});

    window.open("https://firebasestorage.googleapis.com/v0/b/swhag-1a9e3.appspot.com/o/files%2Fbrand_tutorial%2FHello%2C%20Welcome%20to%20SWHAG.%20Interest%20in%20supplying%20products%20on%20SWHAG.%20The.pdf?alt=media&token=b4128e5c-3b9e-4eab-aa52-4174c1715fea");

    setOpenApplyModal(false);
  };

  return (
    <React.Fragment>
      {/* breadcrumb */}
      <div className="position-relative">
        <div className="shape overflow-hidden text-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>

      <section className="section" style={{width: "100%"}}>
        <Container style={{width: "100%"}} >
          <Row >
            <Col lg={3} md={4} xs={12}>
              <Card className="border-0 sidebar sticky-bar">
                <CardBody className="p-0">
                  <Link to="#" className="btn btn-primary mt-4" onClick={() => history.push('/Store')}>
                    Create your e-commerce website
                  </Link>
                  <Link to="#" className="btn btn-primary mt-4" onClick={() => setOpenApplyModal(true)}>
                    Become a verified third party supplier
                  </Link>
                  <Link to="#" className="btn btn-primary mt-4" onClick={() => window.open("https://firebasestorage.googleapis.com/v0/b/swhag-1a9e3.appspot.com/o/files%2Fbrand_tutorial%2FHello%2C%20Welcome%20to%20SWHAG.%20Interest%20in%20supplying%20products%20on%20SWHAG.%20The.pdf?alt=media&token=b4128e5c-3b9e-4eab-aa52-4174c1715fea")}>
                    How to become a third party supplier
                  </Link>
                  <Link to="#" className="btn btn-primary mt-4" onClick={openRequestModal}>
                    Request for a product
                  </Link>
                  <div className="mt-4" style={{display: 'flex', justifyContent: 'center'}}>
                    <Link to="//apps.apple.com/gb/app/swhag/id1602909385" target="_blank" rel="noopener noreferrer" className="btn btn-primary mt-2 me-2">
                      <i className="uil uil-apple"></i> Apple Store
                    </Link>
                    <Link to="//play.google.com/store/apps/details?id=co.swhag.app&hl=en_AU&gl=US"  target="_blank" rel="noopener noreferrer" className="btn btn-primary mt-2 me-2">
                      <i className="uil uil-google-play"></i> Play Store
                    </Link>
                  </div>
                  <div className="widget">
                    <div id="search2" className="widget-search mb-0">
                      <Form
                        role="search"
                        method="get"
                        id="searchform"
                      >
                        <div className="input-group mb-3 border rounded">
                          <Input
                            type="text"
                            className="form-control border-0"
                            name="s"
                            id="search"
                            placeholder="Search Keywords..."
                            onChange={(e) => setSearchField(e.target.value.toLowerCase())}
                          />
                          <button type="submit" className="input-group-text bg-white border-0" id="searchsubmit"><i className="uil uil-search"></i></button>
                        </div>
                      </Form>
                    </div>
                  </div>

                  <div className="d-flex justify-content-md-between align-items-center">
                    <div className="form custom-form">
                      <div className="mb-0">
                        <select
                          className="form-select form-control"
                          id="category"
                          onChange={changeFilter}
                        >
                          <option value={"All"}>All products</option>
                          <option value={"Gaming"}>Gaming</option>
                          <option value={"Beauty"}>Beauty</option>
                          <option value={"Health"}>Health</option>
                          <option value={"Women's Fashion"}>Women's Fashion</option>
                          <option value={"Men's Fashion"}>Men's Fashion</option>
                          <option value={"Accessories"}>Accessories</option>
                          <option value={"Gym and Fitness"}>Gym and Fitness</option>
                          <option value={"Electronics"}>Electronics</option>
                          <option value={"Others"}>Others</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col lg={9} md={8} xs={12} className="mt-5 pt-2 mt-sm-0 pt-sm-0">

              <Row>
                {(curentProducts).map((product, key) => (
                  <Col lg={3} md={4} xs={6} className="mt-4 pt-2" key={key}>
                  {product && (
                      <Card className="shop-list border-0 position-relative">
                        <ul className="label list-unstyled mb-0">
                          {product.featured && (
                            <li>
                              <Link
                                to="#"
                                className="badge badge-link rounded-pill bg-success"
                              >
                                Featured
                              </Link>
                            </li>
                          )}
                          {(product.quantity < 1) && (
                            <li>
                              <Link
                                to="#"
                                className="badge badge-link rounded-pill bg-danger"
                              >
                                Out of stock
                              </Link>
                            </li>
                          )}
                        </ul>
                        <Row className="align-items-center g-0">
                          <Col xs={12}>
                            <div className="shop-image position-relative overflow-hidden">
                              <Link to="#" onClick={() => openModal(product)}>
                                <img
                                  src={product.picUrl}
                                  className="img-fluid"
                                  alt="SWHAG"
                                />
                              </Link>
                              <Link
                                to="#"
                                className="overlay-work"
                                onClick={() => openModal(product)}
                              >
                                <img
                                  src={product.picUrl}
                                  className="img-fluid"
                                  alt="SWHAG"
                                />
                              </Link>
                            </div>
                            <Link
                              to="#"
                              className="text-dark product-name h6"
                            >
                              {product.name}
                            </Link>

                            <h6 className="text-muted small fst-italic mb-0 me-3">
                              NGN {(product.cost)}
                            </h6>
                            {/* <li className="mt-2 list-inline-item">
                                <Link to="#" className="btn btn-primary" onClick={() => openModal(product)}>Add to your store</Link>
                              </li> */}
                          </Col>

                          {/* <Col lg={8} md={6}>
                            <CardBody className="content p-4">
                              <Link
                                to="/#"
                                className="text-dark product-name h6"
                              >
                                {product.name}
                              </Link>
                              <div className="d-lg-flex align-items-center mt-2 mb-3">

                                <h6 className="text-muted small fst-italic mb-0 me-3">
                                  NGN {product.cost}
                                </h6>
                              </div>
                              <p className="para-desc text-muted mb-1">
                                {product.description}
                              </p>
                              <ul className="list-unstyled mb-0">
                                <li className="mt-2 list-inline-item">
                                  <Link to="#" className="btn btn-primary" onClick={() => openModal(product)}>Add to your store</Link>
                                </li>
                                {payButton}
                              </ul>
                            </CardBody>
                          </Col> */}
                        </Row>
                      </Card>
                    )}
                  </Col>
                ))}

                <Modal isOpen={isModalOpen} toggle={closeModal} centered size="lg">
                  <ModalHeader toggle={closeModal}>{product.name}</ModalHeader>

                  <ModalBody className="p-4">
                    <Container fluid className="px-0">
                      <Row>
                        <Col lg={5}>
                          <div className="tiny-single-item">
                            <div className="tiny-slide"><img src={product.picUrl} className="img-fluid rounded" alt="" /></div>
                          </div>
                        </Col>

                        <Col lg={7} className="mt-4 mt-lg-0 pt-2 pt-lg-0">
                          <h4 className="title">{product.name}</h4>
                          <h5 className="mt-4">Costs:</h5>
                          <h5 className="text-muted">NGN {product.cost}</h5>

                          <h5 className="mt-4">Overview :</h5>
                          <p className="text-muted">{product.description}</p>

                          <div className="row mt-4 pt-2">

                            <div className="col-12 mt-4">
                              <div className="d-flex shop-list align-items-center">
                                <h6 className="mb-0">Set your price:</h6>
                                <div className="ms-3">
                                  <div className="qty-icons">
                                    NGN{"  "}<input min={product.cost} name="price" id="price" defaultValue={product.maxPrice} type="number" className="btn btn-icon btn-soft-primary quantity" style={{width: 100}} />
                                  </div>
                                </div>
                              </div>
                            </div>
                            {(store.collections) && (
                              <div className="col-12 mt-4">
                                <div className="d-flex shop-list align-items-center">
                                  <h6 className="mb-0">Add product to a collection:</h6>
                                  <div className="ms-3">
                                    <div className="qty-icons">
                                      <select className="btn btn-icon btn-soft-primary quantity" id="collection" style={{width: 200}}>
                                        {store.collections.map((collection, index) => {
                                          return(
                                            <option key={index} value={collection.name}>{collection.name}</option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>

                          <div className="mt-4 pt-2">
                            <Link to="#" className="btn btn-primary" onClick={() => addProduct(product)}>Add to your store</Link>
                          </div>
                          <div onClick={closeModal} className="btn btn-soft-primary ms-2">
                            Close
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </ModalBody>
                  {/* </div>
                  </div> */}
                </Modal>
                <Modal isOpen={isRequestModalOpen} toggle={closeModal} centered size="lg">
                  <ModalHeader toggle={closeModal}>Request a product</ModalHeader>

                  <ModalBody className="p-4">
                    <Container fluid className="px-0">
                      <Row>
                      <h4 className="card-title">Tell us which product you would like to sell and we will bring it on the platform</h4>
                      <div className="custom-form mt-3">
                        <div id="message"></div>
                        <Form
                          name="contact-form"
                          id="contact-form"
                        >
                          <Row>
                            <Col md={6}>
                              <div className="mb-3">
                                <Label className="form-label">
                                  Product Name <span className="text-danger">*</span>
                                </Label>
                                <div className="form-icon position-relative">
                                  <i>
                                    <FeatherIcon
                                      icon="package"
                                      className="fea icon-sm icons"
                                    />
                                  </i>
                                </div>
                                <Input
                                  name="name"
                                  id="productName"
                                  type="text"
                                  className="form-control ps-5"
                                  placeholder="Product Name:"
                                  required
                                />
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="mb-3">
                                <Label className="form-label">
                                  Your Name <span className="text-danger">*</span>
                                </Label>
                                <div className="form-icon position-relative">
                                  <i>
                                    <FeatherIcon
                                      icon="package"
                                      className="fea icon-sm icons"
                                    />
                                  </i>
                                </div>
                                <Input
                                  name="name"
                                  id="name"
                                  type="text"
                                  className="form-control ps-5"
                                  placeholder="Your Name:"
                                  required
                                />
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="mb-3">
                                <Label className="form-label">
                                  What price would you sell it for <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  name="price"
                                  id="price"
                                  type="number"
                                  className="form-control ps-5"
                                  placeholder="10000"
                                  required
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={12} className="text-center">
                              <div className="d-grid">
                                <input
                                  id="submit"
                                  name="send"
                                  className="submitBnt btn btn-primary"
                                  value="Request product"
                                  onClick={requestProduct}
                                />
                              </div>
                              <div id="simple-msg"></div>

                            </Col>
                          </Row>
                        </Form>
                      </div>
                      </Row>
                    </Container>
                  </ModalBody>
                  {/* </div>
                  </div> */}
                </Modal>
                <Modal isOpen={isApplyModalOpen} toggle={closeModal} centered size="lg">
                  <ModalHeader toggle={closeModal}>Apply as a verified supplier</ModalHeader>
                  <ModalBody className="p-4">
                    <Container fluid className="px-0">
                      <Row>
                      <h4 className="card-title">Give us some information about your business</h4>
                      <h5 className="card-title">After filling this form you will receive a pdf with further instructions</h5>
                      <div className="custom-form mt-3">
                        <div id="message"></div>
                        <Form
                          name="contact-form"
                          id="contact-form"
                        >
                          <Row>
                            <Col md={6}>
                              <div className="mb-3">
                                <Label className="form-label">
                                  Business Name <span className="text-danger">*</span>
                                </Label>
                                <div className="form-icon position-relative">
                                  <i>
                                    <FeatherIcon
                                      icon="package"
                                      className="fea icon-sm icons"
                                    />
                                  </i>
                                </div>
                                <Input
                                  name="name"
                                  id="businessName"
                                  type="text"
                                  className="form-control ps-5"
                                  placeholder="Business Name:"
                                  required
                                />
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="mb-3">
                                <Label className="form-label">
                                  Website link <span className="text-danger">*</span>
                                </Label>
                                <div className="form-icon position-relative">
                                  <i>
                                    <FeatherIcon
                                      icon="package"
                                      className="fea icon-sm icons"
                                    />
                                  </i>
                                </div>
                                <Input
                                  name="name"
                                  id="websiteLink"
                                  type="text"
                                  className="form-control ps-5"
                                  placeholder="https://swhag.co:"
                                  required
                                />
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="mb-3">
                                <Label className="form-label">
                                  Business instagram handle <span className="text-danger">*</span>
                                </Label>
                                <div className="form-icon position-relative">
                                  <i>
                                    <FeatherIcon
                                      icon="package"
                                      className="fea icon-sm icons"
                                    />
                                  </i>
                                </div>
                                <Input
                                  name="name"
                                  id="instagram"
                                  type="text"
                                  className="form-control ps-5"
                                  placeholder="Instagram handle:"
                                  required
                                />
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="mb-3">
                                <Label className="form-label">
                                  Business description <span className="text-danger">*</span>
                                </Label>
                                <div className="form-icon position-relative">
                                  <i>
                                    <FeatherIcon
                                      icon="package"
                                      className="fea icon-sm icons"
                                    />
                                  </i>
                                </div>
                                <textarea
                                  name="name"
                                  id="businessDescription"
                                  type="text"
                                  className="form-control ps-5"
                                  placeholder="Business Description:"
                                  required
                                  rows={3}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={12} className="text-center">
                              <div className="d-grid">
                                <input
                                  id="submit"
                                  name="send"
                                  className="submitBnt btn btn-primary"
                                  value="Submit Application"
                                  onClick={apply}
                                />
                              </div>
                              <div id="simple-msg"></div>

                            </Col>
                          </Row>
                        </Form>
                      </div>
                      </Row>
                    </Container>
                  </ModalBody>
                </Modal>
                <Modal isOpen={isPricingModalOpen} toggle={closeModal} centered size="lg">
                  <ModalBody className="p-4">
                  <Container fluid className="px-0">
                    <Pricing changePlan={changePlan} />
                  </Container>
                  </ModalBody>
                </Modal>
                <Snackbar open={isSnackbarOpen} autoHideDuration={6000} onClose={handleClose}>
                  <Alert onClose={handleClose} severity="success">
                    Your request has been sent successfully!
                  </Alert>
                </Snackbar>
                <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleClose}>
                  <Alert onClose={handleClose} severity="success">
                    {alertMessage}
                  </Alert>
                </Snackbar>
                <Col xs={12} className="mt-4 pt-2">
                  <MyPagination productsPerPage={productsPerPage} totalProducts={products.length} paginate={paginate} currentPage={currentPage} />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default ProductList;
