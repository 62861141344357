// React Basic and Bootstrap
import React from "react";
import {
  Container,
  Row,
  Col,
  Input,
  Label,
  CardBody,
} from "reactstrap";
import banks from './Banks.js';
import fire from "../Fire.js";
import firebase from 'firebase';

import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';

import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';



//Import Icons
import FeatherIcon from "feather-icons-react";

import UpgradePlan from "./UpgradePlan.js"
import EditStore from "./EditStore.js";
import EditFAQ from "./EditFAQ.js";
import EditContact from "./EditContact.js";
import EditAbout from "./EditAbout.js";
import EditDomains from "./EditDomains.js";
import Pricing from "./Pricing";
import { useHistory } from "react-router-dom";
import { ConfirmProvider } from 'material-ui-confirm';
import { useConfirm } from 'material-ui-confirm';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

function useAllstores() {
  const [stores, setStores] = React.useState({});

  const user = fire.auth().currentUser;

  React.useEffect(() => {
    const unsubscribe = fire
    .firestore()
    .collection("stores")
    .onSnapshot(querySnapshot => {
      setStores([]);
      querySnapshot.forEach(store => {
        setStores(currentStores => [...currentStores, store.data()]);
      })
    });
    return () => unsubscribe;
  }, [user.uid]);
  
  return stores;
};
function useProfile() {
  const [profile, setProfile] = React.useState({});

  const user = fire.auth().currentUser;

  React.useEffect(() => {
    const unsubscribe = fire
    .firestore()
    .collection("users")
    .doc(user.uid)
    .onSnapshot(document => {
      setProfile(document.data());
    });
    return () => unsubscribe;
  }, [user.uid]);
  
  return profile;
};

function useStore() {
  const [store, setStore] = React.useState({});
  const user = fire.auth().currentUser;

  React.useEffect(() => {
    const unsubscribe = fire
    .firestore()
    .collection("users")
    .doc(user.uid)
    .collection("stores")
    .onSnapshot(querySnapshot => {
      querySnapshot.forEach(doc => {
        setStore(Object.assign(doc.data(), {storeId: doc.id,}));
      })
    });
    return () => unsubscribe;
  }, [user.uid]);
  
  return store;
};

const Store = props => {
  const classes = useStyles();
  let history = useHistory();
  const confirm = useConfirm();
  const allStores = useAllstores();
  const profile = useProfile();
  const store = useStore();
  const user = fire.auth().currentUser;
  const [storeNameError, setStoreNameError] = React.useState();
  const [referralCodeError, setReferralCodeError] = React.useState();
  const [isReferralCodeValid, setIsReferralCodeValid] = React.useState(false);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");

  const [value, setValue] = React.useState(0);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert(false);
  };


  if(profile.store === true){
    const rightNow = new Date(Date.now());

    let lastPaymentDate = new Date(Date.now());

    if(store.lastPaymentDate) {
      lastPaymentDate = new Date(store.lastPaymentDate.seconds * 1000)
    }

    const Difference_In_Time = rightNow.getTime() - lastPaymentDate.getTime();
  
    const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

    let subscriptionStillValid = false;

    if (store.plan === "trial") {
      if ((Difference_In_Days < 7) && (store.lastPaymentDate)) {
        subscriptionStillValid = true
      }
    } else {
      if ((Difference_In_Days < 30) && (store.lastPaymentDate) && (store.isSubscribed === false)) {
        subscriptionStillValid = true
      }
    }

    if ((store.isSubscribed === true) || (subscriptionStillValid === true)) {

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    return (
      <React.Fragment >
        <section className="section" style={{width: "100%"}}>
        <Container >
          <div className={classes.root}>
            <AppBar position="static">
              <Tabs value={value} onChange={handleChange} aria-label="simple tabs example" centered variant="fullWidth">
                <Tab label="Store" {...a11yProps(0)} />
                <Tab label="About us" {...a11yProps(1)} />
                <Tab label="Contact" {...a11yProps(2)} />
                <Tab label="FAQ" {...a11yProps(3)} />
                <Tab label="Domains" {...a11yProps(4)} />
              </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
              <EditStore profile={profile} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <EditAbout profile={profile} />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <EditContact profile={profile} />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <EditFAQ profile={profile} />
            </TabPanel>
            <TabPanel value={value} index={4}>
              <EditDomains profile={profile} />
            </TabPanel>
          </div>
        </Container>
        </section>
      </React.Fragment>
    );
    } else {
      return(
        <UpgradePlan profile={profile} />
      )
    }

  } else {

    const myFilter = (stores, currentStoreLink) => {
      const currentStore = stores.filter(
        (store) => (
          store.storeName.toLowerCase() === currentStoreLink.toLowerCase()
        )
      );
      return currentStore[0];
    };

    const applyCode = () => {
      const referralCode = document.getElementById("referralCode").value;
      fire
      .firestore()
      .collection("stores")
      .get()
      .then(querySnapshot => {
        var allStores = [];
        var isValid = false;
        querySnapshot.forEach(store => {
          allStores.push(store.data());
        });
        
        allStores.map(item => {
          if ((referralCode).toLowerCase() === (item.storeName).toLowerCase()) {
            isValid = true;
            setReferralCodeError(
            <p style={{color: 'green'}}>
              This referral code is valid
            </p>
            );
          } else {
          };
        });
  
        if (isValid === true) {
          setReferralCodeError(
            <p  style={{color: 'green'}}>
              This referral code is valid
            </p>
          );
          setIsReferralCodeValid(true);
        } else {
          setReferralCodeError(
            <p  style={{color: 'red'}}>
              This referral code is not valid
            </p>
          );
          setIsReferralCodeValid(false);
        };
  
      });
  
    };

    const changeStoreName = () => {
      const storeName = (document.getElementById("storeName").value).toLowerCase();
      if (/\s/.test(storeName)) {
        setStoreNameError(
          <p className=" small fst-italic mb-0 mt-1" style={{color: 'red'}}>
            Don't add any space in your store name. Should be one word
          </p>
          );
      } else {
      setStoreNameError(null);
      };
      allStores.forEach(store => {
        if ((storeName).toLowerCase() === (store.storeName).toLowerCase()) {
          setStoreNameError(
          <p className=" small fst-italic mb-0 mt-1" style={{color: 'red'}}>
            This store name is already taken
          </p>
          );
        } else {
        }
      });
    };

    const createStore = () => {
      const storeName = document.getElementById("storeName").value.replace(/ /g,'').toLowerCase();
      const firstName = document.getElementById("firstName").value;
      const lastName = document.getElementById("lastName").value;
      const phoneNumber = document.getElementById("phoneNumber").value;
      const email = user.email;
      const currentBank = document.getElementById("bank").value.split(",");
      const accountNumber = document.getElementById("accountNumber").value;
      const referralCode = document.getElementById("referralCode").value;

      const storeRef = fire.firestore().collection("users").doc(user.uid).collection("stores").doc();
      confirm({ description: 'Are you sure you have entered the correct information?', confirmationText: 'Yes' })
      .then(() => { 

        var isValid = true;

        if (/\s/.test(storeName)) {
          isValid = false;
          setStoreNameError(
            <p className=" small fst-italic mb-0 mt-1" style={{color: 'red'}}>
              Don't add any space in your store name. Should be one word
            </p>
            );
        } else {
        setStoreNameError(null);
        };
        allStores.forEach(store => {
          if ( ((storeName).toLowerCase() === (store.storeName).toLowerCase()) || ((storeName).toLowerCase() === "")) {
            isValid = false;
            setStoreNameError(
            <p className=" small fst-italic mb-0 mt-1" style={{color: 'red'}}>
              This store name is already taken
            </p>
            );
          } else {
          }
        });

        if (isValid === true) {

        storeRef.set({
          firstName: firstName,
          lastName: lastName,
          userUid: user.uid,
          storeId: storeRef.id,
          storeName: storeName.toLowerCase(),
          storeEmail: email,
          bank: currentBank[0],
          accountNumber: accountNumber,
          phoneNumber: phoneNumber,
          deliveryFee: Number(1000),
          monthlyTargetRevenue: Number(50000),
          ctaHeading: "End of Season Clearance Sale upto 40%",
          ctaSubheading: "Best e-commerce platform, find the best products at the best prices.",
          heading: "New Accessories Collections",
          mainImage: "https://firebasestorage.googleapis.com/v0/b/swhag-1a9e3.appspot.com/o/users%2FZdZhD29GW6fLVtKY4kiISVyH2oz2%2Fstores%2FIqrwd9FT2kEcI1LufrQ9%2Fbg1.jpg?alt=media&token=c911db86-bfd0-4cb3-9f80-ce3401bed709",
          secondaryImage: "https://firebasestorage.googleapis.com/v0/b/swhag-1a9e3.appspot.com/o/users%2FZdZhD29GW6fLVtKY4kiISVyH2oz2%2Fstores%2FIqrwd9FT2kEcI1LufrQ9%2Fcta.jpg?alt=media&token=1e924636-dcb2-4891-8564-44ebdb07c9e1",
          subheading: "Best e-commerce platform, find the best products at the best prices.",
          dateCreated: firebase.firestore.FieldValue.serverTimestamp(),
        }).then(()=> {
          fire
          .firestore()
          .collection("stores")
          .doc(storeRef.id)
          .set({
            firstName: firstName,
            lastName: lastName,
            userUid: user.uid,
            storeId: storeRef.id,
            storeName: storeName.toLowerCase(),
            storeEmail: email,
            bank: currentBank[0],
            accountNumber: accountNumber,
            phoneNumber: phoneNumber,
            deliveryFee: Number(1000),
            monthlyTargetRevenue: Number(50000),
            ctaHeading: "End of Season Clearance Sale upto 40%",
            ctaSubheading: "Best e-commerce platform, find the best products at the best prices.",
            heading: "New Accessories Collections",
            mainImage: "https://firebasestorage.googleapis.com/v0/b/swhag-1a9e3.appspot.com/o/users%2FZdZhD29GW6fLVtKY4kiISVyH2oz2%2Fstores%2FIqrwd9FT2kEcI1LufrQ9%2Fbg1.jpg?alt=media&token=c911db86-bfd0-4cb3-9f80-ce3401bed709",
            secondaryImage: "https://firebasestorage.googleapis.com/v0/b/swhag-1a9e3.appspot.com/o/users%2FZdZhD29GW6fLVtKY4kiISVyH2oz2%2Fstores%2FIqrwd9FT2kEcI1LufrQ9%2Fcta.jpg?alt=media&token=1e924636-dcb2-4891-8564-44ebdb07c9e1",
            subheading: "Best e-commerce platform, find the best products at the best prices.",
            dateCreated: firebase.firestore.FieldValue.serverTimestamp(),
          });

          storeRef.collection("orders").doc("--stats--").set({
            orders: 0,
            revenue: 0,
            costs: 0,
            profits: 0,
            deliveryFee: 0,
          });

          const storeSubaccount = fire.functions().httpsCallable('storeSubaccount');
          storeSubaccount({accountNumber: accountNumber, bank: currentBank[1], firstName: firstName, lastName: lastName, uid: user.uid, storeId: storeRef.id}).then(result => {
            console.log(result.data);
          });

          if(isReferralCodeValid === true) {
            fire
            .firestore().collection("stores").where("storeName", "==", referralCode.toLowerCase()).get().then(querySnapshot => {
              var allStores = [];
              querySnapshot.forEach(doc =>{
                var storeInfo = doc.data();
                allStores.push(Object.assign(storeInfo, {storeId: doc.id}));
              });
              var referrerStore =  myFilter(allStores, referralCode);
              fire.firestore().collection("users")
              .doc(referrerStore.userUid).collection("stores")
              .doc(referrerStore.storeId).collection("referrals")
              .doc(storeRef.id)
              .set({
                firstName: firstName,
                lastName: lastName,
                userUid: user.uid,
                storeId: storeRef.id,
                storeName: storeName.toLowerCase(),
                storeEmail: email,
                bank: currentBank[0],
                accountNumber: accountNumber,
                dateCreated: firebase.firestore.FieldValue.serverTimestamp(),
              });
              storeRef.update({
                referrer: referralCode,
                referrerStoreId: referrerStore.storeId,
                referrerUserUid: referrerStore.userUid,
              });
              fire
              .firestore()
              .collection("stores")
              .doc(storeRef.id)
              .update({
                referrer: referralCode,
                referrerStoreId: referrerStore.storeId,
                referrerUserUid: referrerStore.userUid,
              });
              fire.firestore().collection("referrals")
              .doc(user.uid)
              .set({
                firstName: firstName,
                lastName: lastName,
                userUid: user.uid,
                storeId: storeRef.id,
                storeName: storeName.toLowerCase(),
                storeEmail: email,
                bank: currentBank[0],
                accountNumber: accountNumber,
                referrer: referralCode,
                referrerStoreId: referrerStore.storeId,
                referrerUserUid: referrerStore.userUid,
                dateCreated: firebase.firestore.FieldValue.serverTimestamp(),
              });
            });

          };
          fire.analytics().logEvent("create_store", { value: 1})
        });

        user.updateProfile({
          displayName: firstName,
        });

        fire
        .firestore()
        .collection("users")
        .doc(user.uid)
        .update({
          store: true,
          firstName: firstName,
          lastName: lastName,
          phoneNumber: phoneNumber,
        });

        document.getElementById("contact-form").reset();

        }


      })
      .catch(() => { /* ... */ });
      
    };

    const changePlan = (plan, title) => {
      if (profile.store === true) {
      history.push("/Products");
      } else {
        setAlertMessage("You need to create a store first")
        setOpenAlert(true)
      }
    };

    return (
      <ConfirmProvider>
      <React.Fragment>
        <section className="section pb-0" style={{width: "100%"}}>

          <Container className="mt-0 mt-0">
            <Row className="align-items-center">
              <Col
                xs={12}
                className="mt-4 mt-sm-0 pt-2 pt-sm-0 order-2 order-md-1"
              >
                <Card className="shadow rounded border-0">
                  <CardBody className="py-5">
                    <h4 className="card-title">Create your store!</h4>
                    <div className="custom-form mt-3">
                        <Row>
                          <Col md={6}>
                            <div className="mb-3">
                              {storeNameError}
                              <Label className="form-label">
                                Your Store Name <span className="text-danger">*</span>
                              </Label>
                              <div className="form-icon position-relative">
                                <i>
                                  <FeatherIcon
                                    icon="user"
                                    className="fea icon-sm icons"
                                  />
                                </i>
                              </div>
                              <Input
                                name="name"
                                id="storeName"
                                type="text"
                                className="form-control ps-5"
                                placeholder="One word e.g 'bookshop' not 'book shop'"
                                required
                                onChange={changeStoreName}
                              />
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Your First Name <span className="text-danger">*</span>
                              </Label>
                              <div className="form-icon position-relative">
                                <i>
                                  <FeatherIcon
                                    icon="user"
                                    className="fea icon-sm icons"
                                  />
                                </i>
                              </div>
                              <Input
                                name="name"
                                id="firstName"
                                type="text"
                                className="form-control ps-5"
                                placeholder="First Name :"
                                required
                              />
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Your Last Name <span className="text-danger">*</span>
                              </Label>
                              <div className="form-icon position-relative">
                                <i>
                                  <FeatherIcon
                                    icon="user"
                                    className="fea icon-sm icons"
                                  />
                                </i>
                              </div>
                              <Input
                                name="name"
                                id="lastName"
                                type="text"
                                className="form-control ps-5"
                                placeholder="Last Name :"
                                required
                              />
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Your Phone Number <span className="text-danger">*</span>
                              </Label>
                              <div className="form-icon position-relative">
                                <i>
                                  <FeatherIcon
                                    icon="user"
                                    className="fea icon-sm icons"
                                  />
                                </i>
                              </div>
                              <Input
                                name="phone"
                                id="phoneNumber"
                                type="number"
                                className="form-control ps-5"
                                placeholder="Phone number :"
                                required
                              />
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Your Bank <span className="text-danger">*</span>
                              </Label>
                              <select className="btn btn-icon form-control ps-5 quantity" id="bank"  style={{width: "100%"}}>
                                {banks.map((bank) => {
                                  return(
                                    <option key={bank.value} value={[bank.label, bank.value]}>{bank.label}</option>
                                  )
                                })}
                              </select>
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Your Account Number to recieve payments <span className="text-danger">*</span>
                              </Label>
                              <div className="form-icon position-relative">
                                <i>
                                  <FeatherIcon
                                    icon="dollar-sign"
                                    className="fea icon-sm icons"
                                  />
                                </i>
                              </div>
                              <Input
                                name="accountNumber"
                                id="accountNumber"
                                type="number"
                                className="form-control ps-5"
                                placeholder="Account Number :"
                                required
                              />
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Do you have a referral code ? <span className="text-danger"></span>
                              </Label>
                              {referralCodeError}
                              <Input
                                name="name"
                                id="referralCode"
                                type="text"
                                className="form-control ps-5"
                                placeholder=""
                              />
                              <a  href={"#"} onClick={applyCode}>Apply code</a>
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Preview an example website <span className="text-danger">*</span>
                              </Label>
                              <div className="form-icon position-relative">
                                <i>
                                  <FeatherIcon
                                    icon="globe"
                                    className="fea icon-sm icons"
                                  />
                                </i>
                              </div>
                              <Input
                                name="website"
                                id="url"
                                type="text"
                                className="form-control ps-5"
                                defaultValue={"https://shazzastore.com/"}
                                disabled
                              />
                              <a target="_blank" href={"https://shazzastore.com/"} rel="noreferrer">Preview</a>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={12} className="text-center">
                            <div className="d-grid">
                            <Button className="submitBnt btn btn-primary" onClick={createStore}>
                              Create store
                            </Button>
                            </div>
                          </Col>
                          <Pricing changePlan={changePlan} />
                        </Row>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleClose}>
              <Alert onClose={handleClose} severity="error">
                {alertMessage}
              </Alert>
            </Snackbar>
          </Container>

        </section>
      </React.Fragment>
      </ConfirmProvider>
    );
  };
};

export default Store;
