import React from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Input,
  CardBody,
  Label,
  Modal,
  ModalBody
} from "reactstrap";
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import DeleteIcon from '@mui/icons-material/Delete';

import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';

import fire from "../Fire.js";
import firebase from 'firebase';

const useStyles = makeStyles({
  root: {
  },
  media: {
    height: 500,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  media2: {
    height: 200,
    marginTop: 40,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

function useStore() {
  const [store, setStore] = React.useState({});
  const user = fire.auth().currentUser;

  React.useEffect(() => {
    const unsubscribe = fire
    .firestore()
    .collection("users")
    .doc(user.uid)
    .collection("stores")
    .onSnapshot(querySnapshot => {
      querySnapshot.forEach((doc) => {
        fire
        .firestore()
        .collection("users")
        .doc(user.uid)
        .collection("stores")
        .doc(doc.id)
        .collection("pages")
        .doc("faq")
        .onSnapshot(page => {
          setStore(Object.assign(doc.data(), {storeId: doc.id, faq: page.data()}));
        });
      })
    });
    return () => unsubscribe;
  }, [user.uid]);
  
  return store;
};

const EditFAQ = ({profile}) => {
  const classes = useStyles();
  const user = fire.auth().currentUser;
  const store = useStore();
  const [isOpenNewFAQ, setOpenNewFAQ] = React.useState(false);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");
  
  const updateFAQ = (e) => {
    e.preventDefault();
    const question = document.getElementById("question").value;
    const answer = document.getElementById("answer").value;

    fire
    .firestore()
    .collection("users")
    .doc(user.uid)
    .collection("stores")
    .doc(store.storeId)
    .collection("pages")
    .doc("faq")
    .update({
      questions: firebase.firestore.FieldValue.arrayUnion({question: question, answer: answer}),
    });
    fire
    .firestore()
    .collection("stores")
    .doc(store.storeId)
    .collection("pages")
    .doc("faq")
    .update({
      questions: firebase.firestore.FieldValue.arrayUnion({question: question, answer: answer}),
    });

    setOpenNewFAQ(false);
    setAlertMessage("Question added succesfully");
    setOpenAlert(true);
  };


  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert(false);
  };

  const handleChangeChecked = (event) => {
    fire
    .firestore()
    .collection("users")
    .doc(user.uid)
    .collection("stores")
    .doc(store.storeId)
    .update({
      showFAQ: event.target.checked,
    });
    fire
    .firestore()
    .collection("stores")
    .doc(store.storeId)
    .update({
      showFAQ: event.target.checked,
    });
    fire
    .firestore()
    .collection("users")
    .doc(user.uid)
    .collection("stores")
    .doc(store.storeId)
    .collection("pages")
    .doc("faq")
    .update({
      showPage: event.target.checked,
    });
    fire
    .firestore()
    .collection("stores")
    .doc(store.storeId)
    .collection("pages")
    .doc("faq")
    .update({
      showPage: event.target.checked,
    });
  };

 

  const deleteFAQ = (faq) => {
    fire
    .firestore()
    .collection("users")
    .doc(user.uid)
    .collection("stores")
    .doc(store.storeId)
    .collection("pages")
    .doc("faq")
    .update({
      questions: firebase.firestore.FieldValue.arrayRemove(faq),
    });
    fire
    .firestore()
    .collection("stores")
    .doc(store.storeId)
    .collection("pages")
    .doc("faq")
    .update({
      questions: firebase.firestore.FieldValue.arrayRemove(faq),
    });

    setOpenAlert(true);
    setAlertMessage("Question removed succesfully");
  };


  return (
    <React.Fragment>

        <Container>
          <Row>
            <Col lg={4} xs={12}>
              <Card className="shadow rounded border-0">
                <CardBody className="py-5">
                  <h4 className="card-title">Edit FAQ page</h4>
                  <div className="custom-form mt-3">
                    <div id="message"></div>
                    <Form
                      method="post"
                      onSubmit={updateFAQ}
                      name="contact-form"
                      id="contact-form"
                    >
                      <Row>

                        <Col md={12}>
                          <div className="mb-3">
                            <Label className="form-label">
                              Show FAQ page on your website?
                            </Label>
                            <Switch
                              checked={store? store.showFAQ : false}
                              onChange={handleChangeChecked}
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                          </div>
                        </Col>
                       {(isOpenNewFAQ === false)&&( 
                       <Col md={12} >
                          <Button color="primary" variant="contained" onClick={() => setOpenNewFAQ(true)} style={{borderRadius: 20, width: '100%'}}>Add a question</Button>
                        </Col>
                        )}
                        {(isOpenNewFAQ === true)&&(
                        <div>
                        <Col md={12}>
                          <div className="mb-3">
                            <Label className="form-label">
                              Question
                            </Label>
                            <Input
                              id="question"
                              type="text"
                              className="form-control "
                              required
                            />
                          </div>
                        </Col>
                        <Col md={12}>
                          <div className="mb-3">
                            <Label className="form-label">
                              Answer
                            </Label>
                            <textarea id="answer" type="text" className="form-control " rows="5" required />
                          </div>
                        </Col>
                        <Col sm={12} className="text-center">
                          <div className="d-grid">
                            <input
                              type="submit"
                              id="submit"
                              name="send"
                              className="submitBnt btn btn-primary"
                              value="Save question"
                            />
                          </div>
                        </Col>
                        </div>
                        )}
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg={8} xs={12}>
            <Card className={classes.root}>
              <CardActionArea>
                <CardContent>
                  <Typography variant="h6"  component="h3">
                    FAQ
                  </Typography>
                  <div style={{display: 'flex', flexDirection: "column", justifyContent: 'center', marginTop: 30}}>
                  {store.faq&& store.faq.questions.map((item, i) => {
                    return (
                      <div key={i} style={{marginBottom: 20}}>
                        <div style={{display: 'flex', flexDirection: "row", justifyContent: 'space-between', }}>
                        <Typography variant="h6"  component="p">
                          {item.question}
                        </Typography>
                        <Button title="Delete" onClick={()=> deleteFAQ(item)}>
                          <DeleteIcon style={{color:'red'}} />
                        </Button>
                        </div>
                        <Typography variant="subtitle2"  component="subtitle2">
                          {item.answer}
                        </Typography>
                      </div>
                    );
                  })}
                  </div>
                </CardContent>
              </CardActionArea>
            </Card>
            </Col>
          </Row>
          <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success">
              {alertMessage}
            </Alert>
          </Snackbar>
        </Container>
    </React.Fragment>
  );
};


export default EditFAQ;
