import React from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Input,
  CardBody,
  Label,
  Modal,
  ModalBody
} from "reactstrap";
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';

import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

import Divider from '@mui/material/Divider';
import Collapse from '@mui/material/Collapse';
import ListItemButton from '@mui/material/ListItemButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import { PaystackButton } from 'react-paystack';
import fire from "../Fire.js";
import firebase from 'firebase';

const useStyles = makeStyles({
  root: {
  },
  media: {
    height: 500,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  media2: {
    height: 200,
    marginTop: 40,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

function useStore() {
  const [store, setStore] = React.useState({});
  const user = fire.auth().currentUser;

  React.useEffect(() => {
    const unsubscribe = fire
    .firestore()
    .collection("users")
    .doc(user.uid)
    .collection("stores")
    .onSnapshot(querySnapshot => {
      querySnapshot.forEach((doc) => {
        var domains = [];
        fire
        .firestore()
        .collection("users")
        .doc(user.uid)
        .collection("stores")
        .doc(doc.id)
        .collection("domains")
        .get()
        .then(col => {
          col.forEach((domain) => {
            domains.push(domain.data())
          })
        }).then(() => {
          setStore(Object.assign(doc.data(), {storeId: doc.id, domains: domains}));
        })
      })
    });
    return () => unsubscribe;
  }, [user.uid]);
  
  return store;
};

const EditDomains = ({profile}) => {
  const classes = useStyles();
  const publicKey = "pk_live_9296b30945eba87a279e3d7ca33cefb6fc2fa261";
  const user = fire.auth().currentUser;
  const store = useStore();
  const [openAlert, setOpenAlert] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");

  const [currentDomain, setCurrentDomain] = React.useState("");

  const [openDomainModal, setOpenDomainModal] = React.useState(false);

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const steps = ['Select custom domain', 'Choose domain name', 'Activate DNS'];

  const [customDomainOption, setCustomDomainOption] = React.useState("defaultDomain");
  const [customDomainError, setCustomDomainError] = React.useState("");
  const [newCustomDomainName, setNewCustomDomainName] = React.useState("");

  const [amount, setAmount] = React.useState(500000);
  


  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert(false);
  };

  const closeModal = () => {
    setOpenDomainModal(false);
    setCustomDomainOption("defaultDomain")
  };

  const componentProps = {
    email: user.email,
    publicKey,
    amount: amount,
    onSuccess: () => {

      const changeNetlifySiteDomain = fire.functions().httpsCallable('changeNetlifySiteDomain');
      changeNetlifySiteDomain({email: user.email, storeName: store.storeName, storeId: store.storeId, userUid: user.uid, type: "buyDomain", netlifySiteId: store.netlifySiteId, customDomain: newCustomDomainName, }).then(result => {
        console.log(result.data);
      });
      setActiveStep(3);
      setOpenDomainModal(true);
      setAmount(500000);
    },
    onClose: () => {
      setAmount(500000)
    },
  };

  let payButton;

  if (customDomainOption === "buyDomain" && newCustomDomainName) {
    payButton = (
      <PaystackButton {...componentProps} className="btn btn-primary">
        Buy domain {newCustomDomainName} for 1 year
      </PaystackButton>
    );
} else {
  payButton = (
    <div></div>
  );
}

  const handleChangeCustomDomainOption = (event) => {
    setCustomDomainOption(event.target.value);
  };


  const isStepOptional = (step) => {
    return false;
    //return step === 2;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    if (customDomainOption === "defaultDomain") {
      setActiveStep(3);
      const changeNetlifySiteDomain = fire.functions().httpsCallable('changeNetlifySiteDomain');
      changeNetlifySiteDomain({email: user.email, storeName: store.storeName, storeId: store.storeId, userUid: user.uid, type: "resetDomain", netlifySiteId: store.netlifySiteId, customDomain: store.storeName+".myswhag.shop"}).then(result => {
        console.log(result.data);
      });
    } else{
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    }
  };

  const chooseDomainName = () => {

    const domainName = document.getElementById("domainName").value;
    if (customDomainOption === "myDomain") {
      if ((domainName === "") || (domainName.includes("myswhag"))) {
        setCustomDomainError("Invalid Domain")
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setCustomDomainError("");
        setNewCustomDomainName(domainName);
      }
    }

    if (customDomainOption === "buyDomain") {
      if ((domainName === "") || (domainName.includes("myswhag"))) {
        setCustomDomainError("Invalid Domain")
      } else {
        setOpenDomainModal(false)
        setCustomDomainError("")
        setNewCustomDomainName(domainName);
        if(domainName.slice(-7) === ".com.ng"){
          setAmount(200000)
        }
      }
    }

  };

  const connectDomain = () => {

    if (customDomainOption === "myDomain") {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      const changeNetlifySiteDomain = fire.functions().httpsCallable('changeNetlifySiteDomain');
      changeNetlifySiteDomain({email: user.email, storeName: store.storeName, storeId: store.storeId, userUid: user.uid, type: "linkMyDomain", netlifySiteId: store.netlifySiteId, customDomain: newCustomDomainName}).then(result => {
        console.log(result.data);
      });
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };


  return (
    <React.Fragment>

        <Container>
          <Row>
            <Col lg={12} xs={12}>
              <Card className="shadow rounded border-0">
                <CardBody className="py-5">
                  <h4 className="card-title">Edit Domains</h4>
                  {payButton}
                  <Col md={4} >
                    <Button color="primary" variant="contained" onClick={() => {setOpenDomainModal(true); setActiveStep(0); setCustomDomainOption("defaultDomain")}} style={{borderRadius: 20, width: '100%'}}>Change or add custom domain</Button>
                  </Col>
                  <div className="custom-form mt-3">
                    <List >
                      <Divider />
                      <ListItem>
                        <ListItemText
                          primary={store.storeName ? store.storeName.replace(/[^a-zA-Z0-9]/g,'-')+".myswhag.shop" : ""}
                          secondary={store.storeName ? ((store.custom_domain === store.storeName.replace(/[^a-zA-Z0-9]/g,'-')+".myswhag.shop")? "Primary domain": "") : ""}
                        />
                      </ListItem>
                      {store.domains && store.domains.map((domain, i) => {
                        return (
                          <div key={i}>
                          <Divider />
                          <ListItemButton onClick={() => (currentDomain === domain.custom_domain) ? setCurrentDomain("") : setCurrentDomain(domain.custom_domain)} >
                            <ListItemText
                              primary={domain.custom_domain}
                              secondary={(store.custom_domain === domain.custom_domain)? "Primary domain": ""}
                            />
                            {(currentDomain === domain.custom_domain) ? <ExpandLess /> : <ExpandMore />}
                          </ListItemButton>
                          <Divider />
                          <Collapse in={currentDomain === domain.custom_domain} timeout="auto" unmountOnExit>
                            <Typography component="h5">Name servers</Typography>
                            <Typography component="p">Login into your domain provider and change your name servers to point to our servers</Typography>
                            <List component="div" disablePadding>
                              {domain.dns_servers && domain.dns_servers.map((name, x) => {
                                return (
                                  <div key={x}>
                                  <ListItem sx={{ pl: 4 }} >
                                    <ListItemText primary={name} />
                                  </ListItem>
                                  </div>
                                );
                              })}
                            </List>
                          </Collapse>
                          </div>
                        );
                      })}
                    </List>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal isOpen={openDomainModal} toggle={closeModal} centered size="lg">

            <ModalBody className="p-4">
            <Container fluid className="px-0">
            <h4 className="card-title">Change your custom domain</h4>
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                if (isStepOptional(index)) {
                  labelProps.optional = (
                    <Typography variant="caption">Optional</Typography>
                  );
                }
                if (isStepSkipped(index)) {
                  stepProps.completed = false;
                }
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            {(activeStep === 0) && (
              <React.Fragment>
                <Typography sx={{ mt: 2, mb: 1 }}>Select an option</Typography>
                <FormControl >
                  {/* <FormLabel id="demo-radio-buttons-group-label">Domain type</FormLabel> */}
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="defaultDomain"
                    name="radio-buttons-group"
                    onChange={handleChangeCustomDomainOption}
                    value={customDomainOption}
                  >
                    <FormControlLabel value="defaultDomain" control={<Radio />} label={"Use your default SWHAG domain: ("+store.storeName+".myswhag.shop)"} />
                    <FormControlLabel value="myDomain" control={<Radio />} label="Connect a domain name that you already own" />
                    <FormControlLabel value="buyDomain" control={<Radio />} label="Buy a .com.ng domain name. (One-time payment)" />
                  </RadioGroup>
                </FormControl>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    Back
                  </Button>
                  <Box sx={{ flex: '1 1 auto' }} />
                  <Button onClick={closeModal}>Close</Button>
                  <Button onClick={handleNext}>
                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                  </Button>
                </Box>
              </React.Fragment>
            )}
            {(activeStep === 1) && (
              <React.Fragment>
                {(customDomainOption === "myDomain")&&(<Typography sx={{ mt: 2, mb: 1 }}>Enter the domain name you already own</Typography>)}
                {(customDomainOption === "buyDomain")&&(<Typography sx={{ mt: 2, mb: 1 }}>Enter the domain name would like to buy</Typography>)}
                {(customDomainOption === "buyDomain")&&(<Typography sx={{ mt: 2, mb: 1 }}>.com.ng = ₦2,000</Typography>)}
                {(customDomainOption === "buyDomain")&&(<Typography sx={{ mt: 2, mb: 1 }}>.com = ₦5,000</Typography>)}
                <Row>
                  <Col md={12}>
                    <Typography style={{color: "red"}}>{customDomainError}</Typography>
                    <div className="mb-3">
                      <Label className="form-label">
                        Your Domain Name <span className="text-danger">*</span>
                      </Label>
                      <Input
                        id="domainName"
                        type="text"
                        className="form-control ps-5"
                        placeholder="Example.com.ng"
                        required
                      />
                    </div>
                  </Col>
                </Row>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    Back
                  </Button>
                  <Box sx={{ flex: '1 1 auto' }} />

                  <Button onClick={chooseDomainName}>
                    {activeStep === steps.length - 1 ? 'Finish' : 'Confirm'}
                  </Button>
                </Box>
              </React.Fragment>
            )}

            {(activeStep === 2) && (
              <React.Fragment>
                <Typography sx={{ mt: 2, mb: 1 }}>Update your domain's name servers</Typography>
                <Typography>Your Domain: ({newCustomDomainName})</Typography>
                <Typography sx={{ mt: 2, mb: 1 }}>Login into your domain provider and change your name servers to point to our servers</Typography>
                <Typography sx={{ mt: 2, mb: 1 }}>We will send an email to {user.email} containing the details</Typography>
                {/* <List >
                  <ListItem>
                    <ListItemText
                      primary="dns1.p03.nsone.net"
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="dns2.p03.nsone.net"
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="dns3.p03.nsone.net"
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="dns4.p03.nsone.net"
                    />
                  </ListItem>
                </List> */}
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    Back
                  </Button>
                  <Box sx={{ flex: '1 1 auto' }} />

                  <Button onClick={connectDomain}>
                    {'Connect domain'}
                  </Button>
                </Box>
              </React.Fragment>
            )}
            {(activeStep === steps.length) && (
              <React.Fragment>
                <Typography sx={{ mt: 2, mb: 1 }}>
                  All steps completed
                </Typography>
                {(customDomainOption === "defaultDomain") && (
                  <Typography sx={{ mt: 2, mb: 1 }}>
                    You have succesfully changed your custom domain to the default SWHAG domain we provide, this change may take up to 20 mins to reflect.
                  </Typography>
                )}
                {(customDomainOption === "myDomain") && (
                  <Typography sx={{ mt: 2, mb: 1 }}>
                    You have succesfully changed your custom domain. Once you've made the changes to your name servers your website should be deployed to your domain within 24hrs
                  </Typography>
                )}
                {(customDomainOption === "buyDomain") && (
                  <Typography sx={{ mt: 2, mb: 1 }}>
                    You have succesfully bought a new domain name, your updated website should be up within 24 hours.
                  </Typography>
                )}
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Box sx={{ flex: '1 1 auto' }} />
                  <Button onClick={closeModal}>Close</Button>
                </Box>
              </React.Fragment>
            )}
            </Container>
            </ModalBody>
          </Modal>
          <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success">
              {alertMessage}
            </Alert>
          </Snackbar>
        </Container>
    </React.Fragment>
  );
};


export default EditDomains;
