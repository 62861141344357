import React from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Input,
  CardBody,
  Label,
  Modal,
  ModalBody
} from "reactstrap";
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';


import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import { Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';

import { PaystackButton } from 'react-paystack';
import fire from "../Fire.js";
import firebase from 'firebase';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const useStyles = makeStyles({
  root: {
  },
  media: {
    height: 500,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  media2: {
    height: 200,
    marginTop: 40,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
});

// function addHoursToDate(date, hours) {
//   return new Date(new Date(date).setHours(date.getHours() + hours));
// };

function useStore() {
  const [store, setStore] = React.useState({});
  const user = fire.auth().currentUser;

  React.useEffect(() => {
    const unsubscribe = fire
    .firestore()
    .collection("users")
    .doc(user.uid)
    .collection("stores")
    .onSnapshot(querySnapshot => {
      querySnapshot.forEach(doc => {
        setStore(Object.assign(doc.data(), {storeId: doc.id,}));
      })
    });
    return () => unsubscribe;
  }, [user.uid]);
  
  return store;
};

const EditStore = ({profile}) => {
  const classes = useStyles();
  const user = fire.auth().currentUser;
  const store = useStore();
  const publicKey = "pk_live_9296b30945eba87a279e3d7ca33cefb6fc2fa261";
  const [isOpenNewCollection, setOpenNewCollection] = React.useState(false);
  const [currentCollection, setCurrentCollection] = React.useState();
  const [currentSlide, setCurrentSlide] = React.useState({});
  const [isOpenNewSlider, setOpenNewSlider] = React.useState(false);
  const [isOpenEditSlider, setOpenEditSlider] = React.useState(false);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [openDomainModal, setOpenDomainModal] = React.useState(false);

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const steps = ['Select custom domain', 'Choose domain name', 'Activate DNS'];

  const [customDomainOption, setCustomDomainOption] = React.useState("defaultDomain");
  const [customDomainError, setCustomDomainError] = React.useState("");
  const [newCustomDomainName, setNewCustomDomainName] = React.useState("");

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert(false);
  };

  const updateStore = (e) => {
    e.preventDefault();
    const contactEmail = document.getElementById("contactEmail").value;
    const businessName = document.getElementById("businessName").value;
    const contactPhoneNumber = document.getElementById("contactPhoneNumber").value;
    const instagramLink = document.getElementById("instagramLink").value;
    const facebookLink = document.getElementById("facebookLink").value;
    const twitterLink = document.getElementById("twitterLink").value;
    const youtubeLink = document.getElementById("youtubeLink").value;

    fire
    .firestore()
    .collection("users")
    .doc(user.uid)
    .collection("stores")
    .doc(store.storeId)
    .update({
      contactEmail: contactEmail,
      contactPhoneNumber: contactPhoneNumber,
      instagramLink: instagramLink,
      facebookLink: facebookLink,
      twitterLink: twitterLink,
      youtubeLink: youtubeLink,
      businessName: businessName,
    });
    fire
    .firestore()
    .collection("stores")
    .doc(store.storeId)
    .update({
      contactEmail: contactEmail,
      contactPhoneNumber: contactPhoneNumber,
      instagramLink: instagramLink,
      facebookLink: facebookLink,
      twitterLink: twitterLink,
      youtubeLink: youtubeLink,
      businessName: businessName,
    });

    setAlertMessage("Changes have been saved")
    setOpenAlert(true)

  };

  const closeModal = () => {
    setOpenNewCollection(false);
    setOpenDomainModal(false);
    setCustomDomainOption("defaultDomain")
  };

  const createCollection = (e) => {
    e.preventDefault();
    const collectionName = document.getElementById("collectionName").value;
    setOpenNewCollection(false);
    fire
    .firestore()
    .collection("users")
    .doc(user.uid)
    .collection("stores")
    .doc(store.storeId)
    .update({
      collections: firebase.firestore.FieldValue.arrayUnion({name: collectionName, picUrl: "https://firebasestorage.googleapis.com/v0/b/swhag-1a9e3.appspot.com/o/images%2Fcollection%2Ffea3.jpg?alt=media&token=1e0dec2b-2a37-4924-a67d-31851570f0dc"}),
    });
    fire
    .firestore()
    .collection("stores")
    .doc(store.storeId)
    .update({
      collections: firebase.firestore.FieldValue.arrayUnion({name: collectionName, picUrl: "https://firebasestorage.googleapis.com/v0/b/swhag-1a9e3.appspot.com/o/images%2Fcollection%2Ffea3.jpg?alt=media&token=1e0dec2b-2a37-4924-a67d-31851570f0dc"}),
    });
  };

  const deleteCollection = (col) => {
    fire
    .firestore()
    .collection("users")
    .doc(user.uid)
    .collection("stores")
    .doc(store.storeId)
    .update({
      collections: firebase.firestore.FieldValue.arrayRemove(col),
    });
    fire
    .firestore()
    .collection("stores")
    .doc(store.storeId)
    .update({
      collections: firebase.firestore.FieldValue.arrayRemove(col),
    });
  };

  const changeCollectionImage = (e) => {
    const image = e.target.files[0];
    const storeDocument = fire.firestore().collection("users").doc(user.uid).collection("stores").doc(store.storeId);
    const publicStoreDocument = fire.firestore().collection("stores").doc(store.storeId);
    const storageRef = fire.storage().ref('users/'+user.uid+'/stores/'+store.storeId);
    const fileRef = storageRef.child(currentCollection.name);
    fileRef.put(image).then((e) => {
      fileRef.getDownloadURL().then((url) => {
        storeDocument
        .update({
          collections: firebase.firestore.FieldValue.arrayRemove(currentCollection),
        });
        publicStoreDocument
        .update({
          collections: firebase.firestore.FieldValue.arrayRemove(currentCollection),
        });
        storeDocument
        .update({
          collections: firebase.firestore.FieldValue.arrayUnion({name: currentCollection.name, picUrl: url}),
        });
        publicStoreDocument
        .update({
          collections: firebase.firestore.FieldValue.arrayUnion({name: currentCollection.name, picUrl: url}),
        });
      });
    });
  };

  const handleChangeShowPage = (event) => {
    fire
    .firestore()
    .collection("users")
    .doc(user.uid)
    .collection("stores")
    .doc(store.storeId)
    .update({
      showExplore: event.target.checked,
    });
    fire
    .firestore()
    .collection("stores")
    .doc(store.storeId)
    .update({
      showExplore: event.target.checked,
    });
  };

  const addImageSlider = () => {

    const title = document.getElementById("title").value;
    const subtitle = document.getElementById("subtitle").value;
    const button = document.getElementById("button").value;
    const url = document.getElementById("url").value;
    setOpenNewSlider(false);

    fire
    .firestore()
    .collection("users")
    .doc(user.uid)
    .collection("stores")
    .doc(store.storeId)
    .update({
      heroSliders: firebase.firestore.FieldValue.arrayUnion({
        title: title,
        subtitle: subtitle,
        button: button,
        url: url,
        image: "",
        bgImage: "https://firebasestorage.googleapis.com/v0/b/swhag-1a9e3.appspot.com/o/images%2Fcollection%2Ffea1.jpg?alt=media&token=6ed8d9d7-af9f-420e-bfba-3133aedcd99d",
      }),
    });
    fire
    .firestore()
    .collection("stores")
    .doc(store.storeId)
    .update({
      heroSliders: firebase.firestore.FieldValue.arrayUnion({
        title: title,
        subtitle: subtitle,
        button: button,
        url: url,
        image: "",
        bgImage: "https://firebasestorage.googleapis.com/v0/b/swhag-1a9e3.appspot.com/o/images%2Fcollection%2Ffea1.jpg?alt=media&token=6ed8d9d7-af9f-420e-bfba-3133aedcd99d",
      }),
    });
    setAlertMessage("Slide succesfully created")
    setOpenAlert(true)
  };

  const deleteSlide = (slide) => {
    fire
    .firestore()
    .collection("users")
    .doc(user.uid)
    .collection("stores")
    .doc(store.storeId)
    .update({
      heroSliders: firebase.firestore.FieldValue.arrayRemove(slide),
    });
    fire
    .firestore()
    .collection("stores")
    .doc(store.storeId)
    .update({
      heroSliders: firebase.firestore.FieldValue.arrayRemove(slide),
    });
    setAlertMessage("Slide succesfully deleted")
    setOpenAlert(true)
  };

  const updateSlide = () => {
    const title = document.getElementById("title").value;
    const subtitle = document.getElementById("subtitle").value;
    const button = document.getElementById("button").value;
    const url = document.getElementById("url").value;
    fire
    .firestore()
    .collection("users")
    .doc(user.uid)
    .collection("stores")
    .doc(store.storeId)
    .update({
      heroSliders: firebase.firestore.FieldValue.arrayRemove(currentSlide),
    });
    fire
    .firestore()
    .collection("stores")
    .doc(store.storeId)
    .update({
      heroSliders: firebase.firestore.FieldValue.arrayRemove(currentSlide),
    });

    fire
    .firestore()
    .collection("users")
    .doc(user.uid)
    .collection("stores")
    .doc(store.storeId)
    .update({
      heroSliders: firebase.firestore.FieldValue.arrayUnion({
        title: title,
        subtitle: subtitle,
        button: button,
        url: url,
        image: "",
        bgImage: currentSlide.bgImage,
      }),
    });
    fire
    .firestore()
    .collection("stores")
    .doc(store.storeId)
    .update({
      heroSliders: firebase.firestore.FieldValue.arrayUnion({
        title: title,
        subtitle: subtitle,
        button: button,
        url: url,
        image: "",
        bgImage: currentSlide.bgImage,
      }),
    });
    setOpenEditSlider(false)
    setAlertMessage("Changes have been saved")
    setOpenAlert(true)
  };

  const changeSlideImage = (e) => {
    const image = e.target.files[0];
    const storeDocument = fire.firestore().collection("users").doc(user.uid).collection("stores").doc(store.storeId);
    const publicStoreDocument = fire.firestore().collection("stores").doc(store.storeId);
    const storageRef = fire.storage().ref('users/'+user.uid+'/stores/'+store.storeId);
    const fileRef = storageRef.child(currentSlide.title);
    fileRef.put(image).then((e) => {
      fileRef.getDownloadURL().then((url) => {
        storeDocument
        .update({
          heroSliders: firebase.firestore.FieldValue.arrayRemove(currentSlide),
        });
        publicStoreDocument
        .update({
          heroSliders: firebase.firestore.FieldValue.arrayRemove(currentSlide),
        });
        storeDocument
        .update({
          heroSliders: firebase.firestore.FieldValue.arrayUnion(Object.assign(currentSlide, {bgImage: url})),
        });
        publicStoreDocument
        .update({
          heroSliders: firebase.firestore.FieldValue.arrayUnion(Object.assign(currentSlide, {bgImage: url})),
        });
        setAlertMessage("Image uploading may take up to 1 minute");
        setOpenAlert(true)
        setOpenEditSlider(false)
      });
    });
  };

  const componentProps = {
    email: user.email,
    publicKey,
    amount: 399900,
    onSuccess: () => {

      const changeNetlifySiteDomain = fire.functions().httpsCallable('changeNetlifySiteDomain');
      changeNetlifySiteDomain({email: user.email, storeName: store.storeName, storeId: store.storeId, userUid: user.uid, type: "linkMyDomain", netlifySiteId: store.netlifySiteId, customDomain: newCustomDomainName}).then(result => {
        console.log(result.data);
      });
      setActiveStep(3);
      setOpenDomainModal(true);
    },
    onClose: () => {
      // const changeNetlifySiteDomain = fire.functions().httpsCallable('changeNetlifySiteDomain');
      // changeNetlifySiteDomain({email: user.email, storeName: store.storeName, storeId: store.storeId, userUid: user.uid, type: "linkMyDomain", netlifySiteId: store.netlifySiteId, customDomain: newCustomDomainName}).then(result => {
      //   console.log(result.data);
      // });
      // setActiveStep(3);
      // setOpenDomainModal(true);
    },
  };

  let payButton;

  if (customDomainOption === "buyDomain" && newCustomDomainName) {
    payButton = (
      <PaystackButton {...componentProps} className="btn btn-primary">
        Buy domain {newCustomDomainName}
      </PaystackButton>
    );
} else {
  payButton = (
    <div></div>
  );
}

  const handleChangeCustomDomainOption = (event) => {
    setCustomDomainOption(event.target.value);
  };


  const isStepOptional = (step) => {
    return false;
    //return step === 2;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    if (customDomainOption === "defaultDomain") {
      setActiveStep(3);
      const changeNetlifySiteDomain = fire.functions().httpsCallable('changeNetlifySiteDomain');
      changeNetlifySiteDomain({email: user.email, storeName: store.storeName, storeId: store.storeId, userUid: user.uid, type: "resetDomain", netlifySiteId: store.netlifySiteId, customDomain: store.storeName+".myswhag.shop"}).then(result => {
        console.log(result.data);
      });
    } else{
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    }
  };

  const chooseDomainName = () => {

    const domainName = document.getElementById("domainName").value;
    if (customDomainOption === "myDomain") {
      if ((domainName === "") || (domainName.includes("myswhag"))) {
        setCustomDomainError("Invalid Domain")
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setCustomDomainError("");
        setNewCustomDomainName(domainName);
      }
    }

    if (customDomainOption === "buyDomain") {
      if ((domainName === "") || (domainName.includes("myswhag"))) {
        setCustomDomainError("Invalid Domain")
      } else {
        setOpenDomainModal(false)
        setCustomDomainError("")
        setNewCustomDomainName(domainName);
      }
    }

  };

  const connectDomain = () => {

    if (customDomainOption === "myDomain") {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      const changeNetlifySiteDomain = fire.functions().httpsCallable('changeNetlifySiteDomain');
      changeNetlifySiteDomain({email: user.email, storeName: store.storeName, storeId: store.storeId, userUid: user.uid, type: "linkMyDomain", netlifySiteId: store.netlifySiteId, customDomain: newCustomDomainName}).then(result => {
        console.log(result.data);
      });
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <React.Fragment>

        <Container>
          <Row>
            <Col lg={4} xs={12}>
              <Card className="shadow rounded border-0">
                <CardBody className="py-5">
                  <h4 className="card-title">Edit your store!</h4>
                  <div className="custom-form mt-3">
                    <div id="message"></div>
                    <Form
                      method="post"
                      onSubmit={updateStore}
                      name="contact-form"
                      id="contact-form"
                    >
                      <Row>


                        {(store.custom_domain) && (
                          <div>
                            {/* {payButton}
                          <Col md={12} >
                            <Button color="primary" variant="contained" onClick={() => {setOpenDomainModal(true); setActiveStep(0); setCustomDomainOption("defaultDomain")}} style={{borderRadius: 20, width: '100%'}}>Change your custom domain</Button>
                          </Col> */}
                          <Col md={12}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Your Custom domain link
                              </Label>
                              <Typography variant="h5">
                                {(store.custom_domain)}
                              </Typography>
                              <a target="_blank" href={"//"+store.custom_domain} rel="noreferrer">Preview</a>
                            </div>
                          </Col>
                          </div>
                        )}

                        {!(store.custom_domain) && (store.default_domain) && (
                          <Col md={12}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Your Default website link
                              </Label>
                              <Typography variant="h5">
                                {(store.default_domain)}
                              </Typography>
                              <a target="_blank" href={"//"+store.default_domain} rel="noreferrer">Preview</a>
                              <br/>
                              <Typography variant="p">
                                This is just a temporary domain link, due to high demand your custom domain may take up to 30 minutes to build and deploy.
                              </Typography>
                            </div>
                          </Col>
                        )}
                        {!(store.custom_domain) && !(store.default_domain) && (
                          <Col md={12}>
                            <div className="mb-3">
                              <Typography variant="p">
                                Your website is currently being built, due to high demand your website may take up to 30 minutes to build and deploy.
                              </Typography>
                            </div>
                          </Col>
                        )}
                        {(isOpenNewSlider === false) && (isOpenEditSlider === false)&&(
                        <Col md={12} >
                          <Button color="primary" variant="contained" onClick={() => setOpenNewSlider(true)} style={{borderRadius: 20, width: '100%'}}>Add image slide</Button>
                        </Col>
                        )}
                        {(isOpenNewSlider === true)&&(
                          <div>
                            <Col md={12}>
                              <div className="mb-3">
                                <Label className="form-label">
                                  Title
                                </Label>
                                <Input
                                  id="title"
                                  type="text"
                                  className="form-control "
                                  required
                                />
                              </div>
                            </Col>
                            <Col md={12}>
                              <div className="mb-3">
                                <Label className="form-label">
                                  Subtitle
                                </Label>
                                <Input
                                  id="subtitle"
                                  type="text"
                                  className="form-control "
                                  required
                                />
                              </div>
                            </Col>
                            <Col md={12}>
                              <div className="mb-3">
                                <Label className="form-label">
                                  Button label
                                </Label>
                                <Input
                                  id="button"
                                  type="text"
                                  className="form-control "
                                  required
                                  placeholder="Shop collection"
                                />
                              </div>
                            </Col>
                            <Col md={12}>
                              <div className="mb-3">
                                <Label className="form-label">
                                  Button link
                                </Label>
                                <Input
                                  id="url"
                                  type="text"
                                  className="form-control "
                                  required
                                  placeholder="/shop/all"
                                />
                              </div>
                            </Col>
                            <Col md={12} >
                              <Button color="primary" variant="contained" onClick={addImageSlider} style={{borderRadius: 20, width: '100%'}}>Create slide</Button>
                            </Col>
                          </div>
                        )}
                        {(isOpenEditSlider === true) && (
                          <div>
                            <Col md={12} style={{borderStyle: 'solid', borderRadius: 20, paddingTop: 5, paddingBottom: 5, borderColor: '#2f55d4', marginTop: 10}}>
                              <InputLabel style={{display: 'flex', justifyContent: 'center',alignItems:'center', }}>
                                <Input type="file" onChange={changeSlideImage} style={{display: 'none'}} />
                                <Typography color="primary">Change slide image</Typography>
                              </InputLabel>
                            </Col>
                            <Col md={12}>
                              <div className="mb-3">
                                <Label className="form-label">
                                  Title
                                </Label>
                                <Input
                                  id="title"
                                  type="text"
                                  className="form-control "
                                  defaultValue={currentSlide.title}
                                  required
                                />
                              </div>
                            </Col>
                            <Col md={12}>
                              <div className="mb-3">
                                <Label className="form-label">
                                  Subtitle
                                </Label>
                                <Input
                                  id="subtitle"
                                  type="text"
                                  className="form-control "
                                  defaultValue={currentSlide.subtitle}
                                  required
                                />
                              </div>
                            </Col>
                            <Col md={12}>
                              <div className="mb-3">
                                <Label className="form-label">
                                  Button label
                                </Label>
                                <Input
                                  id="button"
                                  type="text"
                                  className="form-control "
                                  required
                                  placeholder="Shop collection"
                                  defaultValue={currentSlide.button}
                                />
                              </div>
                            </Col>
                            <Col md={12}>
                              <div className="mb-3">
                                <Label className="form-label">
                                  Button link
                                </Label>
                                <Input
                                  id="url"
                                  type="text"
                                  className="form-control "
                                  required
                                  placeholder="/shop/all"
                                  defaultValue={currentSlide.url}
                                />
                              </div>
                            </Col>
                            <Col md={12} >
                              <Button color="primary" variant="contained" onClick={updateSlide} style={{borderRadius: 20, width: '100%'}}>Update slide</Button>
                            </Col>
                          </div>
                        )}

                        <Col md={12}>
                          <div className="mb-3">
                            <Label className="form-label">
                              Show Explore page on your website?
                            </Label>
                            <Switch
                              checked={store? store.showExplore : false}
                              onChange={handleChangeShowPage}
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                          </div>
                        </Col>
                        <Col md={12} >
                          <Button color="primary" variant="contained" onClick={() => setOpenNewCollection(true)} style={{borderRadius: 20, width: '100%'}}>Create a collection</Button>
                        </Col>

                        <Col md={12}>
                          <div className="mb-3">
                            <Label className="form-label">
                              Business name
                            </Label>
                            <Input
                              id="businessName"
                              type="text"
                              className="form-control "
                              defaultValue={store.businessName?store.businessName:store.storeName}
                              required
                            />
                          </div>
                        </Col>

                        <Col md={12}>
                          <div className="mb-3">
                            <Label className="form-label">
                              Contact email
                            </Label>
                            <Input
                              id="contactEmail"
                              type="text"
                              className="form-control "
                              defaultValue={store.contactEmail}
                              required
                            />
                          </div>
                        </Col>
                        <Col md={12}>
                          <div className="mb-3">
                            <Label className="form-label">
                            Contact phonenumber
                            </Label>
                            <Input
                              id="contactPhoneNumber"
                              type="text"
                              className="form-control "
                              defaultValue={store.contactPhoneNumber}
                              required
                            />
                          </div>
                        </Col>
                        <Col md={12}>
                          <div className="mb-3">
                            <Label className="form-label">
                            Instagram link
                            </Label>
                            <Input
                              id="instagramLink"
                              type="text"
                              className="form-control "
                              defaultValue={store.instagramLink}
                            />
                          </div>
                        </Col>
                        <Col md={12}>
                          <div className="mb-3">
                            <Label className="form-label">
                            Facebook link
                            </Label>
                            <Input
                              id="facebookLink"
                              type="text"
                              className="form-control "
                              defaultValue={store.facebookLink}
                            />
                          </div>
                        </Col>
                        <Col md={12}>
                          <div className="mb-3">
                            <Label className="form-label">
                            Twitter link
                            </Label>
                            <Input
                              id="twitterLink"
                              type="text"
                              className="form-control "
                              defaultValue={store.twitterLink}
                            />
                          </div>
                        </Col>
                        <Col md={12}>
                          <div className="mb-3">
                            <Label className="form-label">
                            Youtube link
                            </Label>
                            <Input
                              id="youtubeLink"
                              type="text"
                              className="form-control "
                              defaultValue={store.youtubeLink}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12} className="text-center">
                          <div className="d-grid">
                            <input
                              type="submit"
                              id="submit"
                              name="send"
                              className="submitBnt btn btn-primary"
                              value="Update Store"
                            />
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg={8} xs={12}>
            <Card className={classes.root}>
              <CardActionArea>
                <Swiper
                  spaceBetween={0}
                  slidesPerView={1}
                  onSlideChange={() => {}}
                  onSwiper={(swiper) => {}}
                >
                  {store.heroSliders && store.heroSliders.map((slide, i) => {
                    return(
                      <SwiperSlide key={i}>
                      <CardMedia
                        className={classes.media}
                        image={slide.bgImage}
                        title="Main image"
                      >
                        <div style={{marginTop: 30, display: 'flex', justifyContent: 'space-between'}}>
                          <Typography gutterBottom variant="h5" style={{marginLeft: 40,}} >
                            {store.storeName}
                          </Typography>
                          <div  style={{marginRight: 40,}} >
                            <Button title="edit" onClick={()=> {setCurrentSlide(slide); setOpenEditSlider(true)}}>
                              Edit slide
                            </Button>
                            <Button title="Delete" onClick={()=> deleteSlide(slide)}>
                              <DeleteIcon style={{color:'red'}} />
                            </Button>
                          </div>
                        </div>
                        <div style={{marginBottom: 100, marginLeft: 40, maxWidth: '60%'}}>
                          <Typography variant="body2" component="h6">
                            {slide.subtitle}
                          </Typography>
                          <Typography variant="h4" component="p">
                            {slide.title}
                          </Typography>
                          <Typography variant="body2" color="textSecondary" component="p">
                            {slide.button}
                          </Typography>
                        </div>
                        <Typography variant="body2" component="h6">Swipe to the next slide</Typography>
                      </CardMedia>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
                
                {(store.collections) && (
                  <Row>
                  {store.collections.map((col, index) => {
                  return (
                    <Col lg={6} xs={12} key={index} className="mt-4 pt-2">
                      <Card
                        className="shop-features border-0 rounded overflow-hidden"
                      >
                      <img src={col.picUrl} className="img-fluid" alt="" />
                        <div className="category-title">
                          <h4>
                            {col.name}
                          </h4>
                          <div onClick={() => setCurrentCollection(col)}>
                          <InputLabel className="btn btn-sm btn-soft-primary mt-2">
                            <Input type="file" onChange={changeCollectionImage} style={{display: 'none'}} />
                            Change image
                          </InputLabel>
                          </div>
                          <div className="btn btn-sm btn-soft-primary mt-2" onClick={() => deleteCollection(col)}>
                            <div style={{color: 'red'}}>Delete Collection</div>
                          </div>
                        </div>
                      </Card>
                    </Col>
                  );
                  })}
                  </Row>
                )}
              </CardActionArea>
            </Card>
            </Col>
          </Row>
          <Modal isOpen={isOpenNewCollection} toggle={closeModal} centered size="lg">

            <ModalBody className="p-4">
              <Container fluid className="px-0">
                <Row>
                  <h4 className="card-title">Create your Collection!</h4>
                  <h5 className="text-muted">Organise your products by adding them to collections</h5>
                  <div className="custom-form mt-3">
                    <Form
                      method="post"
                      onSubmit={createCollection}
                      name="contact-form"
                      id="contact-form"
                    >
                      <Row>
                        <Col md={12}>
                          <div className="mb-3">
                            <Label className="form-label">
                              Your Collection Name <span className="text-danger">*</span>
                            </Label>
                            <Input
                              id="collectionName"
                              type="text"
                              className="form-control ps-5"
                              placeholder="Collection Name: e.g Clothes"
                              required
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12} className="text-center">
                          <div className="d-grid">
                            <input
                              type="submit"
                              id="submit"
                              name="send"
                              className="submitBnt btn btn-primary"
                              value="Create collection"
                            />
                          </div>
                          <div id="simple-msg"></div>
                          <div onClick={closeModal} className="btn btn-soft-primary ms-2">
                            Close
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </Row>
              </Container>
            </ModalBody>
          </Modal>
          <Modal isOpen={openDomainModal} toggle={closeModal} centered size="lg">

            <ModalBody className="p-4">
            <Container fluid className="px-0">
            <h4 className="card-title">Change your custom domain</h4>
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                if (isStepOptional(index)) {
                  labelProps.optional = (
                    <Typography variant="caption">Optional</Typography>
                  );
                }
                if (isStepSkipped(index)) {
                  stepProps.completed = false;
                }
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            {(activeStep === 0) && (
              <React.Fragment>
                <Typography sx={{ mt: 2, mb: 1 }}>Select an option</Typography>
                <FormControl >
                  {/* <FormLabel id="demo-radio-buttons-group-label">Domain type</FormLabel> */}
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="defaultDomain"
                    name="radio-buttons-group"
                    onChange={handleChangeCustomDomainOption}
                    value={customDomainOption}
                  >
                    <FormControlLabel value="defaultDomain" control={<Radio />} label={"Use your default SWHAG domain: ("+store.storeName+".myswhag.shop)"} />
                    <FormControlLabel value="myDomain" control={<Radio />} label="Connect a domain name that you already own" />
                    <FormControlLabel value="buyDomain" control={<Radio />} label="Buy a .com.ng domain name" />
                  </RadioGroup>
                </FormControl>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    Back
                  </Button>
                  <Box sx={{ flex: '1 1 auto' }} />
                  <Button onClick={closeModal}>Close</Button>
                  <Button onClick={handleNext}>
                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                  </Button>
                </Box>
              </React.Fragment>
            )}
            {(activeStep === 1) && (
              <React.Fragment>
                {(customDomainOption === "myDomain")&&(<Typography sx={{ mt: 2, mb: 1 }}>Enter the domain name you already own</Typography>)}
                {(customDomainOption === "buyDomain")&&(<Typography sx={{ mt: 2, mb: 1 }}>Enter the domain name would like to buy</Typography>)}
                <Row>
                  <Col md={12}>
                    <Typography style={{color: "red"}}>{customDomainError}</Typography>
                    <div className="mb-3">
                      <Label className="form-label">
                        Your Domain Name <span className="text-danger">*</span>
                      </Label>
                      <Input
                        id="domainName"
                        type="text"
                        className="form-control ps-5"
                        placeholder="Example.com.ng"
                        required
                      />
                    </div>
                  </Col>
                </Row>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    Back
                  </Button>
                  <Box sx={{ flex: '1 1 auto' }} />

                  <Button onClick={chooseDomainName}>
                    {activeStep === steps.length - 1 ? 'Finish' : 'Confirm'}
                  </Button>
                </Box>
              </React.Fragment>
            )}

            {(activeStep === 2) && (
              <React.Fragment>
                <Typography sx={{ mt: 2, mb: 1 }}>Update your domain's name servers</Typography>
                <Typography>Your Domain: ({newCustomDomainName})</Typography>
                <Typography sx={{ mt: 2, mb: 1 }}>Login into your domain provider and change your name servers to point to our servers</Typography>
                <Typography sx={{ mt: 2, mb: 1 }}>We will send an email to {user.email} containing the details</Typography>
                {/* <List >
                  <ListItem>
                    <ListItemText
                      primary="dns1.p03.nsone.net"
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="dns2.p03.nsone.net"
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="dns3.p03.nsone.net"
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="dns4.p03.nsone.net"
                    />
                  </ListItem>
                </List> */}
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    Back
                  </Button>
                  <Box sx={{ flex: '1 1 auto' }} />

                  <Button onClick={connectDomain}>
                    {'Connect domain'}
                  </Button>
                </Box>
              </React.Fragment>
            )}
            {(activeStep === steps.length) && (
              <React.Fragment>
                <Typography sx={{ mt: 2, mb: 1 }}>
                  All steps completed
                </Typography>
                {(customDomainOption === "defaultDomain") && (
                  <Typography sx={{ mt: 2, mb: 1 }}>
                    You have succesfully changed your custom domain to the default SWHAG domain we provide, this change may take up to 20 mins to reflect.
                  </Typography>
                )}
                {(customDomainOption === "myDomain") && (
                  <Typography sx={{ mt: 2, mb: 1 }}>
                    You have succesfully changed your custom domain. Once you've made the changes to your name servers your website should be deployed to your domain within 24hrs
                  </Typography>
                )}
                {(customDomainOption === "buyDomain") && (
                  <Typography sx={{ mt: 2, mb: 1 }}>
                    You have succesfully bought a new domain name, your updated website should be up within 24 hours.
                  </Typography>
                )}
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Box sx={{ flex: '1 1 auto' }} />
                  <Button onClick={closeModal}>Close</Button>
                </Box>
              </React.Fragment>
            )}
            </Container>
            </ModalBody>
          </Modal>
          <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success">
              {alertMessage}
            </Alert>
          </Snackbar>
        </Container>
    </React.Fragment>
  );
};


export default EditStore;
