// React Basic and Bootstrap
import React from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
} from "reactstrap";

//Import components
import SectionTitle from "../components/Shared/SectionTitle";

//Import Images
const Pricing = ({changePlan}) =>  {
  const pricings = [
    {
      id: 3,
      title: "Premium",
      price: "5,999",
      duration: "month",
      buttonText: "Select plan",
      btnLink: "//app.swhag.co/signUp",
      planCode: "PLN_vjarptk9gxo4rqn",
      isActive: true,
      amount: 5999,
      features: [
        { title: "Fully customizable e-commerce website" },
        { title: "Connect your own domain" },
        { title: "2% + NGN 100 processing fee." },
        { title: "In app payments" },
        { title: "Manage all your products" },
        { title: "Sell your own products" },
        { title: "Access to all products on SWHAG" },
        { title: "Add unlimited products to your store" },
        { title: "Partner with unlimited brands" },
        { title: "Website analytics" },
      ],
    },
  ];
    return (

        <section className="section">
          <Container>
            {/* Render Section Title */}
            <SectionTitle
              title="Pricing"
              desc="Start selling various products online without buying any inventory."
            />

            <Row className="align-items-center">
              {/* pricing */}
              {/* <Col md={4} xs={12}  className="mt-4 pt-2">
                <Card className="pricing-rates business-rate shadow bg-light rounded text-center border-0">
                  <CardBody className="py-5">
                    <h6 className="title fw-bold text-uppercase text-primary mb-4">
                      Storefront
                    </h6>
                    <div className="d-flex justify-content-center mb-0">
                      <span className="h4 mb-0 mt-2">NGN</span>
                      <span className="price h1 mb-0">0</span> 
                    </div>
                    <span className="h4 align-self-end mb-1">
                      /month
                    </span>
                    <ul className="list-unstyled mb-0 ps-0">
                      <li key={1} className="h6 text-muted mb-0">
                        <span className="text-primary h5 me-2">
                          <i className="uil uil-check-circle align-middle"></i>
                        </span>
                          5% processing fee
                      </li>
                      <li key={2} className="h6 text-muted mb-0">
                        <span className="text-primary h5 me-2">
                          <i className="uil uil-check-circle align-middle"></i>
                        </span>
                        Free online store
                      </li>
                      <li key={3} className="h6 text-muted mb-0">
                        <span className="text-primary h5 me-2">
                          <i className="uil uil-check-circle align-middle"></i>
                        </span>
                        In app payments
                      </li>
                      <li key={4} className="h6 text-muted mb-0">
                        <span className="text-primary h5 me-2">
                          <i className="uil uil-check-circle align-middle"></i>
                        </span>
                        Manage all your products
                      </li>
                      <li key={5} className="h6 text-muted mb-0">
                        <span className="text-primary h5 me-2">
                          <i className="uil uil-check-circle align-middle"></i>
                        </span>
                        Sell your own products
                      </li>
                    </ul>
                    <Link
                      target="_blank"
                      rel="noreferrer"
                      to="//myswhag.com/azastore"
                      className="btn btn-primary mt-4"
                    >
                      Preview example store
                    </Link>
                  </CardBody>
                </Card>
              </Col> */}
              <Col md={4} xs={12} className="mt-4 pt-2">
                <Card className="pricing-rates business-rate shadow bg-light rounded text-center border-0">
                  <CardBody className="py-5">
                    <h6 className="title fw-bold text-uppercase text-primary mb-4">
                      Supplier
                    </h6>
                    <div className="d-flex justify-content-center mb-0">
                      <span className="h4 mb-0 mt-2">NGN</span>
                      <span className="price h1 mb-0">0</span> 
                    </div>
                    <span className="h4 align-self-end mb-1">
                      /month
                    </span>
                    <ul className="list-unstyled mb-0 ps-0">
                      <li key={1} className="h6 text-muted mb-0">
                        <span className="text-primary h5 me-2">
                          <i className="uil uil-check-circle align-middle"></i>
                        </span>
                          5% processing fee
                      </li>
                      <li key={2} className="h6 text-muted mb-0">
                        <span className="text-primary h5 me-2">
                          <i className="uil uil-check-circle align-middle"></i>
                        </span>
                        Free online store
                      </li>
                      <li key={3} className="h6 text-muted mb-0">
                        <span className="text-primary h5 me-2">
                          <i className="uil uil-check-circle align-middle"></i>
                        </span>
                        List your product on SWHAG
                      </li>
                      <li key={4} className="h6 text-muted mb-0">
                        <span className="text-primary h5 me-2">
                          <i className="uil uil-check-circle align-middle"></i>
                        </span>
                        Generate more sales for your products
                      </li>
                      <li key={5} className="h6 text-muted mb-0">
                        <span className="text-primary h5 me-2">
                          <i className="uil uil-check-circle align-middle"></i>
                        </span>
                        In app payments
                      </li>
                      <li key={6} className="h6 text-muted mb-0">
                        <span className="text-primary h5 me-2">
                          <i className="uil uil-check-circle align-middle"></i>
                        </span>
                        Manage all your products
                      </li>
                    </ul>
                  </CardBody>
                </Card>
              </Col>
              {pricings.map((pricing, index) => (
                <Col md={4} xs={12} key={index} className="mt-4 pt-2">
                  <Card className="pricing-rates business-rate shadow bg-light rounded text-center border-0">
                    {pricing.isActive && (
                      <div className="ribbon ribbon-right ribbon-warning overflow-hidden">
                        <span className="text-center d-block shadow small h6">
                          Best
                        </span>
                      </div>
                    )}
                    <CardBody className="py-5">
                      <h6
                        className={
                          pricing.isActive
                            ? "title fw-bold text-uppercase text-primary mb-4"
                            : "title fw-bold text-uppercase text-primary mb-4"
                        }
                      >
                        {pricing.title} plan
                      </h6>
                      <div className="d-flex justify-content-center mb-4">
                        <span className="h4 mb-0 mt-2">NGN</span>
                        <span className="price h1 mb-0">{pricing.price}</span>
                        <span className="h4 align-self-end mb-1">
                          /{pricing.duration}
                        </span>
                      </div>

                      <ul className="list-unstyled mb-0 ps-0">
                        {pricing.features.map((feature, i) => (
                          <li key={i} className="h6 text-muted mb-0">
                            <span className="text-primary h5 me-2">
                              <i className="uil uil-check-circle align-middle"></i>
                            </span>
                            {feature.title}
                          </li>
                        ))}
                        <li key={pricing.features.length}>
                        <Link
                          target="_blank"
                          rel="noreferrer"
                          to="//shazzastore.com/"
                          className="btn btn-primary mt-4"
                        >
                          Preview example website
                        </Link>
                        </li>
                      </ul>
                      <Link
                        to="#"
                        onClick={() => changePlan(pricing.planCode, pricing.title, pricing.amount)}
                        className="btn btn-primary mt-4"
                      >
                        {pricing.buttonText}
                      </Link>
                    </CardBody>
                  </Card>
                </Col>
              ))}
            </Row>
          </Container>

          
        </section>

    );
}
export default Pricing;
