import React from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Input,
  CardBody,
  Label,
  Modal,
  ModalBody
} from "reactstrap";
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';

import fire from "../Fire.js";
import firebase from 'firebase';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const useStyles = makeStyles({
  root: {
  },
  media: {
    height: 500,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  media2: {
    height: 200,
    marginTop: 40,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
});


function useStore() {
  const [store, setStore] = React.useState({});
  const user = fire.auth().currentUser;

  React.useEffect(() => {
    const unsubscribe = fire
    .firestore()
    .collection("users")
    .doc(user.uid)
    .collection("stores")
    .onSnapshot(querySnapshot => {
      querySnapshot.forEach(doc => {
        fire
        .firestore()
        .collection("users")
        .doc(user.uid)
        .collection("stores")
        .doc(doc.id)
        .collection("pages")
        .doc("about")
        .onSnapshot(page => {
          setStore(Object.assign(doc.data(), {storeId: doc.id, about: page.data()}));
        });
      })
    });
    return () => unsubscribe;
  }, [user.uid]);
  
  return store;
};

const EditAbout = ({profile}) => {
  const classes = useStyles();
  const user = fire.auth().currentUser;
  const store = useStore();
  const [openAlert, setOpenAlert] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");


  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert(false);
  };

  
  const updateAboutPage = (e) => {
    e.preventDefault();
    const title = document.getElementById("title").value;
    const subtitle = document.getElementById("subtitle").value;
    const address = document.getElementById("address").value;
    const phoneNumber = document.getElementById("phoneNumber").value;
    const email = document.getElementById("email").value;
    const description = document.getElementById("description").value;
    const videoTitle = document.getElementById("videoTitle").value;
    const youTubeVideoId = document.getElementById("youTubeVideoId").value;

    fire
    .firestore()
    .collection("users")
    .doc(user.uid)
    .collection("stores")
    .doc(store.storeId)
    .collection("pages")
    .doc("about")
    .update({
      title: title,
      subtitle: subtitle,
      address: address,
      phoneNumber: phoneNumber,
      email: email,
      description: description,
      videoTitle: videoTitle,
      youTubeVideoId: youTubeVideoId,
    });
    fire
    .firestore()
    .collection("stores")
    .doc(store.storeId)
    .collection("pages")
    .doc("about")
    .update({
      title: title,
      subtitle: subtitle,
      address: address,
      phoneNumber: phoneNumber,
      email: email,
      description: description,
      videoTitle: videoTitle,
      youTubeVideoId: youTubeVideoId,
    });

    setAlertMessage("Changes saved");
    setOpenAlert(true);
  };

  const selectMainPic = (e) => {
    const image = e.target.files[0];
    const storeDocument = fire.firestore().collection("users").doc(user.uid).collection("stores").doc(store.storeId).collection("pages").doc("about");
    const publicStoreDocument = fire.firestore().collection("stores").doc(store.storeId).collection("pages").doc("about");
    const storageRef = fire.storage().ref('users/'+user.uid+'/stores/'+store.storeId+'/about');
    const fileRef = storageRef.child('mainImage');
    fileRef.put(image).then((e) => {
      fileRef.getDownloadURL().then((url) => {
        storeDocument
        .update({
          imageUrl: url,
        });
        publicStoreDocument
        .update({
          imageUrl: url,
        });
        setAlertMessage("Image uploading may take up to 1 minute");
        setOpenAlert(true);
      });
    });
  };

  const selectVideoBgPic = (e) => {
    const image = e.target.files[0];
    const storeDocument = fire.firestore().collection("users").doc(user.uid).collection("stores").doc(store.storeId).collection("pages").doc("about");
    const publicStoreDocument = fire.firestore().collection("stores").doc(store.storeId).collection("pages").doc("about");
    const storageRef = fire.storage().ref('users/'+user.uid+'/stores/'+store.storeId+'/about');
    const fileRef = storageRef.child('videoBgImage');
    fileRef.put(image).then((e) => {
      fileRef.getDownloadURL().then((url) => {
        storeDocument
        .update({
          videoBgImage: url,
        });
        publicStoreDocument
        .update({
          videoBgImage: url,
        });
        setAlertMessage("Image uploading may take up to 1 minute");
        setOpenAlert(true);
      });
    });
  };

  const handleChangeShowPage = (event) => {
    fire
    .firestore()
    .collection("users")
    .doc(user.uid)
    .collection("stores")
    .doc(store.storeId)
    .update({
      showAbout: event.target.checked,
    });
    fire
    .firestore()
    .collection("stores")
    .doc(store.storeId)
    .update({
      showAbout: event.target.checked,
    });
    fire
    .firestore()
    .collection("users")
    .doc(user.uid)
    .collection("stores")
    .doc(store.storeId)
    .collection("pages")
    .doc("about")
    .update({
      showPage: event.target.checked,
    });
    fire
    .firestore()
    .collection("stores")
    .doc(store.storeId)
    .collection("pages")
    .doc("about")
    .update({
      showPage: event.target.checked,
    });
  };

  const handleChangeShowAddress = (event) => {
    fire
    .firestore()
    .collection("users")
    .doc(user.uid)
    .collection("stores")
    .doc(store.storeId)
    .collection("pages")
    .doc("about")
    .update({
      showAddress: event.target.checked,
    });
    fire
    .firestore()
    .collection("stores")
    .doc(store.storeId)
    .collection("pages")
    .doc("about")
    .update({
      showAddress: event.target.checked,
    });
  };

  const handleChangeShowPhoneNumber = (event) => {
    fire
    .firestore()
    .collection("users")
    .doc(user.uid)
    .collection("stores")
    .doc(store.storeId)
    .collection("pages")
    .doc("about")
    .update({
      showPhoneNumber: event.target.checked,
    });
    fire
    .firestore()
    .collection("stores")
    .doc(store.storeId)
    .collection("pages")
    .doc("about")
    .update({
      showPhoneNumber: event.target.checked,
    });
  }

  const handleChangeShowEmail = (event) => {
    fire
    .firestore()
    .collection("users")
    .doc(user.uid)
    .collection("stores")
    .doc(store.storeId)
    .collection("pages")
    .doc("about")
    .update({
      showEmail: event.target.checked,
    });
    fire
    .firestore()
    .collection("stores")
    .doc(store.storeId)
    .collection("pages")
    .doc("about")
    .update({
      showEmail: event.target.checked,
    });
  }

  const handleChangeShowVideo = (event) => {
    fire
    .firestore()
    .collection("users")
    .doc(user.uid)
    .collection("stores")
    .doc(store.storeId)
    .collection("pages")
    .doc("about")
    .update({
      showVideo: event.target.checked,
    });
    fire
    .firestore()
    .collection("stores")
    .doc(store.storeId)
    .collection("pages")
    .doc("about")
    .update({
      showVideo: event.target.checked,
    });
  }

  return (
    <React.Fragment>

        <Container>
          <Row>
            <Col lg={4} xs={12}>
              <Card className="shadow rounded border-0">
                <CardBody className="py-5">
                  <h4 className="card-title">Edit About page</h4>
                  <div className="custom-form mt-3">
                    <div id="message"></div>
                    <Form
                      method="post"
                      onSubmit={updateAboutPage}
                      name="contact-form"
                      id="contact-form"
                    >
                      <Row>

                        <Col md={12}>
                          <div className="mb-3">
                            <Label className="form-label">
                              Show About page on your website?
                            </Label>
                            <Switch
                              checked={store? store.showAbout : false}
                              onChange={handleChangeShowPage}
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                          </div>
                        </Col>

                        <Col md={12} style={{borderStyle: 'solid', borderRadius: 20, paddingTop: 5, paddingBottom: 5, borderColor: '#2f55d4', marginTop: 10}}>
                          <InputLabel style={{display: 'flex', justifyContent: 'center',alignItems:'center', }}>
                            <Input type="file" onChange={selectMainPic} style={{display: 'none'}} />
                            <Typography color="primary">Change main Image</Typography>
                          </InputLabel>
                        </Col>

                        <Col md={12}>
                          <div className="mb-3">
                            <Label className="form-label">
                              Title
                            </Label>
                            <Input
                              id="title"
                              type="text"
                              className="form-control "
                              defaultValue={store.about? store.about.title : ""}
                              required
                            />
                          </div>
                        </Col>
                        <Col md={12}>
                          <div className="mb-3">
                            <Label className="form-label">
                              Subtitle
                            </Label>
                            <Input
                              id="subtitle"
                              type="text"
                              className="form-control "
                              defaultValue={store.about? store.about.subtitle : ""}
                              required
                            />
                          </div>
                        </Col>
                        <Col md={12}>
                          <div className="mb-3">
                            <Label className="form-label">
                              Description
                            </Label>
                            <textarea
                              id="description"
                              type="text"
                              className="form-control "
                              defaultValue={store.about? store.about.description : ""}
                              rows="5"
                              required
                            />
                          </div>
                        </Col>

                        <Col md={12}>
                          <div className="mb-3">
                            <Label className="form-label">
                              Show address?
                            </Label>
                            <Switch
                              checked={store.about ? store.about.showAddress : false}
                              onChange={handleChangeShowAddress}
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                          </div>
                        </Col>

                        {store.about && (
                          <Col md={12} style={{display: store.about.showAddress === true? "":"none"}}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Address
                              </Label>
                              <Input
                                id="address"
                                type="text"
                                className="form-control "
                                defaultValue={store.about? store.about.address : ""}
                                required
                              />
                            </div>
                          </Col>
                        )}

                        <Col md={12}>
                          <div className="mb-3">
                            <Label className="form-label">
                              Show phonenumber?
                            </Label>
                            <Switch
                              checked={store.about ? store.about.showPhoneNumber : false}
                              onChange={handleChangeShowPhoneNumber}
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                          </div>
                        </Col>

                        {store.about && (
                          <div style={{display: store.about.showPhoneNumber === true? "":"none"}}>
                          <Col md={12}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Phone number
                              </Label>
                              <Input
                                id="phoneNumber"
                                type="text"
                                className="form-control "
                                defaultValue={store.about? store.about.phoneNumber : ""}
                                required
                              />
                            </div>
                          </Col>
                          </div>
                        )}
                        <Col md={12}>
                          <div className="mb-3">
                            <Label className="form-label">
                              Show email?
                            </Label>
                            <Switch
                              checked={store.about ? store.about.showEmail : false}
                              onChange={handleChangeShowEmail}
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                          </div>
                        </Col>

                        {store.about && (
                          <div style={{display: store.about.showEmail === true? "":"none"}}>
                          <Col md={12}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Email
                              </Label>
                              <Input
                                id="email"
                                type="text"
                                className="form-control "
                                defaultValue={store.about? store.about.email : ""}
                                required
                              />
                            </div>
                          </Col>
                          </div>
                        )}

                        <Col md={12}>
                          <div className="mb-3">
                            <Label className="form-label">
                              Show video?
                            </Label>
                            <Switch
                              checked={store.about ? store.about.showVideo : false}
                              onChange={handleChangeShowVideo}
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                          </div>
                        </Col>

                        {store.about && (
                          <div style={{display: store.about.showVideo === true? "":"none"}}>
                          <Col md={12} style={{borderStyle: 'solid', borderRadius: 20, paddingTop: 5, paddingBottom: 5, borderColor: '#2f55d4', marginTop: 10}}>
                            <InputLabel style={{display: 'flex', justifyContent: 'center',alignItems:'center', }}>
                              <Input type="file" onChange={selectVideoBgPic}  style={{display: 'none'}} />
                              <Typography color="primary">Change video background Image</Typography>
                            </InputLabel>
                          </Col>
                          <Col md={12}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Video title
                              </Label>
                              <Input
                                id="videoTitle"
                                type="text"
                                className="form-control "
                                defaultValue={store.about? store.about.videoTitle : ""}
                                required
                              />
                            </div>
                          </Col>
                          <Col md={12}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Youtube video id
                              </Label>
                              <Input
                                id="youTubeVideoId"
                                type="text"
                                className="form-control "
                                defaultValue={store.about? store.about.youTubeVideoId : ""}
                                required
                              />
                            </div>
                          </Col>
                          </div>
                        )}

                      </Row>
                      <Row>
                        <Col sm={12} className="text-center">
                          <div className="d-grid">
                            <input
                              type="submit"
                              id="submit"
                              name="send"
                              className="submitBnt btn btn-primary"
                              value="Save changes"
                            />
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg={8} xs={12}>
            <Card className={classes.root}>
              <CardActionArea>
                
                {(store.about)&&(
                <CardContent >
                  <div class="about-content space-mb--r100">
                    <div class="wide container">
                      <div class="section-title-container text-center space-mb--r80">
                        <h2 class="section-title space-mb--20">{store.about.title}</h2>
                        <h4 class="section-title--secondary">{store.about.subtitle}</h4>
                      </div>
                      <div class="row">
                        <div class="col-xl-6 col-lg-6">
                          <div class="about-page-2-image space-mb-mobile-only--50">
                            <img src={store.about.imageUrl} class="img-fluid" alt=""/>
                          </div>
                        </div>
                        <div class="ml-auto col-xl-5 col-lg-6">
                          <div class="about-page-text space-mb--30 mt-0">
                            <p>{store.about.description}</p>
                          </div>
                          {(store.about.showAddress === true)&&(
                          <div class="about-widget space-mb--35">
                            <h5 class="widget-title space-mb--0">ADDRESS</h5>
                            <p class="widget-content">{store.about.address}</p>
                          </div>
                          )}
                          {(store.about.showPhoneNumber === true)&&(
                          <div class="about-widget space-mb--35">
                            <h5 class="widget-title space-mb--25">PHONE</h5>
                            <p class="widget-content">Mobile: {store.about.phoneNumber}</p>
                          </div>
                          )}
                          {(store.about.showEmail === true)&&(
                          <div class="about-widget">
                            <h5 class="widget-title space-mb--25">EMAIL</h5>
                            <p class="widget-content">{store.about.email}</p>
                          </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {(store.about.showVideo === true)&&(<div class="about-video-popup">
                    <div class="wide container">
                      <div class="row">
                        <div class="col-lg-12">
                          <div class="about-video-bg space-mb--60" style={{backgroundImage: `url(${store.about.videoBgImage})`, height: 300}}>
                            <div class="about-video-content__text-icon d-flex flex-column h-100 justify-content-center">
                              <div class="play-icon text-center space-mb--40">
                                <Button onClick={() => {window.open("https://www.youtube.com/watch?v="+store.about.youTubeVideoId)}}><PlayCircleIcon style={{fontSize: 70}}  /></Button>
                              </div>
                              <h1>{store.about.videoTitle}</h1>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>)}
                </CardContent>
                )}
              </CardActionArea>
            </Card>
            </Col>
          </Row>
          <Snackbar open={openAlert} autoHideDuration={10000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success">
              {alertMessage}
            </Alert>
          </Snackbar>
        </Container>
    </React.Fragment>
  );
};


export default EditAbout;
