import React from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Input,
  Card,
  CardBody,
  Button,
  Label,
} from "reactstrap";
import { Link } from "react-router-dom";
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import FeatherIcon from "feather-icons-react";
import InputLabel from '@material-ui/core/InputLabel';

import Typography from '@mui/material/Typography';

import Comingsoon from './Comingsoon';

import fire from "../Fire.js";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

function useProfile() {
  const [profile, setProfile] = React.useState({});
  const user = fire.auth().currentUser;

  React.useEffect(() => {
    const unsubscribe = fire
    .firestore()
    .collection("users")
    .doc(user.uid)
    .onSnapshot(document => {
      setProfile(document.data());
    });
    return () => unsubscribe;
  }, [user.uid]);
  
  return profile;
};

function useStore() {
  const [store, setStore] = React.useState({});

  const user = fire.auth().currentUser;

  React.useEffect(() => {
    const unsubscribe = fire
    .firestore()
    .collection("users")
    .doc(user.uid)
    .collection("stores")
    .onSnapshot(querySnapshot => {
      querySnapshot.forEach(doc => {
        setStore(Object.assign(doc.data(), {storeId: doc.id}));
      })
    });
    return () => unsubscribe;
  }, [user.uid]);
  
  return store;
};


const Settings = props => {
  const user = fire.auth().currentUser;
  const profile = useProfile();
  const store = useStore();
  const [isSnackbarOpen, setOpenSnackbar] = React.useState(false);
  const [isSnackbarSaveOpen, setOpenSnackbarSave] = React.useState(false);

  const updateProfile = (e) => {
    e.preventDefault();
    const firstName = document.getElementById("firstName").value;
    const lastName = document.getElementById("lastName").value;
    const phoneNumber = document.getElementById("phoneNumber").value;
    const businessName = document.getElementById("businessName").value;
    const businessDescription = document.getElementById("businessDescription").value;
    const websiteLink = document.getElementById("websiteLink").value;
    const instagram = document.getElementById("instagram").value;
    setOpenSnackbarSave(true);

    fire.firestore().collection("users").doc(user.uid).update({
      firstName: firstName,
      lastName: lastName,
      phoneNumber: phoneNumber,
      businessName: businessName,
      businessDescription: businessDescription,
      websiteLink: websiteLink,
      instagram: instagram,
    });
  };

  const selectPic = (e) => {
    const image = e.target.files[0];

    const profileDocument = fire.firestore().collection("users").doc(user.uid);
    const storeDocument = fire.firestore().collection("users").doc(user.uid).collection("stores").doc(store.storeId);
    const publicStoreDocument = fire.firestore().collection("stores").doc(store.storeId);
    const storageRef = fire.storage().ref('users/'+user.uid+'/');
    const fileRef = storageRef.child('profilePic');

    fileRef.put(image).then((e) => {
      fileRef.getDownloadURL().then((url) => {
        profileDocument
        .update({
          profilePicUrl: url,
        });
        storeDocument
        .update({
          profilePicUrl: url,
        });
        publicStoreDocument
        .update({
          profilePicUrl: url,
        });
      });
    });
  };

  const newMerchatRequest = (e) => {
    e.preventDefault();
    const businessName = document.getElementById("businessName").value;
    const businessDescription = document.getElementById("businessDescription").value;
    const staffSize = document.getElementById("staffSize").value;
    const websiteLink = document.getElementById("websiteLink").value;
    const instagram = document.getElementById("instagram").value;
    setOpenSnackbar(true);

    fire.firestore().collection("users").doc(user.uid).update({
      businessName: businessName,
      businessDescription: businessDescription,
      staffSize: staffSize,
      websiteLink: websiteLink,
      instagram: instagram,
    });
    const newMerchantRequest = fire.functions().httpsCallable('newMerchantRequest');
    newMerchantRequest({ businessName: businessName, businessDescription: businessDescription, email: user.email, staffSize: staffSize, websiteLink: websiteLink, instagram: instagram, }).then(res => {
      console.log(res.data);
    });
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbarSave(false);
    setOpenSnackbar(false);
  };

  return (
    <React.Fragment>
      <section  style={{width: "100%", flex: 1, height: "100%", minHeight: "100vh", backgroundColor: '#f2f8fb'}} className="section mt-60">
        <Container className="mt-lg-3">
          <Row className="justify-content-center">
            <Col xs={12}>
              <Card className="border-0 rounded shadow">
                <CardBody>
                  <h5 className="text-md-start text-center">
                    Personal Detail :
                  </h5>
                  <Row>
                  <Col md="3">
                  <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center',}} >
                    
                    <img src={profile.profilePicUrl} alt="logo" style={{width: 250, height: 250, borderRadius: 100, borderWidth: 10,
                    borderColor: '#2f55d4', border: 10}} />

                    <InputLabel >
                      <Input type="file" onChange={selectPic} style={{display: 'none'}} />
                      <Typography color="primary">Change logo</Typography>
                    </InputLabel>
                  </div>
                  </Col>
                  </Row>

                  <Form onSubmit={updateProfile}>
                    <Row className="mt-4">
                      <Col md="6">
                        <div className="mb-3">
                          <Label className="form-label">First Name</Label>
                          <div className="form-icon position-relative">
                            <i>
                              <FeatherIcon
                                icon="user"
                                className="fea icon-sm icons"
                              />
                            </i>
                          </div>
                          <Input
                            name="name"
                            id="firstName"
                            type="text"
                            className="form-control ps-5"
                            placeholder="First Name :"
                            defaultValue={profile.firstName}
                          />
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="mb-3">
                          <Label className="form-label">Last Name</Label>
                          <div className="form-icon position-relative">
                            <i>
                              <FeatherIcon
                                icon="user-check"
                                className="fea icon-sm icons"
                              />
                            </i>
                          </div>
                          <Input
                            name="name"
                            id="lastName"
                            type="text"
                            className="form-control ps-5"
                            placeholder="Last Name :"
                            defaultValue={profile.lastName}
                          />
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="mb-3">
                          <Label className="form-label">Your Email</Label>
                          <div className="form-icon position-relative">
                            <i>
                              <FeatherIcon
                                icon="mail"
                                className="fea icon-sm icons"
                              />
                            </i>
                          </div>
                          <Input
                            name="email"
                            id="email"
                            type="email"
                            className="form-control ps-5"
                            defaultValue={user.email}
                            disabled
                          />
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="mb-3">
                          <Label className="form-label">Phone No. :</Label>
                          <div className="form-icon position-relative">
                            <i>
                              <FeatherIcon
                                icon="phone"
                                className="fea icon-sm icons"
                              />
                            </i>
                          </div>
                          <Input
                            name="number"
                            id="phoneNumber"
                            type="phone-pad"
                            placeholder="+2348012345678"
                            className="form-control ps-5"
                            defaultValue={profile.phoneNumber}
                          />
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label className="form-label">Website link :</Label>
                          <div className="form-icon position-relative">
                            <i>
                              <FeatherIcon
                                icon="globe"
                                className="fea icon-sm icons"
                              />
                            </i>
                          </div>
                          <Input
                            name="website"
                            id="websiteLink"
                            type="text"
                            className="form-control ps-5"
                            defaultValue={profile.websiteLink}
                          />
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label className="form-label">Instagram handle :</Label>
                          <div className="form-icon position-relative">
                            <i>
                              <FeatherIcon
                                icon="user"
                                className="fea icon-sm icons"
                              />
                            </i>
                          </div>
                          <Input
                            id="instagram"
                            type="text"
                            className="form-control ps-5"
                            defaultValue={profile.instagram}
                          />
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label className="form-label">Business Name :</Label>
                          <div className="form-icon position-relative">
                            <i>
                              <FeatherIcon
                                icon="user"
                                className="fea icon-sm icons"
                              />
                            </i>
                          </div>
                          <Input
                            name="businessName"
                            id="businessName"
                            type="text"
                            className="form-control ps-5"
                            defaultValue={profile.businessName}
                          />
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label className="form-label">Business description :</Label>
                          <div className="form-icon position-relative">
                            <i>
                              <FeatherIcon
                                icon="user"
                                className="fea icon-sm icons"
                              />
                            </i>
                          </div>
                          <textarea
                            id="businessDescription"
                            type="text"
                            className="form-control ps-5"
                            defaultValue={profile.businessDescription}
                            rows={3}
                            required
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <input
                          type="submit"
                          id="submit"
                          name="send"
                          className="btn btn-primary"
                          value="Save Changes"
                        />
                      </Col>
                    </Row>
                  </Form>

                  <Row>
                    {(profile.store === true) && (
                    <Col md="12" className="mt-4 pt-2">
                      <h5>Store Info :</h5>

                        <Row className="mt-4">

                          <Col lg="6">
                            <div className="mb-3">
                              <Label className="form-label">Account Number :</Label>
                              <div className="form-icon position-relative">
                                <i>
                                  <FeatherIcon
                                    icon="dollar-sign"
                                    className="fea icon-sm icons"
                                  />
                                </i>
                              </div>
                              <Input
                                name="url"
                                id="url"
                                type="url"
                                className="form-control ps-5"
                                defaultValue={store.accountNumber}
                                disabled
                              />
                            </div>
                          </Col>
                          <Col lg="6">
                            <div className="mb-3">
                              <Label className="form-label">Store name :</Label>
                              <div className="form-icon position-relative">
                                <i>
                                  <FeatherIcon
                                    icon="book"
                                    className="fea icon-sm icons"
                                  />
                                </i>
                              </div>
                              <Input
                                id="plan"
                                type="text"
                                className="form-control ps-5"
                                defaultValue={store.storeName}
                                disabled
                              />
                            </div>
                          </Col>

                          {/* <Col lg="12" className="mt-2 mb-0">
                            <Link to="/Store">
                              <Button color="primary">Edit Store</Button>
                            </Link>
                          </Col> */}
                        </Row>
                    </Col>
                    )}
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Snackbar open={isSnackbarOpen} autoHideDuration={7000} onClose={handleClose}>
              <Alert onClose={handleClose} severity="success">
                Your request has been sent successfully! We will get in touch via email
              </Alert>
            </Snackbar>
            <Snackbar open={isSnackbarSaveOpen} autoHideDuration={6000} onClose={handleClose}>
              <Alert onClose={handleClose} severity="success">
                Your changes have been saved successfully!
              </Alert>
            </Snackbar>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};


export default Settings;
