import React from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
  Form,
} from "reactstrap";
import { useHistory } from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import { visuallyHidden } from '@mui/utils';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ButtonGroup from '@mui/material/ButtonGroup';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

import Comingsoon from './Comingsoon';

import {fetchAllOrders, fetchMoreOrders} from './getProducts';
import fire from "../Fire.js";
import fire2 from "../Fire2.js";
import { color } from '@mui/system';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function numberSeparator(numb) {
  var str = numb.toString().split(".");
  str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return str.join(".");
};

function useStore() {
  const [store, setStore] = React.useState({});
  const user = fire.auth().currentUser;

  React.useEffect(() => {
    const unsubscribe = fire
    .firestore()
    .collection("users")
    .doc(user.uid)
    .collection("stores")
    .onSnapshot(querySnapshot => {
      querySnapshot.forEach(doc => {
        setStore(Object.assign(doc.data(), {storeId: doc.id,}));
      })
    });
    return () => unsubscribe;
  }, [user.uid]);
  
  return store;
};

function useProfile() {
  const [profile, setProfile] = React.useState({});

  const user = fire.auth().currentUser;

  React.useEffect(() => {
    const unsubscribe = fire
    .firestore()
    .collection("users")
    .doc(user.uid)
    .onSnapshot(document => {
      setProfile(document.data());
    });
    return () => unsubscribe;
  }, [user.uid]);
  
  return profile;
};


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'firstName',
    numeric: false,
    disablePadding: false,
    label: 'User',
  },
  {
    id: 'storeName',
    numeric: false,
    disablePadding: false,
    label: 'Store Name',
  },
  {
    id: 'revenue',
    numeric: false,
    disablePadding: false,
    label: 'Revenue',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Status',
  },
  {
    id: 'dateCreated',
    numeric: false,
    disablePadding: false,
    label: 'Date',
  },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const Referrals = (props) => {
  const user = fire.auth().currentUser;
  const classes = useStyles();
  let history = useHistory();
  const store = useStore();
  const profile = useProfile();
  const [isModalOpen, setOpenModal] = React.useState(false);

  const [searchField, setSearchField] = React.useState("");
  const [myFilter, setMyFilter] = React.useState("All");

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('productName');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const [referrals, setReferrals] = React.useState([]);
  const [startAfter, setStartAfter] = React.useState();
  const [lastOrder, setLastOrder] = React.useState(false);
  const [start, setStart] = React.useState();
  const [end, setEnd] = React.useState();

  const [referralsSubscribed, setReferralsSubscribed] = React.useState(0);
  const [referralsLength, setReferralsLength] = React.useState(0);
  const [referralsRevenue, setReferralsRevenue] = React.useState(0);
  const periods = ["Today", "This week", "This month", "This quarter", "This year", "Last year"];
  const [period, setPeriod] = React.useState(null);

  React.useEffect(() => {
    const rightNow = new Date(Date.now());
    const todayDate = new Date().getDate();
    const formattedTodayDate = ("0" + todayDate).slice(-2);
    const thisMonth = ("0" + (new Date().getMonth() + 1)).slice(-2);
    const thisYear = new Date().getFullYear();
    const today = new Date(thisYear+"-"+thisMonth+"-"+formattedTodayDate);
    const startMonth = new Date(thisYear+"-"+thisMonth+"-01");
    const startYear = new Date(thisYear+"-01-01");
    const startAt = startMonth;
    const endAt = rightNow;

    var thirtyDaysAgo = today;
    thirtyDaysAgo.setDate(today.getDate() - 30);
    const unsubscribe = fire
      .firestore()
      .collection("users")
      .doc(user.uid)
      .collection("stores")
      .get()
      .then(stores => {
        stores.forEach(store =>{
          setReferrals([]);
          setStartAfter();
          setLastOrder(false);
          setStart(startAt);
          setEnd(endAt);
          getReferrals(store.id, startAt, endAt);
        });
      });

    return unsubscribe;
  }, []);

  async function getReferrals(storeId, startAt, endAt) {
    
    var totalReferrals = 0;
    var totalSubscribedReferrals = 0;
    var totalReferralsRevenue = 0;

    await fire
    .firestore()
    .collection("users")
    .doc(user.uid)
    .collection("stores")
    .doc(storeId)
    .collection("referrals")
    .where('dateCreated', '>', startAt)
    .where('dateCreated', '<', endAt)
    .orderBy('dateCreated')
    .get()
    .then((querySnapshot) => {
      setReferrals([])
      querySnapshot.forEach( (referral) => {
        totalReferrals += 1;
        if(referral.data().revenue) {
          totalSubscribedReferrals += 1
          totalReferralsRevenue += Number(referral.data().revenue);
        }
        setReferrals(current => [...current, referral.data()])
      });
    });

    setReferralsLength(totalReferrals);
    setReferralsSubscribed(totalSubscribedReferrals);
    setReferralsRevenue(totalReferralsRevenue);

  };

  const search = (products) => {
    const x = products.filter(
      (order) => 
      (
        (order.firstName.toLowerCase().indexOf(searchField) > -1 || order.lastName.toLowerCase().indexOf(searchField) > -1) &&
        ((order.revenue ? "subscribed": "unsubscribed") === myFilter || myFilter === "All")
      )
    );
    return x;
  };

  const sendReminder = () => {
    selected.forEach(async (referral) => {
      const sendRefferalReminder = fire.functions().httpsCallable('sendRefferalReminder');
      sendRefferalReminder({store: store, referralStoreName: referral.storeName, referralStoreId: referral.storeId, referralStoreEmail: referral.storeEmail, referralUserUid: referral.userUid,}).then(result => {
        console.log(result.data);
      });

      await fire.firestore().collection("stores").doc(referral.storeId).get().then(doc => {
        if (doc.data().pushToken) {
          sendPushNotification({expoPushToken: doc.data().pushToken, title: "Upgrade to SWHAG premium", body: "["+store.storeName + "] Hey "+referral.firstName+" don't forget to upgrade to SWHAG permium and get 10% cashback."});
        }
      })


    });
    getReferrals(store.storeId, start, end);
    setSelected([]);
  };

  const handleAlignment = (event, newAlignment) => {
    setMyFilter(newAlignment);
  };

  const openModal = (e) => {
    setOpenModal(true);
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  const changePeriod = (event) => {
    setPeriod(event.target.value);
    const selectedPeriod = event.target.value;

    const rightNow = new Date(Date.now());
    const todayDate = new Date().getDate();
    const formattedTodayDate = ("0" + todayDate).slice(-2);
    const thisMonth = ("0" + (new Date().getMonth() + 1)).slice(-2);
    const thisYear = new Date().getFullYear();
    const today = new Date(thisYear+"-"+thisMonth+"-"+formattedTodayDate);
    const startMonth = new Date(thisYear+"-"+thisMonth+"-01");
    const startYear = new Date(thisYear+"-01-01");
    const startLastYear = new Date((thisYear-1)+"-01-01");
    const endLastYear = new Date(thisYear+"-01-01");

    if (selectedPeriod === "Today"){
      const startAt = today;
      const endAt = rightNow;
      getReferrals(store.storeId, startAt, endAt );
    } else if (selectedPeriod === "This week") {
      const currentDay = new Date().getDay();
      var startAt = today;
      startAt.setDate(today.getDate() - currentDay);
      const endAt = rightNow;
      setStart(startAt);
      setEnd(endAt);
      getReferrals(store.storeId, startAt, endAt );
    } else if (selectedPeriod === "This month") {
      const startAt = startMonth;
      const endAt = rightNow;
      setStart(startAt);
      setEnd(endAt);
      getReferrals(store.storeId, startAt, endAt );
    } else if (selectedPeriod === "This quarter") {
      if (thisMonth === "01" || thisMonth === "02" || thisMonth === "03"){
        const startQuarter = new Date(thisYear+"-01-01");
        const startAt = startQuarter;
        const endAt = rightNow;
        setStart(startAt);
        setEnd(endAt);
        getReferrals(store.storeId, startAt, endAt );
      } else if (thisMonth === "04" || thisMonth === "05" || thisMonth === "06"){
        const startQuarter = new Date(thisYear+"-04-01");
        const startAt = startQuarter;
        const endAt = rightNow;
        setStart(startAt);
        setEnd(endAt);
        getReferrals(store.storeId, startAt, endAt );
      } else if (thisMonth === "07" || thisMonth === "08" || thisMonth === "09"){
        const startQuarter = new Date(thisYear+"-07-01");
        const startAt = startQuarter;
        const endAt = rightNow;
        setStart(startAt);
        setEnd(endAt);
        getReferrals(store.storeId, startAt, endAt );
      } else if (thisMonth === "10" || thisMonth === "11" || thisMonth === "12"){
        const startQuarter = new Date(thisYear+"-10-01");
        const startAt = startQuarter;
        const endAt = rightNow;
        setStart(startAt);
        setEnd(endAt);
        getReferrals(store.storeId, startAt, endAt );
      };
    } else if (selectedPeriod === "This year") {
      const startAt = startYear;
      const endAt = rightNow;
      setStart(startAt);
      setEnd(endAt);
      getReferrals(store.storeId, startAt, endAt );
    } else if (selectedPeriod === "Last year") {
      const startAt = startLastYear;
      const endAt = endLastYear;
      setStart(startAt);
      setEnd(endAt);
      getReferrals(store.storeId, startAt, endAt );
    }
  };

  const EnhancedTableToolbar = (props) => {
    const { numSelected } = props;
  
    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
          }),
        }}
      >
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: '1 1 100%' }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            sx={{ flex: '1 1 100%' }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Referrals
          </Typography>
        )}
  
        {numSelected > 0 ? (
          <ButtonGroup variant="outlined" aria-label="outlined button group">
            <Button onClick={sendReminder}  style={{color:'green'}}>Send reminder</Button>
          </ButtonGroup>
        ) : (
        <>
          <ButtonGroup variant="outlined" aria-label="outlined button group">
            <Button onClick={() => setOpenModal(true)}  style={{color:'green'}}>Learn more</Button>
          </ButtonGroup>
        </>
        )}
      </Toolbar>
    );
  };
  
  EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = search(referrals).map((n) => n);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - search(referrals).length) : 0;

  
  return (
    <React.Fragment  >
    <section className="section" style={{width: "100%", height: '100%', minHeight: "100vh", flex: 1, backgroundColor: '#f2f8fb',}}>
    <Container>
    <div>
      <div style={{ backgroundColor: '#10041c', width: '100%', borderRadius: 30}}>
      <Row direction="row" >
        <Col style={{padding: 20}}>
          <FormControl fullWidth style={{color: '#fff'}}>
            <InputLabel id="demo-simple-select-label" style={{color: '#fff'}}>
              {(period === null) && (
                <>This Month</>
              )}
              {(period !== null) && (
                <>Period</>
              )}
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={period}
              onChange={changePeriod}
              label="This Month"
              defaultValue='This Month'
              style={{color: '#fff'}}
            >
              {periods.map(eachPeriod => {
                return(
                  <MenuItem value={eachPeriod}>{eachPeriod}</MenuItem>
                )
              })}
          </Select>
          </FormControl>
        </Col>
        <Col style={{padding: 20,}}>
          <Typography style={{color: '#fff'}}>Referrals</Typography>
          <Typography style={{color: '#fff'}}>{referralsLength}</Typography>
        </Col>
        <Col style={{padding: 20,}}>
          <Typography style={{color: '#fff'}}>Subscribed Referrals</Typography>
          <Typography style={{color: '#fff'}}>{referralsSubscribed}</Typography>
        </Col>
        <Col style={{padding: 20,}}>
          <Typography style={{color: '#fff'}}>Referrals Revenue</Typography>
          <Typography style={{color: '#fff'}}>NGN {numberSeparator(referralsRevenue)}</Typography>
        </Col>
      </Row>
      </div>
      <Paper sx={{ width: '100%', mb: 2, mt: 2 }}>
        <ToggleButtonGroup
          value={myFilter}
          color="primary"
          exclusive
          onChange={handleAlignment}
          aria-label="text alignment"
        >
          <ToggleButton value="All" aria-label="All">
            <Typography>All Referrals</Typography>
          </ToggleButton>
          <ToggleButton value="subscribed" aria-label="All">
            <Typography>Subscribed Referrals</Typography>
          </ToggleButton>
          <ToggleButton value="unsubscribed" aria-label="All">
            <Typography>Unsubscribed Referrals</Typography>
          </ToggleButton>
        </ToggleButtonGroup>
        <Form
          role="search"
          method="get"
          id="searchform"
        >
          <div className="input-group mb-3 border rounded">
            <button type="submit" onClick={(e) => e.preventDefault()} className="input-group-text bg-white border-0" id="searchsubmit"><i className="uil uil-search"></i></button>
            <Input
              type="text"
              className="form-control border-0"
              name="s"
              id="search"
              placeholder="Search Referrals"
              onChange={(e) => setSearchField(e.target.value.toLowerCase())}
            />
          </div>
        </Form>
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={search(referrals).length}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                rows.slice().sort(getComparator(order, orderBy)) */}
              {stableSort(search(referrals), getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  let date;

                  var revenue = 0;
                  let status = (<TableCell align="right"><div style={{backgroundColor: "orange", padding: 10, borderRadius: '50%', textAlign: 'center'}}><Typography>Unsubscribed</Typography></div></TableCell>);;
                  if(row.revenue) {
                    revenue = row.revenue;
                    status = (<TableCell align="right"><div style={{backgroundColor: "green", padding: 10, borderRadius: '50%', textAlign: 'center'}}>Subscribed</div></TableCell>);
                  }
                  date = row.dateCreated.toDate().toDateString();

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.productId}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          onClick={(event) => handleClick(event, row)}
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell
                        id={labelId}
                        scope="row"
                        align="left"
                        component="th"
                      >
                        {row.firstName} {row.lastName}
                      </TableCell>
                      <TableCell align="left" >{row.storeName}</TableCell>
                      <TableCell align="left" >NGN {numberSeparator(revenue)}</TableCell>
                      {status}
                      <TableCell align="left" >{date}</TableCell>
                      
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={search(referrals).length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <Modal isOpen={isModalOpen} toggle={closeModal} centered size="lg">
      <img src={"https://firebasestorage.googleapis.com/v0/b/swhag-1a9e3.appspot.com/o/images%2Fwebsite%2F966d1b8d-5a2d-4b63-8da5-ddc620ccc104.JPG?alt=media&token=dcf9d2bd-ec86-4068-9f58-640b0beaf0b7"} className="img-fluid rounded" alt="" />
        <div onClick={closeModal} className="btn btn-soft-primary ms-2">
          Close
        </div>
      </Modal>
    </div>
    </Container>
    </section>
    </React.Fragment>
  );
}

export default Referrals;

async function sendPushNotification({expoPushToken, title, body}) {
  const message = {
    to: expoPushToken,
    sound: 'default',
    title: title,
    body: body,
    data: { someData: 'goes here' },
  };

  await fetch('https://exp.host/--/api/v2/push/send', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Accept-encoding': 'gzip, deflate',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(message),
  });
};