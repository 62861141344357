import React from 'react';
import {
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
  Form,
  CardBody,
} from "reactstrap";
import { useHistory } from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
//import Divider from '@mui/material/Divider';
import { visuallyHidden } from '@mui/utils';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ButtonGroup from '@mui/material/ButtonGroup';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import CardMedia from '@mui/material/CardMedia';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { ConfirmProvider } from 'material-ui-confirm';
import { useConfirm } from 'material-ui-confirm';
import { Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';

//import axios from "axios";
import fire from "../Fire.js";
//import fire2 from "../Fire2.js";
import firebase from 'firebase';
import { PaystackButton } from 'react-paystack';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function numberSeparator(numb) {
  var str = numb.toString().split(".");
  str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return str.join(".");
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};


function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

function useStore() {
  const [store, setStore] = React.useState({});
  const user = fire.auth().currentUser;

  React.useEffect(() => {
    const unsubscribe = fire
    .firestore()
    .collection("users")
    .doc(user.uid)
    .collection("stores")
    .onSnapshot(querySnapshot => {
      querySnapshot.forEach(doc => {
        setStore(Object.assign(doc.data(), {storeId: doc.id,}));
      })
    });
    return () => unsubscribe;
  }, [user.uid]);
  
  return store;
};

function useProfile() {
  const [profile, setProfile] = React.useState({});

  const user = fire.auth().currentUser;

  React.useEffect(() => {
    const unsubscribe = fire
    .firestore()
    .collection("users")
    .doc(user.uid)
    .onSnapshot(document => {
      setProfile(document.data());
    });
    return () => unsubscribe;
  }, [user.uid]);
  
  return profile;
};


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Campaign',
  },
  {
    id: 'budget',
    numeric: false,
    disablePadding: false,
    label: 'Budget',
  },
  {
    id: 'impressions',
    numeric: false,
    disablePadding: false,
    label: 'Impressions',
  },
  {
    id: 'reach',
    numeric: false,
    disablePadding: false,
    label: 'Reach',
  },
  {
    id: 'amountSpent',
    numeric: false,
    disablePadding: false,
    label: 'Amount spent',
  },
  {
    id: 'timestamp',
    numeric: false,
    disablePadding: false,
    label: 'Date',
  },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const headCellsPayments = [
  {
    id: 'id',
    numeric: false,
    disablePadding: false,
    label: 'Transaction ID',
  },
  {
    id: 'timestamp',
    numeric: false,
    disablePadding: false,
    label: 'Date',
  },
  {
    id: 'amount',
    numeric: false,
    disablePadding: false,
    label: 'Amount',
  },
  {
    id: 'authorization',
    numeric: false,
    disablePadding: false,
    label: 'Payment Method',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Payment status',
  },
];

function EnhancedTableHeadPayments(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCellsPayments.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHeadPayments.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const headCellsLeads = [
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: 'Lead',
  },
  {
    id: 'cost',
    numeric: false,
    disablePadding: false,
    label: 'Lead cost',
  },
  {
    id: 'timestamp',
    numeric: false,
    disablePadding: false,
    label: 'Date',
  }
];

function EnhancedTableHeadLeads(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell> */}
        {headCellsLeads.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHeadLeads.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const MyCampaigns = (props) => {
  const user = fire.auth().currentUser;
  const classes = useStyles();
  let history = useHistory();
  const store = useStore();
  const confirm = useConfirm();
  const profile = useProfile();
  const [singleCampaign, setSingleCampaign] = React.useState({});
  const [isModalOpen, setOpenModal] = React.useState(false);
  const [isModalLeadOpen, setOpenModalLead] = React.useState(false);
  const [isNewCampaignOpen, setOpenNewCampaign] = React.useState(false);
  const [timestamp, setTimestamp] = React.useState("");

  const [searchField, setSearchField] = React.useState("");
  const [searchFieldLeads, setSearchFieldLeads] = React.useState("");
  const [myFilter, setMyFilter] = React.useState("All");
  const [myFilterLeads, setMyFilterLeads] = React.useState("questions");

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('productName');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const [campaigns, setCampaigns] = React.useState([]);
  const [startAfter, setStartAfter] = React.useState();
  const [ordersPerLoad] = React.useState(20);
  const [lastCampaign, setLastCampaign] = React.useState(false);
  const [start, setStart] = React.useState();
  const [end, setEnd] = React.useState();

  const [payments, setPayments] = React.useState([]);

  const [totalReach, setTotalReach] = React.useState(0);
  const [amountSpent, setAmountSpent] = React.useState(0);
  const [outstandingBalance, setOutstandingBalance] = React.useState(0);
  const [billingThreshold, setBillingThreshold] = React.useState(0);
  const [totalImpressions, setTotalImpressions] = React.useState(0);
  const periods = ["Today", "This week", "This month", "This quarter", "This year", "Last year"];
  const [period, setPeriod] = React.useState(null);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");
  const [isSnackbarOpen, setOpenSnackbar] = React.useState(false);
  const [campaignObjective, setCampaignObjective] = React.useState("traffic");
  const [conversionEvent, setConversionEvent] = React.useState("purchase");
  const [ctaButtonText, setCtaButtonText] = React.useState("Learn more");
  const [options, setOptions] = React.useState([]);
  const [targetProducts, setTargetProducts] = React.useState([]);
  const [campaignImages, setCampaignImages] = React.useState(null);

  const [leads, setLeads] = React.useState([]);
  const [singleLead, setSingleLead] = React.useState({});
  const [isOpenNewQuestion, setOpenNewQuestion] = React.useState(false);
  const [isMultipleChoiceQuestion, setMultipleChoiceQuestion] = React.useState(false);
  const [questionOptions, setLeadQuestionOptions] = React.useState([]);
  const [leadQuestions, setLeadQuestions] = React.useState([]);
  const [currentQuestion, setCurrentQuestion] = React.useState({});

  const publicKey = "pk_live_9296b30945eba87a279e3d7ca33cefb6fc2fa261";
  const [totalPayouts, setTotalPayouts] = React.useState(0);
  const [payoutSubaccounts, setPayoutSubaccounts] = React.useState([]);
  const [allWallets, setAllWallets] = React.useState([
  ]);

  let addConversionEvent;
  let addTargetProducts;
  let addCtaButtonText;


  React.useEffect(() => {
    const rightNow = new Date(Date.now());
    const todayDate = new Date().getDate();
    const formattedTodayDate = ("0" + todayDate).slice(-2);
    const thisMonth = ("0" + (new Date().getMonth() + 1)).slice(-2);
    const thisYear = new Date().getFullYear();
    const today = new Date(thisYear+"-"+thisMonth+"-"+formattedTodayDate);
    const startMonth = new Date(thisYear+"-"+thisMonth+"-01");
    const startYear = new Date(thisYear+"-01-01");
    const startAt = startYear;
    const endAt = rightNow;

    var thirtyDaysAgo = today;
    thirtyDaysAgo.setDate(today.getDate() - 30);
    const unsubscribe = fire
      .firestore()
      .collection("users")
      .doc(user.uid)
      .collection("stores")
      .get()
      .then(stores => {
        stores.forEach(store =>{
          setCampaigns([]);
          setAmountSpent(0);
          setTotalReach(0);
          setTotalImpressions(0);
          setStartAfter();
          setLastCampaign(false);
          setStart(startAt);
          setEnd(endAt);
          getCampaigns(store.id, startAt, endAt);
          getPayments(store.id, startAt, endAt);
        });
      });

    return unsubscribe;
  }, []);


  async function getCampaigns(storeId, startAt, endAt) {
    setCampaigns([]);
    setAmountSpent(0);
    setTotalReach(0);
    setTotalImpressions(0);
    var AmountSpent = 0;
    var TotalImpressions = 0;
    var TotalReach = 0;
    
    await fire
    .firestore()
    .collection("users")
    .doc(user.uid)
    .collection("stores")
    .doc(storeId)
    .collection("campaigns")
    .where('timestamp', '>', startAt)
    .where('timestamp', '<', endAt)
    .orderBy('timestamp', 'desc')
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach(async (campaign) => {
        var impressionsArray = [];
        var allIPs = [];
        await fire
        .firestore()
        .collection("users")
        .doc(user.uid)
        .collection("stores")
        .doc(storeId)
        .collection("campaigns")
        .doc(campaign.id)
        .collection("impressions")
        .where('timestamp', '>', startAt)
        .where('timestamp', '<', endAt)
        .orderBy('timestamp', 'desc')
        .get()
        .then(impressions => {
          var campaignSpend = 0;
          impressions.forEach(impression => {
            if (!(impression.data().isReferrer === true)) {
              impressionsArray.push(impression.data());
              if (!allIPs.includes(impression.data().ip) && (impression.data().ip !== "") && (impression.data().ip !== undefined)){
                allIPs.push(impression.data().ip)
              }
              campaignSpend += Number(impression.data().cost);
            }
          });
          if (storeId === campaign.data().storeId) {
            setCampaigns(currentCampaigns => [...currentCampaigns, Object.assign(campaign.data(), { impressions: impressionsArray, campaignId: campaign.id})]);
            setTotalImpressions(TotalImpressions+impressionsArray.length);
            setTotalReach(TotalReach + allIPs.length);
            setAmountSpent(AmountSpent + campaignSpend);
            AmountSpent = AmountSpent + campaignSpend;
            TotalReach = TotalReach + allIPs.length;
            TotalImpressions = TotalImpressions + impressionsArray.length;
          }
        });
      });
    });

    await fire
    .firestore()
    .collection("users")
    .doc(user.uid)
    .collection("stores")
    .doc(storeId)
    .collection("campaigns")
    .doc("--stats--")
    .get()
    .then((stats) => {
      setOutstandingBalance(stats.data().outstandingBalance);
      setBillingThreshold(stats.data().billingThreshold);
    });


  };

  async function getPayments(storeId, startAt, endAt) {
    
    await fire
    .firestore()
    .collection("users")
    .doc(user.uid)
    .collection("stores")
    .doc(storeId)
    .collection("payments")
    .where('timestamp', '>', startAt)
    .where('timestamp', '<', endAt)
    .orderBy('timestamp', 'desc')
    .get()
    .then((querySnapshot) => {
      setPayments([]);
      querySnapshot.forEach(async (payment) => {
        setPayments(currentPayments => [...currentPayments, Object.assign(payment.data(), {  id: payment.id, paymentId: payment.id})]);
      });
    });
  };

  // async function getMoreCampaigns(startAt, endAt) {
  //   if (!lastCampaign) {
  //     const campaignsData = await fetchMoreOrders({ordersPerLoad, startAfter, storeId: store.storeId, userUid: user.uid, startAt: startAt, endAt: endAt});
  //     setCampaigns([...campaigns, ...CampaignsData.campaigns]);
  //     setStartAfter(campaignsData.lastVisible);
  //     campaignsData.Campaigns.length == 0 ? setLastCampaign(true) : setLastCampaign(false); 
  //   };
  // };



  const search = (campaigns) => {
    const x = campaigns.filter(
      (campaign) => 
      (
        (campaign.name.toLowerCase().indexOf(searchField) > -1 ) &&
        (campaign.status === myFilter || (campaign.objective? (campaign.objective === myFilter) : "traffic" === myFilter )|| myFilter === "All") &&
        (campaign.orderId !== "--stats--") 
      )
    );
    return x;
  };

  const componentProps = {
    email: user.email,
    publicKey,
    amount: outstandingBalance*100,
    onSuccess: () => {
      fire.firestore().collection("users").doc(user.uid).collection("stores").doc(store.storeId).collection("payments").doc().set({
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        status: "success",
        amount: Number(outstandingBalance),
        authorization: null,
      });
      fire.firestore().collection("users").doc(user.uid).collection("stores").doc(store.storeId).collection("campaigns").doc("--stats--").update({
        outstandingBalance: firebase.firestore.FieldValue.increment(-Number(outstandingBalance)),
      });
      getCampaigns(store.storeId, start, end);
      getPayments(store.storeId, start, end);
      
    },
    onClose: () => {
    },
  };

  const componentProps2 = {
    email: user.email,
    publicKey,
    amount: 10000,
    onSuccess: () => {
      fire.firestore().collection("users").doc(user.uid).collection("stores").doc(store.storeId).collection("payments").doc().set({
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        status: "success",
        amount: Number(100),
        authorization: null,
      });
      fire.firestore().collection("users").doc(user.uid).collection("stores").doc(store.storeId).collection("campaigns").doc("--stats--").update({
        outstandingBalance: firebase.firestore.FieldValue.increment(-Number(100)),
      });
      setSnackbarSeverity("success");
      setSnackbarMessage("Card linked");
      setOpenSnackbar(true);
      getPayments(store.storeId, start, end);
      
    },
    onClose: () => {
    },
  };

  const componentProps3 = {
    email: user.email,
    publicKey,
    amount: (totalPayouts*100) + 15000,
    split: {
      type: "flat",
      bearer_type: "account",
      subaccounts: payoutSubaccounts,
    },
    onSuccess: () => {
      setSnackbarSeverity("success");
      setSnackbarMessage("Payout succesful");
      setOpenSnackbar(true);
      
    },
    onClose: () => {
    },
  };

  const getWallets = async () => {
    console.log("getting wallets")
    //setAllWallets([]);
    setPayoutSubaccounts([]);
    setTotalPayouts(0);
    // const res = await axios.get("https://swhag-api-h3huhza24q-uc.a.run.app/wallets");

    //console.log(allWallets);
    // console.log(res.data);
    var subaccounts = [];
    var total = 0;
    allWallets.forEach((wallet)=> {
      subaccounts.push({share: Number(wallet.walletBalance) * 98.5, subaccount: wallet.subaccount});
      total += Number(wallet.walletBalance);
    });
    //setAllWallets(res.data.allWallets);
    //console.log(subaccounts);
    setPayoutSubaccounts(subaccounts);
    setTotalPayouts(total);


  }


  const handleAlignment = (event, newAlignment) => {
    setMyFilter(newAlignment);
  };
  const handleAlignmentLeads = (event, newAlignment) => {
    setMyFilterLeads(newAlignment);
  };

  const openModal = (e) => {
    setOpenModal(true);
    setSingleCampaign(e);
    setTimestamp(e.timestamp.toDate().toDateString());
    if (e.imagesUrl) {
      setCampaignImages(e.imagesUrl);
    }

    if (e.ctaButtonText) {
      setCtaButtonText(e.ctaButtonText);
    }
    if (e.objective === "conversion") {
      setTargetProducts(e.targetProducts);
      fire
      .firestore()
      .collection("users")
      .doc(user.uid)
      .collection("stores")
      .doc(store.storeId)
      .collection("products")
      .onSnapshot(queryDocumentSnapshot => {
        setOptions([]);
        queryDocumentSnapshot.forEach(eachDocument => {
          const doc = eachDocument.data();
          setOptions(currentProducts => [...currentProducts, Object.assign(doc, {storeName: store.storeName, storeId: store.storeId, docId: eachDocument.id,})]);
        });
      });
    }

    if (e.objective === "leads") {
      getLeadQuestions(e);
      getLeads(e);
      setMyFilterLeads("questions");
    }
  };

  const openModalLead = (e) => {
    if (outstandingBalance > billingThreshold) {
      setSnackbarSeverity("error");
      setSnackbarMessage("Please pay our outstanding balance to view your leads");
      setOpenSnackbar(true);
    } else {
      setSingleLead(e);
      setTimestamp(e.timestamp.toDate().toDateString());
      setOpenModalLead(true);
    }


  };

  const closeModal = () => {
    setOpenModal(false);
    setOpenNewCampaign(false);
    setOpenModalLead(false);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };

  const openNewCampaign = () => {
    setOpenNewCampaign(true);
  };

  const changeCampaignObjective = () => {
    const objective = document.getElementById("campaignObjective").value;
    setCampaignObjective(objective);
    if (objective === "conversion") {
      fire
      .firestore()
      .collection("users")
      .doc(user.uid)
      .collection("stores")
      .doc(store.storeId)
      .collection("products")
      .onSnapshot(queryDocumentSnapshot => {
        setOptions([]);
        queryDocumentSnapshot.forEach(eachDocument => {
          const doc = eachDocument.data();
          setOptions(currentProducts => [...currentProducts, Object.assign(doc, {storeName: store.storeName, storeId: store.storeId, docId: eachDocument.id,})]);
        });
      });
    }
  };

  const changeConversionEvent = () => {
    const event = document.getElementById("conversionEvent").value;
    setConversionEvent(event);
  };

  if(campaignObjective === "conversion"){
    addConversionEvent = (
      <Col md={6}>
        <div className="mb-3">
          <Label className="form-label">
            Conversion event <span className="text-danger">*</span>
          </Label>
          <select className="btn btn-icon form-control ps-5 quantity" id="conversionEvent" onChange={changeConversionEvent} style={{width: "100%"}}>
            <option key={0} value={"purchase"}>Purchase</option>
            <option key={1} value={"add to cart"}>Add to cart</option>
          </select>
        </div>
      </Col>
    );
    addTargetProducts = (
      <Col md={6}>
        <div className="mb-3">
          <Label className="form-label">
            Add products this campaign targets <span className="text-danger">*</span>
          </Label>
          <Autocomplete
            multiple
            id="targetProducts"
            options={options}
            getOptionLabel={(option) => option.name}
            onChange={(event, value) => setTargetProducts(value)}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Select"
              />
            )}
          />
        </div>
      </Col>
    );
  } else {
    addConversionEvent = (
      <div></div>
    );
    addTargetProducts = (
      <div></div>
    );
  };
  if(campaignObjective === "leads"){
    addCtaButtonText = (
      <Col md={6}>
        <div className="mb-3">
          <Label className="form-label">
            Call to action text <span className="text-danger">*</span>
          </Label>
          <Input
            name="ctaButtonText"
            id="ctaButtonText"
            type="text"
            className="form-control ps-5"
            placeholder="Call to action:"
            required
            value={ctaButtonText}
            onChange={(e)=> setCtaButtonText(e.value)}
          />
        </div>
      </Col>
    );
  } else {
    addCtaButtonText = (
      <div></div>
    );
  };

  const publishCampaigns = () => {
    confirm({ description: "Are you ready to publish? Once published your campaign cannot be paused until your budget has been completely spent and your budget can't be reduced.", confirmationText: "Yes publish" })
      .then(() => { 
        if (store.authorization) {
          selected.forEach(campaign => {
            fire.firestore().collection("campaigns").doc(campaign.campaignId).set(Object.assign(campaign, {status: "published", impressions: []}));
            fire.firestore().collection("users").doc(user.uid).collection("stores").doc(store.storeId).collection("campaigns").doc(campaign.campaignId).update({status: "published"});
          });
          setSelected([]);
          setSnackbarSeverity("success");
          setSnackbarMessage("Campaign published");
          setOpenSnackbar(true);
        } else {
          setSnackbarSeverity("error");
          setSnackbarMessage("Please link a card before publishing");
          setOpenSnackbar(true);
        }
      });
  };

  const createCampaign = (e) => {
    e.preventDefault();
    const name = document.getElementById("name").value;
    const budget = document.getElementById("budget").value;
    const description = document.getElementById("description").value;
    const hashTags = document.getElementById("hashTags").value;
    const websiteUrl = document.getElementById("websiteUrl").value;
    const costPerEvent = document.getElementById("costPerEvent").value;
    let hashTagsArray = [];
    if(hashTags){
      hashTagsArray = hashTags.split(","); 
    }

    if (Number(budget) >= 10000){
      if ((campaignObjective === "conversion") && (!websiteUrl.includes(store.custom_domain))) {
        setSnackbarSeverity("error");
        setSnackbarMessage("You need to add your website which is hosted by SWHAG for conversion events");
        setOpenSnackbar(true);
      } else {
        const campaignRef = fire.firestore().collection("users").doc(user.uid).collection("stores").doc(store.storeId).collection("campaigns").doc();
        campaignRef.set({name: name, budget: Number(budget), amountSpent: Number(0), costPerEvent: Number(costPerEvent), description: description, websiteUrl: websiteUrl, hashTags: hashTagsArray, userUid: user.uid, storeId: store.storeId, status: "drafts", adFormat: "image", objective: campaignObjective, conversionEvent: conversionEvent, targetProducts: targetProducts, ctaButtonText: ctaButtonText, timestamp: firebase.firestore.FieldValue.serverTimestamp(), imagesUrl: [], videosUrl: []})
        setOpenNewCampaign(false);
        setSnackbarSeverity("success");
        setSnackbarMessage("Campaign created");
        setOpenSnackbar(true);
      }
    } else {
      setSnackbarSeverity("error");
      setSnackbarMessage("Minimum budget is NGN 10,000");
      setOpenSnackbar(true);
    }

    // if (campaigns.length === 0) {
    //   fire
    //   .firestore()
    //   .collection("users")
    //   .doc(user.uid)
    //   .collection("stores")
    //   .doc(store.storeId)
    //   .collection("campaigns")
    //   .doc("--stats--")
    //   .get()
    //   .then((stats) => {
    //     if(!stats.exists) {
    //       fire
    //       .firestore()
    //       .collection("users")
    //       .doc(user.uid)
    //       .collection("stores")
    //       .doc(store.storeId)
    //       .collection("campaigns")
    //       .doc("--stats--")
    //       .set({
    //         walletBalance: 0,
    //         outstandingBalance: -3000,
    //         billingThreshold: 3000,
    //         minWithdrawal: 1000,
    //       });
    //       setSnackbarSeverity("success");
    //       setSnackbarMessage("Congratulations!! We have added NGN 3,000 in ad credit to your account");
    //       setOpenSnackbar(true);
    //     }
    //   });
    // }
    getCampaigns(store.storeId, start, end);
  };

  const updateCampaign = (camp) => {
    const name = document.getElementById("name").value;
    const budget = document.getElementById("budget").value;
    const description = document.getElementById("description").value;
    const hashTags = document.getElementById("hashTags").value;
    const websiteUrl = document.getElementById("websiteUrl").value;
    const adFormat = document.getElementById("adFormat").value;
    const costPerEvent = document.getElementById("costPerEvent").value;
    let hashTagsArray = [];
    if(hashTags){
      hashTagsArray = hashTags.split(","); 
    }

    if (camp.status === "drafts") {
      if ((camp.objective === "conversion") && (!websiteUrl.includes(store.custom_domain))) {
        setSnackbarSeverity("error");
        setSnackbarMessage("You need to add your website which is hosted by SWHAG for conversion events");
        setOpenSnackbar(true);
      } else {
        const campaignRef = fire.firestore().collection("users").doc(user.uid).collection("stores").doc(store.storeId).collection("campaigns").doc(camp.campaignId);
        campaignRef.update({name: name, budget: Number(budget), costPerEvent: Number(costPerEvent), description: description, websiteUrl: websiteUrl, hashTags: hashTagsArray, adFormat: adFormat, conversionEvent: conversionEvent, targetProducts: targetProducts});
        if ((camp.objective === "leads")) {
          const ctaButton = document.getElementById("ctaButtonText").value;
          campaignRef.update({ctaButtonText: ctaButton});
        }
        setSnackbarSeverity("success");
        setSnackbarMessage("Campaign updated");
        setOpenSnackbar(true);
        setOpenModal(false);
        getCampaigns(store.storeId, start, end);
      } 
    }

    if (camp.status === "published") {
      if (Number(camp.budget) <= Number(budget)) {
        if ((camp.objective === "conversion") && (!websiteUrl.includes(store.custom_domain))) {
          setSnackbarSeverity("error");
          setSnackbarMessage("You need to add your website which is hosted by SWHAG for conversion events");
          setOpenSnackbar(true);
        } else {
          const campaignRef = fire.firestore().collection("users").doc(user.uid).collection("stores").doc(store.storeId).collection("campaigns").doc(camp.campaignId);
          campaignRef.update({name: name, budget: Number(budget), costPerEvent: Number(costPerEvent), description: description, websiteUrl: websiteUrl, hashTags: hashTagsArray, adFormat: adFormat, conversionEvent: conversionEvent, targetProducts: targetProducts});

          fire.firestore().collection("campaigns").doc(camp.campaignId).update({name: name, budget: Number(budget), description: description, websiteUrl: websiteUrl, hashTags: hashTagsArray, adFormat: adFormat, conversionEvent: conversionEvent, targetProducts: targetProducts});
          if ((camp.objective === "leads")) {
            const ctaButton = document.getElementById("ctaButtonText").value;
            campaignRef.update({ctaButtonText: ctaButton});
            fire.firestore().collection("campaigns").doc(camp.campaignId).update({ctaButtonText: ctaButton});
          }
          setSnackbarSeverity("success");
          setSnackbarMessage("Campaign updated");
          setOpenSnackbar(true);
          setOpenModal(false);
          getCampaigns(store.storeId, start, end);
        }
      } else {
        setSnackbarSeverity("error");
        setSnackbarMessage("You can't decrease the budget of a published campaign");
        setOpenSnackbar(true);
      }
    }

  };

  const selectPic = (e) => {
    const image = e.target.files[0];
    setCampaignImages(current => [...current, URL.createObjectURL(image)]);
    const campaignDocument = fire.firestore().collection("users").doc(user.uid).collection("stores").doc(store.storeId).collection("campaigns").doc(singleCampaign.campaignId);
    const storageRef = fire.storage().ref('users/'+user.uid+'/stores/'+store.storeId+'/campaigns/'+singleCampaign.campaignId);
    const fileRef = storageRef.child('campaignImage'+singleCampaign.imagesUrl.length);
    fileRef.put(image).then((e) => {
      fileRef.getDownloadURL().then((url) => {
        campaignDocument
        .update({
          imagesUrl: firebase.firestore.FieldValue.arrayUnion(url),
        });
        if (singleCampaign.status === "published") {
          fire.firestore().collection("campaigns").doc(singleCampaign.campaignId).update({
            imagesUrl: firebase.firestore.FieldValue.arrayUnion(url),
          });
        }
      });
    });
    // setOpenModal(false);
    getCampaigns(store.storeId, start, end);
  };

  const selectVideo = (e) => {
    const image = e.target.files[0];
    const campaignDocument = fire.firestore().collection("users").doc(user.uid).collection("stores").doc(store.storeId).collection("campaigns").doc(singleCampaign.campaignId);
    const storageRef = fire.storage().ref('users/'+user.uid+'/stores/'+store.storeId+'/campaigns/'+singleCampaign.campaignId);
    const fileRef = storageRef.child('campaignVideo'+0);
    fileRef.put(image).then((e) => {
      fileRef.getDownloadURL().then((url) => {
        campaignDocument
        .update({
          videosUrl: [url],
        });
        if (singleCampaign.status === "published") {
          fire.firestore().collection("campaigns").doc(singleCampaign.campaignId).update({
            videosUrl: [url],
          });
        }
      });
    });
    setOpenModal(false);
    getCampaigns(store.storeId, start, end);
  };

  const clearImages = () => {
    confirm({ description: 'Are you sure you want to remove all images', confirmationText: 'Yes' })
      .then(() => { 
        const campaignDocument = fire.firestore().collection("users").doc(user.uid).collection("stores").doc(store.storeId).collection("campaigns").doc(singleCampaign.campaignId);
        const storageRef = fire.storage().ref('users/'+user.uid+'/stores/'+store.storeId+'/campaigns/'+singleCampaign.campaignId);
        let count = 0;
        singleCampaign.imagesUrl.forEach(item => {
          var imageRef = storageRef.child('campaignImage'+count);
          // Delete the file
          imageRef.delete().then(() => {
            // File deleted successfully
          }).catch((error) => {
            // Uh-oh, an error occurred!
          });
          count +=1;
        });
        campaignDocument
        .update({
          imagesUrl: [],
        });
        if (singleCampaign.status === "published") {
          fire.firestore().collection("campaigns").doc(singleCampaign.campaignId).update({
            imagesUrl: [],
          });
        }
        setOpenModal(false);
        getCampaigns(store.storeId, start, end);
      });
  };

  const clearVideos = () => {
    confirm({ description: 'Are you sure you want to remove all videos', confirmationText: 'Yes' })
      .then(() => { 
        const campaignDocument = fire.firestore().collection("users").doc(user.uid).collection("stores").doc(store.storeId).collection("campaigns").doc(singleCampaign.campaignId);
        const storageRef = fire.storage().ref('users/'+user.uid+'/stores/'+store.storeId+'/campaigns/'+singleCampaign.campaignId);
        let count = 0;
        singleCampaign.imagesUrl.forEach(item => {
          var imageRef = storageRef.child('campaignVideo'+count);
          // Delete the file
          imageRef.delete().then(() => {
            // File deleted successfully
          }).catch((error) => {
            // Uh-oh, an error occurred!
          });
          count +=1;
        });
        campaignDocument
        .update({
          campaignVideo: [],
        });
        if (singleCampaign.status === "published") {
          fire.firestore().collection("campaigns").doc(singleCampaign.campaignId).update({
            campaignVideo: [],
          });
        }
        setOpenModal(false);
        getCampaigns(store.storeId, start, end);
      });
  };

  const handleChangeChecked = (event) => {
    setMultipleChoiceQuestion(event.target.checked);
  };

  const getLeadQuestions = (camp) => {
    fire.firestore().collection("users").doc(user.uid).collection("stores").doc(store.storeId).collection("campaigns").doc(camp.campaignId).collection("leadQuestions").orderBy("questionIndex", "asc").get().then((query) => {
      
      setLeadQuestions([]);
      query.forEach((doc) =>{
        setLeadQuestions(currentQuestions => [...currentQuestions, Object.assign(doc.data(), { questionId: doc.id})]);
      });
    });

  };

  const getLeads = (camp) => {
    fire.firestore().collection("users").doc(user.uid).collection("stores").doc(store.storeId).collection("campaigns").doc(camp.campaignId).collection("leads").orderBy("timestamp", "desc").get().then((query) => {
      
      setLeads([]);
      query.forEach((doc) =>{
        setLeads(currentLeads => [...currentLeads, Object.assign(doc.data(), { id: doc.id})]);
      });
    });

  };

  const addQuestion = (e) => {
    e.preventDefault();
    const question = document.getElementById("question").value;
    const questionIndex = document.getElementById("questionIndex").value;

    if (currentQuestion.questionId) {
      const questionsRef = fire.firestore().collection("users").doc(user.uid).collection("stores").doc(store.storeId).collection("campaigns").doc(singleCampaign.campaignId).collection("leadQuestions").doc(currentQuestion.questionId);

      questionsRef.update({question: question, isMultipleChoiceQuestion: isMultipleChoiceQuestion, questionOptions: questionOptions, questionIndex: Number(questionIndex)}).then(() => {
        getLeadQuestions(singleCampaign);
      });
      if (singleCampaign.status === "published") {
        fire.firestore().collection("campaigns").doc(singleCampaign.campaignId).collection("leadQuestions").doc(currentQuestion.questionId).set({question: question, isMultipleChoiceQuestion: isMultipleChoiceQuestion, questionOptions: questionOptions, questionIndex: Number(questionIndex)});
      }

    } else {
      const questionsRef = fire.firestore().collection("users").doc(user.uid).collection("stores").doc(store.storeId).collection("campaigns").doc(singleCampaign.campaignId).collection("leadQuestions").doc();

      questionsRef.set({question: question, isMultipleChoiceQuestion: isMultipleChoiceQuestion, questionOptions: questionOptions, questionIndex: Number(questionIndex)}).then(() => {
        getLeadQuestions(singleCampaign);
      });
      if (singleCampaign.status === "published") {
        fire.firestore().collection("campaigns").doc(singleCampaign.campaignId).collection("leadQuestions").doc(questionsRef.id).set({question: question, isMultipleChoiceQuestion: isMultipleChoiceQuestion, questionOptions: questionOptions, questionIndex: Number(questionIndex)});
      }
    }

    setCurrentQuestion({});
    setMultipleChoiceQuestion(false);
    setLeadQuestionOptions([]);
    setOpenNewQuestion(false);
    setSnackbarSeverity("success");
    setSnackbarMessage("Question saved succesfully");
    setOpenSnackbar(true);
  };

  const deleteQuestion = (question) => {

    fire.firestore().collection("users").doc(user.uid).collection("stores").doc(store.storeId).collection("campaigns").doc(singleCampaign.campaignId).collection("leadQuestions").doc(question.questionId).delete().then(() => {
      getLeadQuestions(singleCampaign);
    });
    if (singleCampaign.status === "published") {
      fire.firestore().collection("campaigns").doc(singleCampaign.campaignId).collection("leadQuestions").doc(question.questionId).delete();
    }

    setSnackbarSeverity("success");
    setSnackbarMessage("Question removed succesfully");
    setOpenSnackbar(true);
  };


  const changePeriod = (event) => {
    setPeriod(event.target.value);
    const selectedPeriod = event.target.value;

    const rightNow = new Date(Date.now());
    const todayDate = new Date().getDate();
    const formattedTodayDate = ("0" + todayDate).slice(-2);
    const thisMonth = ("0" + (new Date().getMonth() + 1)).slice(-2);
    const thisYear = new Date().getFullYear();
    const today = new Date(thisYear+"-"+thisMonth+"-"+formattedTodayDate);
    const startMonth = new Date(thisYear+"-"+thisMonth+"-01");
    const startYear = new Date(thisYear+"-01-01");
    const startLastYear = new Date((thisYear-1)+"-01-01");
    const endLastYear = new Date(thisYear+"-01-01");

    if (selectedPeriod === "Today"){
      const startAt = today;
      const endAt = rightNow;
      getCampaigns(store.storeId, startAt, endAt );
      getPayments(store.storeId, startAt, endAt );
    } else if (selectedPeriod === "This week") {
      const currentDay = new Date().getDay();
      var startAt = today;
      startAt.setDate(today.getDate() - currentDay);
      const endAt = rightNow;
      setStart(startAt);
      setEnd(endAt);
      getCampaigns(store.storeId, startAt, endAt );
      getPayments(store.storeId, startAt, endAt );
    } else if (selectedPeriod === "This month") {
      const startAt = startMonth;
      const endAt = rightNow;
      setStart(startAt);
      setEnd(endAt);
      getCampaigns(store.storeId, startAt, endAt );
      getPayments(store.storeId, startAt, endAt );
    } else if (selectedPeriod === "This quarter") {
      if (thisMonth === "01" || thisMonth === "02" || thisMonth === "03"){
        const startQuarter = new Date(thisYear+"-01-01");
        const startAt = startQuarter;
        const endAt = rightNow;
        setStart(startAt);
        setEnd(endAt);
        getCampaigns(store.storeId, startAt, endAt );
        getPayments(store.storeId, startAt, endAt );
      } else if (thisMonth === "04" || thisMonth === "05" || thisMonth === "06"){
        const startQuarter = new Date(thisYear+"-04-01");
        const startAt = startQuarter;
        const endAt = rightNow;
        setStart(startAt);
        setEnd(endAt);
        getCampaigns(store.storeId, startAt, endAt );
        getPayments(store.storeId, startAt, endAt );
      } else if (thisMonth === "07" || thisMonth === "08" || thisMonth === "09"){
        const startQuarter = new Date(thisYear+"-07-01");
        const startAt = startQuarter;
        const endAt = rightNow;
        setStart(startAt);
        setEnd(endAt);
        getCampaigns(store.storeId, startAt, endAt );
        getPayments(store.storeId, startAt, endAt );
      } else if (thisMonth === "10" || thisMonth === "11" || thisMonth === "12"){
        const startQuarter = new Date(thisYear+"-10-01");
        const startAt = startQuarter;
        const endAt = rightNow;
        setStart(startAt);
        setEnd(endAt);
        getCampaigns(store.storeId, startAt, endAt );
        getPayments(store.storeId, startAt, endAt );
      };
    } else if (selectedPeriod === "This year") {
      const startAt = startYear;
      const endAt = rightNow;
      setStart(startAt);
      setEnd(endAt);
      getCampaigns(store.storeId, startAt, endAt );
      getPayments(store.storeId, startAt, endAt );
    } else if (selectedPeriod === "Last year") {
      const startAt = startLastYear;
      const endAt = endLastYear;
      setStart(startAt);
      setEnd(endAt);
      getCampaigns(store.storeId, startAt, endAt );
      getPayments(store.storeId, startAt, endAt );
    }
  };

  const EnhancedTableToolbar = (props) => {
    const { numSelected } = props;
  
    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
          }),
        }}
      >
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: '1 1 100%' }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            sx={{ flex: '1 1 100%' }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Campaigns
          </Typography>
        )}
  
        {numSelected > 0 ? (
          <ButtonGroup variant="outlined" aria-label="outlined button group">
            <Button onClick={publishCampaigns}  style={{color:'green'}}>Publish campaigns</Button>
          </ButtonGroup>
        ) : (
        <div>
          {(user.uid === "S7KxFuoUOlUNX2l2Tj8Jub8smwu2")&& (searchField === "payoutwallets")&&(
          <Button onClick={getWallets}>
            Get wallets
          </Button>
          )}
          {(user.uid === "S7KxFuoUOlUNX2l2Tj8Jub8smwu2")&& (searchField === "payoutwallets")&& (allWallets.length > 0) &&(
          <PaystackButton {...componentProps3} className="btn btn-primary">
            Pay wallets
          </PaystackButton>
          )}
        {(Number(outstandingBalance > 100))&&(
        <ButtonGroup variant="outlined" aria-label="outlined button group">
          <PaystackButton {...componentProps} className="btn btn-primary">
            Pay now
          </PaystackButton>
        </ButtonGroup>
        )}
        </div>
        )}
      </Toolbar>
    );
  };
  
  EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
  };

  const EnhancedTableToolbarPayments = (props) => {
    const { numSelected } = props;
  
    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
          }),
        }}
      >
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: '1 1 100%' }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            sx={{ flex: '1 1 100%' }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Payments
          </Typography>
        )}
  
        {numSelected > 0 ? (
          <>
          </>
        ) : (
        <>
        <ButtonGroup variant="outlined" aria-label="outlined button group">
          <PaystackButton {...componentProps2} className="btn btn-primary">
            Link card
          </PaystackButton>
        </ButtonGroup>
        </>
        )}
      </Toolbar>
    );
  };

  EnhancedTableToolbarPayments.propTypes = {
    numSelected: PropTypes.number.isRequired,
  };

  const EnhancedTableToolbarLeads = (props) => {
    const { numSelected } = props;
  
    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
          }),
        }}
      >
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: '1 1 100%' }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            sx={{ flex: '1 1 100%' }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Leads
          </Typography>
        )}
  
        {numSelected > 0 ? (
          <>
          </>
        ) : (
        <>
          {(Number(outstandingBalance > 100))&&(
          <ButtonGroup variant="outlined" aria-label="outlined button group">
            <PaystackButton {...componentProps} className="btn btn-primary">
              Pay now
            </PaystackButton>
          </ButtonGroup>
          )}
        </>
        )}
      </Toolbar>
    );
  };

  EnhancedTableToolbarLeads.propTypes = {
    numSelected: PropTypes.number.isRequired,
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = search(campaigns).map((n) => n);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - search(campaigns).length) : 0;


  
  return (
    <ConfirmProvider>
      <React.Fragment>
      <section className="section" style={{width: "100%", height: '100%', minHeight: "100vh", flex: 1, backgroundColor: '#f2f8fb',}}>
      <Container>
      <div>
        <div style={{ backgroundColor: '#10041c', width: '100%', borderRadius: 30}}>
        <Row direction="row" >
          <Col style={{padding: 20}}>
            <FormControl fullWidth style={{color: '#fff'}}>
              <InputLabel id="demo-simple-select-label" style={{color: '#fff'}}>
                {(period === null) && (
                  <>This year</>
                )}
                {(period !== null) && (
                  <>Period</>
                )}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={period}
                onChange={changePeriod}
                label="This year"
                defaultValue='This year'
                style={{color: '#fff'}}
              >
                {periods.map(eachPeriod => {
                  return(
                    <MenuItem value={eachPeriod}>{eachPeriod}</MenuItem>
                  )
                })}
            </Select>
            </FormControl>
          </Col>
          <Col style={{padding: 20,}}>
            <Typography style={{color: '#fff'}}>Outstanding balance</Typography>
            <Typography style={{color: '#fff'}}>NGN {outstandingBalance}</Typography>
          </Col>
          <Col style={{padding: 20,}}>
            <Typography style={{color: '#fff'}}>Amount spent</Typography>
            <Typography style={{color: '#fff'}}>NGN {amountSpent}</Typography>
          </Col>
          <Col style={{padding: 20,}}>
            <Typography style={{color: '#fff'}}>Impressions</Typography>
            <Typography style={{color: '#fff'}}>{totalImpressions}</Typography>
          </Col>
          <Col style={{padding: 20,}}>
            <Typography style={{color: '#fff'}}>Reach</Typography>
            <Typography style={{color: '#fff'}}>{totalReach}</Typography>
          </Col>
        </Row>
        </div>
        {!(singleCampaign.objective === "leads") &&(
        <Paper sx={{ width: '100%', mb: 2, mt: 2 }}>
          <ToggleButtonGroup
            value={myFilter}
            color="primary"
            exclusive
            onChange={handleAlignment}
            aria-label="text alignment"
          >
            <ToggleButton value="All" aria-label="All">
              <Typography>All</Typography>
            </ToggleButton>
            <ToggleButton value="published" aria-label="All">
              <Typography>Published</Typography>
            </ToggleButton>
            <ToggleButton value="drafts" aria-label="All">
              <Typography>Drafts</Typography>
            </ToggleButton>
            <ToggleButton value="traffic" aria-label="All">
              <Typography>Traffic ads</Typography>
            </ToggleButton>
            <ToggleButton value="conversion" aria-label="All">
              <Typography>Conversion ads</Typography>
            </ToggleButton>
            <ToggleButton value="leads" aria-label="All">
              <Typography>Lead Gen ads</Typography>
            </ToggleButton>
            <ToggleButton value="payments" aria-label="All">
              <Typography>Payments</Typography>
            </ToggleButton>
          </ToggleButtonGroup>
          {!(myFilter === "payments")&&(
          <div>
            <Form
              role="search"
              method="get"
              id="searchform"
            >
              <div className="input-group mb-3 border rounded">
                <button type="submit" onClick={(e) => e.preventDefault()} className="input-group-text bg-white border-0" id="searchsubmit"><i className="uil uil-search"></i></button>
                <Input
                  type="text"
                  className="form-control border-0"
                  name="s"
                  id="search"
                  placeholder="Search campaigns"
                  onChange={(e) => setSearchField(e.target.value.toLowerCase())}
                />
                <Button onClick={openNewCampaign}  variant="contained" style={{ backgroundColor: "green", color: "#ffffff"}}>
                  Create Campaign
                </Button>
              </div>
            </Form>
            <EnhancedTableToolbar numSelected={selected.length} />
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={dense ? 'small' : 'medium'}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={timestamp}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={search(campaigns).length}
                />
                <TableBody>
                  {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                    rows.slice().sort(getComparator(order, orderBy)) */}
                  {stableSort(search(campaigns), getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      let date;

                      var impressions = 0;
                      var reach = 0;
                      var allIPs = [];
                      row.impressions.forEach(impression => {
                        impressions +=1;
                        if (!allIPs.includes(impression.ip) && (impression.ip !== "") && (impression.ip !== undefined)){
                          allIPs.push(impression.ip)
                        }
                      });
                      reach = allIPs.length;
                      date = row.timestamp.toDate().toDateString();

                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.campaignId}
                          selected={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              onClick={(event) => handleClick(event, row)}
                              checked={isItemSelected}
                              inputProps={{
                                'aria-labelledby': labelId,
                              }}
                            />
                          </TableCell>
                          <TableCell
                            onClick={() => openModal(row)}
                            id={labelId}
                            scope="row"
                            align="left"
                            component="th"
                          >
                            {row.name}
                          </TableCell>
                          <TableCell align="left" onClick={() => openModal(row)}>NGN {numberSeparator(Number(row.budget))}</TableCell>
                          <TableCell align="left" onClick={() => openModal(row)}>{numberSeparator(impressions)}</TableCell>
                          <TableCell align="left" onClick={() => openModal(row)}>{numberSeparator(reach)}</TableCell>
                          <TableCell align="left" onClick={() => openModal(row)}>NGN {numberSeparator(Number(row.amountSpent))}</TableCell>
                          <TableCell align="left" onClick={() => openModal(row)}>{date}</TableCell>
                          
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: (dense ? 33 : 53) * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={search(campaigns).length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
          )}

          {(myFilter === "payments")&&(
          <div>
            <EnhancedTableToolbarPayments numSelected={selected.length} />
            <div>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>How you'll pay</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    You need to have a card linked to run a campaign.
                    This card will be charged once you reach your payment threshold.
                    To link a card you will be charged NGN 100.
                    This will be added as credit to your account.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>My card</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {(store.authorization) &&(
                    <div>
                      <Typography component="h6">{store.authorization.brand} - {store.authorization.last4} </Typography>
                      <Typography component="h6">Expires on {store.authorization.exp_month}/{store.authorization.exp_year} </Typography>
                    </div>
                  )}
                  {!(store.authorization) &&(
                    <div>
                      <Typography component="h6">No card found</Typography>
                    </div>
                  )}
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Payment threshold</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>NGN {billingThreshold}</Typography>
                </AccordionDetails>
              </Accordion>
              
            </div>
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={dense ? 'small' : 'medium'}
              >
                <EnhancedTableHeadPayments
                  numSelected={selected.length}
                  order={timestamp}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={(payments).length}
                />
                <TableBody>
                  {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                    rows.slice().sort(getComparator(order, orderBy)) */}
                  {stableSort((payments), getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      let date;
                      var paymentMethod = "Dashboard payment";

                      if (row.authorization){
                        paymentMethod = row.authorization.brand + " - " +row.authorization.last4;
                      }

                      date = row.timestamp.toDate().toDateString();

                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                        >
                          <TableCell
                            onClick={() => openModal(row)}
                            id={labelId}
                            scope="row"
                            align="left"
                            component="th"
                          >
                            {row.id}
                          </TableCell>
                          <TableCell align="left" onClick={() => openModal(row)}>{date}</TableCell>
                          <TableCell align="left" onClick={() => openModal(row)}>NGN {numberSeparator(Number(row.amount))}</TableCell>
                          <TableCell align="left" onClick={() => openModal(row)}>{paymentMethod}</TableCell>
                          <TableCell align="left" onClick={() => openModal(row)}>{row.status}</TableCell>
                          
                          
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: (dense ? 33 : 53) * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={payments.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
          )}
        </Paper>
        )}
        {(singleCampaign.objective === "leads") &&(
        <Paper sx={{ width: '100%', mb: 2, mt: 2 }}>
          <ToggleButtonGroup
            value={myFilterLeads}
            color="primary"
            exclusive
            onChange={handleAlignmentLeads}
            aria-label="text alignment"
          >
            <ToggleButton value="All" aria-label="All" onClick={() => setSingleCampaign({})}>
              <Typography>All Campaigns</Typography>
            </ToggleButton>
            <ToggleButton value="questions" aria-label="All">
              <Typography>Questions</Typography>
            </ToggleButton>
            <ToggleButton value="leads" aria-label="All">
              <Typography>Leads</Typography>
            </ToggleButton>
          </ToggleButtonGroup>
          {(myFilterLeads === "leads")&&(
          <div>
            <Form
              role="search"
              method="get"
              id="searchform"
            >
              <div className="input-group mb-3 border rounded">
                <button type="submit" onClick={(e) => e.preventDefault()} className="input-group-text bg-white border-0" id="searchsubmit"><i className="uil uil-search"></i></button>
                <Input
                  type="text"
                  className="form-control border-0"
                  name="s"
                  id="search"
                  placeholder="Search leads"
                  onChange={(e) => setSearchFieldLeads(e.target.value.toLowerCase())}
                />
              </div>
            </Form>
            <EnhancedTableToolbarLeads numSelected={selected.length} />
            <div>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Why can't I see my leads?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Your outstanding balance is too high. Once you pay off your outstandingBalance you will leads will become available
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={dense ? 'small' : 'medium'}
              >
                <EnhancedTableHeadLeads
                  numSelected={selected.length}
                  order={timestamp}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={(leads).length}
                />
                <TableBody>
                  {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                    rows.slice().sort(getComparator(order, orderBy)) */}
                  {stableSort((leads), getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      let date;

                      date = row.timestamp.toDate().toDateString();

                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                        >
                          <TableCell
                            onClick={() => openModalLead(row)}
                            id={labelId}
                            scope="row"
                            align="left"
                            component="th"
                          >
                            {(outstandingBalance > billingThreshold) ? "**************":row.email}
                          </TableCell>
                          <TableCell align="left" onClick={() => openModalLead(row)}>NGN {numberSeparator(Number(row.cost))}</TableCell>
                          <TableCell align="left" onClick={() => openModalLead(row)}>{date}</TableCell>
                          
                          
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: (dense ? 33 : 53) * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={leads.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
          )}
        </Paper>)}
        {(singleCampaign.objective === "leads") && (myFilterLeads === "questions")&&(
          <div>
            <Row>
              <Col lg={4} xs={12}>
                <Card className="shadow rounded border-0">
                  <CardBody className="py-5">
                    {(singleCampaign.name) && (
                      <h4 className="card-title">{singleCampaign.name}</h4>
                    )}
                    <div className="custom-form mt-3">
                      <div id="message"></div>
                      <Form
                        method="post"
                        onSubmit={addQuestion}
                        name="contact-form"
                        id="contact-form"
                      >
                        <Row>
                        {(isOpenNewQuestion === false)&&( 
                          <Col md={12} >
                            <Button color="primary" variant="contained" onClick={() => setOpenNewQuestion(true)} style={{borderRadius: 20, width: '100%'}}>Add a question</Button>
                          </Col>
                          )}
                          {(isOpenNewQuestion === true)&&(
                          <div>
                          <Col md={12}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Question
                              </Label>
                              <Input
                                id="question"
                                type="text"
                                className="form-control "
                                required
                                defaultValue={currentQuestion.question}
                              />
                            </div>
                          </Col>
                          <Col md={12}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Question number
                              </Label>
                              <Input
                                id="questionIndex"
                                type="number"
                                className="form-control "
                                required
                                defaultValue={currentQuestion.questionIndex}
                              />
                            </div>
                          </Col>
                          <Col md={12}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Multiple choice question?
                              </Label>
                              <Switch
                                checked={isMultipleChoiceQuestion}
                                onChange={handleChangeChecked}
                                inputProps={{ 'aria-label': 'controlled' }}
                              />
                            </div>
                          </Col>
                          {(isMultipleChoiceQuestion)&&(<Col md={12}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Question options <span className="text-danger">*</span>
                              </Label>
                              <Autocomplete
                                multiple
                                id="leadQuestionAnswers"
                                options={[]}
                                freeSolo
                                getOptionLabel={(option) => option}
                                onChange={(event, value) => setLeadQuestionOptions(value)}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    placeholder="Add options"
                                  />
                                )}
                                defaultValue={questionOptions}
                              />
                            </div>
                          </Col>)}
                          <Col sm={12} className="text-center">
                            <div className="d-grid">
                              <input
                                type="submit"
                                id="submit"
                                name="send"
                                className="submitBnt btn btn-primary"
                                value="Save question"
                              />
                            </div>
                          </Col>
                          </div>
                          )}
                        </Row>
                      </Form>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={8} xs={12}>
              <Card className={classes.root}>
                <CardActionArea>
                  <CardContent>
                    <Typography variant="h6"  component="h3">
                      Questions
                    </Typography>
                    <div style={{display: 'flex', flexDirection: "column", justifyContent: 'center', marginTop: 30}}>
                    {leadQuestions && leadQuestions.map((item, i) => {
                      return (
                        <div key={i} style={{marginBottom: 20}}>
                          <div style={{display: 'flex', flexDirection: "row", justifyContent: 'space-between', }}>
                            <Typography variant="h6"  component="p">
                              {item.questionIndex}) {item.question}
                            </Typography>
                            <div>
                              {!(currentQuestion.questionId) && (
                              <Button title="Edit" onClick={()=> {setOpenNewQuestion(false); setCurrentQuestion(item); setMultipleChoiceQuestion(item.isMultipleChoiceQuestion); setLeadQuestionOptions(item.questionOptions); setOpenNewQuestion(true);}}>
                                <EditIcon style={{color:'grey'}} />
                              </Button>
                              )}
                              <Button title="Delete" onClick={()=> deleteQuestion(item)}>
                                <DeleteIcon style={{color:'red'}} />
                              </Button>
                            </div>
                          </div>
                          {(item.isMultipleChoiceQuestion) && (
                            <ul>
                              {item.questionOptions.map((option, index) => {
                                return(
                                  <li>
                                  <Typography key={index}variant="subtitle2"  component="subtitle2">
                                    {option}
                                  </Typography>
                                  </li>
                                );
                              })}
                            </ul>
                          )}
                        </div>
                      );
                    })}
                    </div>
                  </CardContent>
                </CardActionArea>
              </Card>
              </Col>
            </Row>
          </div>
        )}
        <Modal isOpen={isNewCampaignOpen} toggle={closeModal} centered size="lg">
          <ModalHeader toggle={closeModal}>New Campaign</ModalHeader>
          <ModalBody className="p-4">
            <Container fluid className="px-0">
              <Row>
                <h4 className="card-title">Create your Campaign!</h4>
                <div className="custom-form mt-3">
                  <div id="message"></div>
                  <Form
                    method="post"
                    onSubmit={createCampaign}
                    name="contact-form"
                    id="contact-form"
                  >
                    <Row>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label className="form-label">
                            Your Campaign Name <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="name"
                            id="name"
                            type="text"
                            className="form-control ps-5"
                            placeholder="Campaign Name:"
                            required
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label className="form-label">
                            Campaign budget <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="budget"
                            id="budget"
                            type="number"
                            className="form-control ps-5"
                            placeholder="10000"
                            required
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label className="form-label">
                          {campaignObjective === "leads" ? "Redirect website url" : "Website Url"} <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="website"
                            id="websiteUrl"
                            type="text"
                            className="form-control ps-5"
                            placeholder="https://example.com"
                            required
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label className="form-label">
                            Enter the different hashtags you have, separate them with a comma <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="hashtags"
                            id="hashTags"
                            type="text"
                            className="form-control ps-5"
                            placeholder="e.g fitness,gym,sport,exercise"
                            required
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label className="form-label">
                            Campaign objective <span className="text-danger">*</span>
                          </Label>
                          <select className="btn btn-icon form-control ps-5 quantity" id="campaignObjective" onChange={changeCampaignObjective} style={{width: "100%"}}>
                            <option key={0} value={"traffic"}>Traffic</option>
                            <option key={1} value={"conversion"}>Conversion</option>
                            <option key={2} value={"leads"}>Lead generation</option>
                          </select>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label className="form-label">
                            Cost per event <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="costPerEvent"
                            id="costPerEvent"
                            type="number"
                            className="form-control ps-5"
                            placeholder="2000"
                            defaultValue={2000}
                            required
                          />
                        </div>
                      </Col>
                      {addCtaButtonText}
                      {addConversionEvent}
                      {addTargetProducts}
                      <Col md={12}>
                        <div className="mb-3">
                          <Label className="form-label">
                            Campaign Description{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <textarea
                            name="description"
                            id="description"
                            type="text"
                            className="form-control ps-5"
                            placeholder="Describe your campaign or what you are selling:"
                            rows={5}
                            required
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12} className="text-center">
                        <div className="d-grid">
                          <input
                            type="submit"
                            id="submit"
                            name="send"
                            className="submitBnt btn btn-primary"
                            value="Create campaign"
                          />
                        </div>
                        <div id="simple-msg"></div>
                        <div onClick={closeModal} className="btn btn-soft-primary ms-2">
                          Close
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </Row>
            </Container>
          </ModalBody>
        </Modal>
        <Modal isOpen={isModalOpen} toggle={closeModal} centered size="lg">
        <ModalHeader toggle={closeModal}>{singleCampaign.name}</ModalHeader>
        <ModalBody className="p-4">
          <Container fluid className="px-0">
            <Row>
              <h4 className="card-title">Edit your Campaign!</h4>
              {/* <Col lg={5}>
                <div className="tiny-single-item">
                  <div className="tiny-slide"><img src={product.picUrl} className="img-fluid rounded" alt="" /></div>
                </div>
                <InputLabel style={{display: 'flex', justifyContent: 'center',alignItems:'center'}}>
                  <Input type="file" onChange={selectPic} onClick={() => newProductId(product)} style={{display: 'none'}} />
                  <Typography color="primary">Change product image</Typography>
                </InputLabel>
              </Col> */}
              <div className="custom-form mt-3">
                <div id="message"></div>
                <Form
                  name="contact-form"
                  id="contact-form"
                >
                  <Row>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label className="form-label">
                          Your Campaign Name <span className="text-danger">*</span>
                        </Label>
                        <Input
                          name="name"
                          id="name"
                          type="text"
                          className="form-control ps-5"
                          placeholder="Campaign Name:"
                          defaultValue={singleCampaign.name}
                          required
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label className="form-label">
                          Campaign budget <span className="text-danger">*</span>
                        </Label>
                        <Input
                          name="price"
                          id="budget"
                          type="number"
                          className="form-control ps-5"
                          placeholder=""
                          defaultValue={singleCampaign.budget}
                          required
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label className="form-label">
                          {singleCampaign.objective === "leads" ? "Redirect website url" : "Website Url"} <span className="text-danger">*</span>
                        </Label>
                        <Input
                          name="website"
                          id="websiteUrl"
                          type="text"
                          className="form-control ps-5"
                          placeholder="https://example.com"
                          defaultValue={singleCampaign.websiteUrl}
                          required
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label className="form-label">
                          Enter the different hashtags you have, separate them with a comma <span className="text-danger">*</span>
                        </Label>
                        <Input
                          name="hashtags"
                          id="hashTags"
                          type="text"
                          className="form-control ps-5"
                          placeholder="e.g fitness,gym,sport,exercise"
                          defaultValue={singleCampaign.hashTags}
                        />
                      </div>
                    </Col>
                    <Col md={12}>
                      <div className="mb-3">
                        <Label className="form-label">
                          Campaign Description{" "}
                          <span className="text-danger">*</span>
                        </Label>
                        <textarea
                          name="description"
                          id="description"
                          type="text"
                          className="form-control ps-5"
                          placeholder="Describe your campaign or what you are selling:"
                          rows={5}
                          defaultValue={singleCampaign.description}
                          required
                        />
                      </div>
                    </Col>
                    {(singleCampaign.objective === "leads") &&(
                      <Col md={6}>
                        <div className="mb-3">
                          <Label className="form-label">
                            Call to action text <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="ctaButtonText"
                            id="ctaButtonText"
                            type="text"
                            className="form-control ps-5"
                            placeholder="Call to action:"
                            required
                            defaultValue={singleCampaign.ctaButtonText}
                          />
                        </div>
                      </Col>
                    )}
                    {(singleCampaign.objective === "conversion") &&(
                      <Col md={6}>
                        <div className="mb-3">
                          <Label className="form-label">
                            Conversion event <span className="text-danger">*</span>
                          </Label>
                          <select className="btn btn-icon form-control ps-5 quantity" id="conversionEvent"  style={{width: "100%"}}>
                            <option key={0} value={singleCampaign.conversionEvent}>{singleCampaign.conversionEvent}</option>
                          </select>
                        </div>
                      </Col>
                    )}
                    <Col md={6}>
                      <div className="mb-3">
                        <Label className="form-label">
                          Cost per event <span className="text-danger">*</span>
                        </Label>
                        <Input
                          name="costPerEvent"
                          id="costPerEvent"
                          type="number"
                          className="form-control ps-5"
                          placeholder="2000"
                          defaultValue={singleCampaign.costPerEvent}
                          required
                        />
                      </div>
                    </Col>

                    {(singleCampaign.objective === "conversion") &&(
                      <Col md={6}>
                        <div className="mb-3">
                          <Label className="form-label">
                            Add products this campaign targets <span className="text-danger">*</span>
                          </Label>
                          <Autocomplete
                            multiple
                            id="targetProducts"
                            options={options}
                            getOptionLabel={(option) => option.name}
                            onChange={(event, value) => setTargetProducts(value)}
                            defaultValue={singleCampaign.targetProducts}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Select"
                              />
                            )}
                          />
                        </div>
                      </Col>
                    )}
                    <Col md={6}>
                      <div className="mb-3">
                        <Label className="form-label">
                          Ad format <span className="text-danger">*</span>
                        </Label>
                        <select className="btn btn-icon form-control ps-5 quantity" id="adFormat" defaultValue={singleCampaign.adFormat ? singleCampaign.adFormat : "image"} style={{width: "100%"}}>
                          <option key={0} value={"image"}>Image</option>
                          <option key={1} value={"video"}>Video</option>
                        </select>
                      </div>
                    </Col>
                    {(singleCampaign.adFormat === "image") &&(
                    <Col md={6}>
                      <div className="mb-3">
                        <Label className="form-label">
                          Add Images
                        </Label>
                        <ButtonGroup variant="outlined" aria-label="outlined button group" className="ps-5">
                          <InputLabel style={{display: 'flex', justifyContent: 'center',alignItems:'center', paddingLeft: 20, paddingRight: 20, backgroundColor: "#2f55d4", borderRadius: 5}}>
                            <Typography color="primary" style={{color: '#fff',}}>ADD IMAGE</Typography>
                            <Input type="file" onChange={selectPic} style={{display: 'none',}} />
                          </InputLabel>
                          <Button  style={{color:'red'}} onClick={clearImages}>Clear Images</Button>
                        </ButtonGroup>
                      </div>
                    </Col>
                    )}
                    {(singleCampaign.adFormat === "video") &&(
                    <Col md={6}>
                      <div className="mb-3">
                        <Label className="form-label">
                          Add Video
                        </Label>
                        <ButtonGroup variant="outlined" aria-label="outlined button group" className="ps-5">
                          <InputLabel style={{display: 'flex', justifyContent: 'center',alignItems:'center', paddingLeft: 20, paddingRight: 20, backgroundColor: "#2f55d4", borderRadius: 5}}>
                            <Typography color="primary" style={{color: '#fff',}}>ADD VIDEO</Typography>
                            <Input type="file" onChange={selectVideo} style={{display: 'none',}} />
                          </InputLabel>
                          <Button  style={{color:'red'}} onClick={clearVideos}>Remove Video</Button>
                        </ButtonGroup>
                      </div>
                    </Col>
                    )}
                    {(singleCampaign.adFormat === "image") && (Array.isArray(campaignImages)) && (campaignImages.length > 0) && (
                    <Col md={12}>
                      <Swiper
                        spaceBetween={10}
                        slidesPerView={2}
                        onSlideChange={() => {}}
                        onSwiper={(swiper) => {}}
                      >
                        {campaignImages && campaignImages.map((slide, i) => {
                          return(
                            <SwiperSlide key={i}>
                            <CardMedia
                              className={classes.media}
                              image={slide}
                              title="Main image"
                              style={{height: 400}}
                            >
                              {/* <div style={{marginTop: 30, display: 'flex', justifyContent: 'space-between'}}>
                                <div  style={{marginRight: 40,}} >
                                  <Button title="Delete" onClick={()=> deleteSlide(slide)}>
                                    <DeleteIcon style={{color:'red'}} />
                                  </Button>
                                </div>
                              </div> */}
                              
                            </CardMedia>
                            </SwiperSlide>
                          );
                        })}
                      </Swiper>
                      <Typography variant="body2" component="h6">Swipe</Typography>
                    </Col>
                    )}
                    {(singleCampaign.adFormat === "video") && (Array.isArray(singleCampaign.videosUrl)) && (singleCampaign.videosUrl.length >0) && (
                    <Col md={12} style={{display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: "center",}}>
                      <Button variant="contained" style={{ marginBottom: 20}} onClick={() => window.open(singleCampaign.videosUrl[0])}>Preview</Button>
                      <CardMedia
                        component={"video"}
                        src={singleCampaign.videosUrl[0]}
                      >
                        
                      </CardMedia>
                    </Col>
                    )}
                  </Row>
                  <Row>
                    <Col sm={12} className="text-center">
                      <div className="d-grid">
                        <input
                          id="submit"
                          name="send"
                          className="submitBnt btn btn-primary"
                          value="Update Campaign"
                          onClick={() =>updateCampaign(singleCampaign)}
                        />
                      </div>
                      <div id="simple-msg"></div>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Row>
          </Container>
        </ModalBody>
        </Modal>
        <Modal isOpen={isModalLeadOpen} toggle={closeModal} centered size="lg">
          <ModalHeader toggle={closeModal}>Lead</ModalHeader>
          <ModalBody className="p-4">
            <Container fluid className="px-0">
              <Row>
                <h4 className="card-title">{singleLead.email}</h4>
                {singleLead.questions && singleLead.questions.map((item, i) => {
                  return (
                    <div key={i} style={{marginBottom: 20}}>
                      <div style={{display: 'flex', flexDirection: "row", justifyContent: 'space-between', }}>
                        <Typography variant="h6"  component="p">
                          {item.question}
                        </Typography>
                      </div>
                      <Typography variant="subtitle2"  component="subtitle2">
                        {item.answer}
                      </Typography>
                    </div>
                  );
                })}
              </Row>
            </Container>
          </ModalBody>
        </Modal>
        <Snackbar open={isSnackbarOpen} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity={snackbarSeverity}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </div>
      </Container>
      </section>
      </React.Fragment>
    </ConfirmProvider>
  );
}

export default MyCampaigns;

async function sendPushNotification({expoPushToken, title, body}) {
  const message = {
    to: expoPushToken,
    sound: 'default',
    title: title,
    body: body,
    data: { someData: 'goes here' },
  };

  await fetch('https://exp.host/--/api/v2/push/send', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Accept-encoding': 'gzip, deflate',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(message),
  });
};
