import React from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Input,
  CardBody,
  Label,
  Modal,
  ModalBody
} from "reactstrap";
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@mui/material/Button';

import Switch from '@mui/material/Switch';

import fire from "../Fire.js";
import firebase from 'firebase';

const useStyles = makeStyles({
  root: {
  },
  media: {
    height: 500,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  media2: {
    height: 200,
    marginTop: 40,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
});


function useStore() {
  const [store, setStore] = React.useState({});
  const user = fire.auth().currentUser;

  React.useEffect(() => {
    const unsubscribe = fire
    .firestore()
    .collection("users")
    .doc(user.uid)
    .collection("stores")
    .onSnapshot(querySnapshot => {
      querySnapshot.forEach(doc => {
        fire
        .firestore()
        .collection("users")
        .doc(user.uid)
        .collection("stores")
        .doc(doc.id)
        .collection("pages")
        .doc("contact")
        .onSnapshot(page => {
          setStore(Object.assign(doc.data(), {storeId: doc.id, contact: page.data()}));
        });
      })
    });
    return () => unsubscribe;
  }, [user.uid]);
  
  return store;
};

const EditContact = ({profile}) => {
  const classes = useStyles();
  const user = fire.auth().currentUser;
  const store = useStore();
  const [isOpenNewCollection, setOpenNewCollection] = React.useState(false);
  const [currentCollection, setCurrentCollection] = React.useState();

  
  const updateContactPage = (e) => {
    e.preventDefault();
    const title = document.getElementById("title").value;
    const subtitle = document.getElementById("subtitle").value;
    const address = document.getElementById("address").value;
    const phoneNumber = document.getElementById("phoneNumber").value;
    const email = document.getElementById("email").value;
    const hoursOfOperation = document.getElementById("hoursOfOperation").value;
    const mapUrl = document.getElementById("mapUrl").value;

    fire
    .firestore()
    .collection("users")
    .doc(user.uid)
    .collection("stores")
    .doc(store.storeId)
    .collection("pages")
    .doc("contact")
    .update({
      title: title,
      subtitle: subtitle,
      address: address,
      phoneNumber: phoneNumber,
      email: email,
      hoursOfOperation: hoursOfOperation,
      mapUrl: mapUrl,
    });
    fire
    .firestore()
    .collection("stores")
    .doc(store.storeId)
    .collection("pages")
    .doc("contact")
    .update({
      title: title,
      subtitle: subtitle,
      address: address,
      phoneNumber: phoneNumber,
      email: email,
      hoursOfOperation: hoursOfOperation,
      mapUrl: mapUrl,
    });
  };

  const handleChangeShowPage = (event) => {
    fire
    .firestore()
    .collection("users")
    .doc(user.uid)
    .collection("stores")
    .doc(store.storeId)
    .update({
      showContact: event.target.checked,
    });
    fire
    .firestore()
    .collection("stores")
    .doc(store.storeId)
    .update({
      showContact: event.target.checked,
    });
    fire
    .firestore()
    .collection("users")
    .doc(user.uid)
    .collection("stores")
    .doc(store.storeId)
    .collection("pages")
    .doc("contact")
    .update({
      showPage: event.target.checked,
    });
    fire
    .firestore()
    .collection("stores")
    .doc(store.storeId)
    .collection("pages")
    .doc("contact")
    .update({
      showPage: event.target.checked,
    });
  };

  const handleChangeShowAddress = (event) => {
    fire
    .firestore()
    .collection("users")
    .doc(user.uid)
    .collection("stores")
    .doc(store.storeId)
    .collection("pages")
    .doc("contact")
    .update({
      showAddress: event.target.checked,
    });
    fire
    .firestore()
    .collection("stores")
    .doc(store.storeId)
    .collection("pages")
    .doc("contact")
    .update({
      showAddress: event.target.checked,
    });
  };

  const handleChangeShowContactDetails = (event) => {
    fire
    .firestore()
    .collection("users")
    .doc(user.uid)
    .collection("stores")
    .doc(store.storeId)
    .collection("pages")
    .doc("contact")
    .update({
      showContactDetails: event.target.checked,
    });
    fire
    .firestore()
    .collection("stores")
    .doc(store.storeId)
    .collection("pages")
    .doc("contact")
    .update({
      showContactDetails: event.target.checked,
    });
  }

  const handleChangeShowHoursOfOperation = (event) => {
    fire
    .firestore()
    .collection("users")
    .doc(user.uid)
    .collection("stores")
    .doc(store.storeId)
    .collection("pages")
    .doc("contact")
    .update({
      showHoursOfOperation: event.target.checked,
    });
    fire
    .firestore()
    .collection("stores")
    .doc(store.storeId)
    .collection("pages")
    .doc("contact")
    .update({
      showHoursOfOperation: event.target.checked,
    });
  }

  const handleChangeShowMap = (event) => {
    fire
    .firestore()
    .collection("users")
    .doc(user.uid)
    .collection("stores")
    .doc(store.storeId)
    .collection("pages")
    .doc("contact")
    .update({
      showMap: event.target.checked,
    });
    fire
    .firestore()
    .collection("stores")
    .doc(store.storeId)
    .collection("pages")
    .doc("contact")
    .update({showMap: event.target.checked,
    });
  }

  return (
    <React.Fragment>

        <Container>
          <Row>
            <Col lg={4} xs={12}>
              <Card className="shadow rounded border-0">
                <CardBody className="py-5">
                  <h4 className="card-title">Edit Contact page</h4>
                  <div className="custom-form mt-3">
                    <div id="message"></div>
                    <Form
                      method="post"
                      onSubmit={updateContactPage}
                      name="contact-form"
                      id="contact-form"
                    >
                      <Row>

                        <Col md={12}>
                          <div className="mb-3">
                            <Label className="form-label">
                              Show Contact page on your website?
                            </Label>
                            <Switch
                              checked={store? store.showContact : false}
                              onChange={handleChangeShowPage}
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                          </div>
                        </Col>

                        <Col md={12}>
                          <div className="mb-3">
                            <Label className="form-label">
                              Title
                            </Label>
                            <Input
                              id="title"
                              type="text"
                              className="form-control "
                              defaultValue={store.contact? store.contact.title : ""}
                              required
                            />
                          </div>
                        </Col>
                        <Col md={12}>
                          <div className="mb-3">
                            <Label className="form-label">
                              Subtitle
                            </Label>
                            <Input
                              id="subtitle"
                              type="text"
                              className="form-control "
                              defaultValue={store.contact? store.contact.subtitle : ""}
                              required
                            />
                          </div>
                        </Col>

                        <Col md={12}>
                          <div className="mb-3">
                            <Label className="form-label">
                              Show address?
                            </Label>
                            <Switch
                              checked={store.contact ? store.contact.showAddress : false}
                              onChange={handleChangeShowAddress}
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                          </div>
                        </Col>

                        {store.contact && (
                          <Col md={12} style={{display: store.contact.showAddress === true? "":"none"}}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Address
                              </Label>
                              <Input
                                id="address"
                                type="text"
                                className="form-control "
                                defaultValue={store.contact? store.contact.address : ""}
                                required
                              />
                            </div>
                          </Col>
                        )}

                        <Col md={12}>
                          <div className="mb-3">
                            <Label className="form-label">
                              Show contact details?
                            </Label>
                            <Switch
                              checked={store.contact ? store.contact.showContactDetails : false}
                              onChange={handleChangeShowContactDetails}
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                          </div>
                        </Col>

                        {store.contact && (
                          <div style={{display: store.contact.showContactDetails === true? "":"none"}}>
                          <Col md={12}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Phone number
                              </Label>
                              <Input
                                id="phoneNumber"
                                type="text"
                                className="form-control "
                                defaultValue={store.contact? store.contact.phoneNumber : ""}
                                required
                              />
                            </div>
                          </Col>
                          <Col md={12}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Email
                              </Label>
                              <Input
                                id="email"
                                type="text"
                                className="form-control "
                                defaultValue={store.contact? store.contact.email : ""}
                                required
                              />
                            </div>
                          </Col>
                          </div>
                        )}

                        <Col md={12}>
                          <div className="mb-3">
                            <Label className="form-label">
                              Show hours of operation?
                            </Label>
                            <Switch
                              checked={store.contact ? store.contact.showHoursOfOperation : false}
                              onChange={handleChangeShowHoursOfOperation}
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                          </div>
                        </Col>

                        {store.contact && (
                          <Col md={12} style={{display: store.contact.showHoursOfOperation === true? "":"none"}}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Hours Of Operation
                              </Label>
                              <textarea
                                id="hoursOfOperation"
                                type="text"
                                rows={"5"}
                                className="form-control "
                                defaultValue={store.contact? store.contact.hoursOfOperation : ""}
                                required
                              />
                            </div>
                          </Col>
                        )}

                        <Col md={12}>
                          <div className="mb-3">
                            <Label className="form-label">
                              Show map?
                            </Label>
                            <Switch
                              checked={store.contact ? store.contact.showMap : false}
                              onChange={handleChangeShowMap}
                              inputProps={{ 'aria-label': 'controlled' }}
                            />
                          </div>
                        </Col>

                        {store.contact && (
                          <Col md={12} style={{display: store.contact.showMap === true? "":"none"}}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Google map iframe link
                              </Label>
                              <textarea
                                id="mapUrl"
                                type="text"
                                rows={"5"}
                                className="form-control "
                                defaultValue={store.contact? store.contact.mapUrl : ""}
                                required
                              />
                            </div>
                          </Col>
                        )}

                      </Row>
                      <Row>
                        <Col sm={12} className="text-center">
                          <div className="d-grid">
                            <input
                              type="submit"
                              id="submit"
                              name="send"
                              className="submitBnt btn btn-primary"
                              value="Save changes"
                            />
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg={8} xs={12}>
            <Card className={classes.root}>
              <CardActionArea>
                
                {(store.contact)&&(
                <CardContent >
                  <div class="contact-page-top-info space-mb--r100">
                    <div class="container"><div class="row">
                      <div class="col-lg-12">
                        <div class="section-title-container text-center space-mb--r80">
                          <h4 class="section-title--secondary">{store.contact.title}</h4>
                          <h2 class="section-title space-mt--20">{store.contact.subtitle}</h2>
                        </div>
                      </div>
                    </div>
                    <div class="space-mb-mobile-only--m50 row text-center" >
                      {(store.contact.showAddress === true)&&(
                      <div class="space-mb-mobile-only--50 col-md-4">
                        <div class="icon-box">
                          <div class="icon-box__icon">
                            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                              <path d="M256 48c-79.5 0-144 59.9-144 133.7 0 104 144 282.3 144 282.3s144-178.3 144-282.3C400 107.9 335.5 48 256 48zm0 190.9c-25.9 0-46.9-21-46.9-46.9s21-46.9 46.9-46.9 46.9 21 46.9 46.9-21 46.9-46.9 46.9z"></path>
                            </svg>
                          </div>
                          <div class="icon-box__content">
                            <h3 class="title">ADDRESS</h3>
                            <p class="content">{store.contact.address}</p>
                          </div>
                        </div>
                      </div>
                      )}
                      {(store.contact.showContactDetails === true)&&(
                      <div class="space-mb-mobile-only--50 col-md-4">
                        <div class="icon-box">
                          <div class="icon-box__icon">
                            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                              <path d="M436.9 364.8c-14.7-14.7-50-36.8-67.4-45.1-20.2-9.7-27.6-9.5-41.9.8-11.9 8.6-19.6 16.6-33.3 13.6-13.7-2.9-40.7-23.4-66.9-49.5-26.2-26.2-46.6-53.2-49.5-66.9-2.9-13.8 5.1-21.4 13.6-33.3 10.3-14.3 10.6-21.7.8-41.9C184 125 162 89.8 147.2 75.1c-14.7-14.7-18-11.5-26.1-8.6 0 0-12 4.8-23.9 12.7-14.7 9.8-22.9 18-28.7 30.3-5.7 12.3-12.3 35.2 21.3 95 27.1 48.3 53.7 84.9 93.2 124.3l.1.1.1.1c39.5 39.5 76 66.1 124.3 93.2 59.8 33.6 82.7 27 95 21.3 12.3-5.7 20.5-13.9 30.3-28.7 7.9-11.9 12.7-23.9 12.7-23.9 2.9-8.1 6.2-11.4-8.6-26.1z"></path>
                            </svg>
                          </div>
                          <div class="icon-box__content"><h3 class="title">CONTACT</h3>
                            <p class="content">Mobile: {store.contact.phoneNumber}</p>
                          </div>
                        </div>
                        <div class="icon-box">
                          <div class="icon-box__icon">
                            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                              <path d="M460.6 147.3L353 256.9c-.8.8-.8 2 0 2.8l75.3 80.2c5.1 5.1 5.1 13.3 0 18.4-2.5 2.5-5.9 3.8-9.2 3.8s-6.7-1.3-9.2-3.8l-75-79.9c-.8-.8-2.1-.8-2.9 0L313.7 297c-15.3 15.5-35.6 24.1-57.4 24.2-22.1.1-43.1-9.2-58.6-24.9l-17.6-17.9c-.8-.8-2.1-.8-2.9 0l-75 79.9c-2.5 2.5-5.9 3.8-9.2 3.8s-6.7-1.3-9.2-3.8c-5.1-5.1-5.1-13.3 0-18.4l75.3-80.2c.7-.8.7-2 0-2.8L51.4 147.3c-1.3-1.3-3.4-.4-3.4 1.4V368c0 17.6 14.4 32 32 32h352c17.6 0 32-14.4 32-32V148.7c0-1.8-2.2-2.6-3.4-1.4z"></path>
                              <path d="M256 295.1c14.8 0 28.7-5.8 39.1-16.4L452 119c-5.5-4.4-12.3-7-19.8-7H79.9c-7.5 0-14.4 2.6-19.8 7L217 278.7c10.3 10.5 24.2 16.4 39 16.4z"></path>
                            </svg>
                          </div>
                          <div class="icon-box__content">
                            <p class="content"> Mail: {store.contact.email} </p>
                          </div>
                        </div>
                      </div>
                      )}
                      {(store.contact.showHoursOfOperation === true)&&(<div class="space-mb-mobile-only--50 col-md-4">
                        <div class="icon-box">
                          <div class="icon-box__icon">
                            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                              <path d="M403.1 108.9c-81.2-81.2-212.9-81.2-294.2 0-81.3 81.2-81.2 212.9 0 294.2 81.2 81.2 212.9 81.2 294.2 0 81.2-81.2 81.2-213 0-294.2zM89.6 263.8c-5.6 1.1-10.5-3.8-9.4-9.4.6-3.1 3.1-5.7 6.3-6.3 5.6-1.1 10.5 3.8 9.4 9.4-.6 3.2-3.2 5.7-6.3 6.3zm26.2 82.2c-4.3 3.7-11 1.9-12.8-3.5-1-3-.1-6.5 2.3-8.5 4.3-3.7 11-1.9 12.8 3.5 1 3 .1 6.4-2.3 8.5zm2.3-171.5c-1.9 5.4-8.5 7.2-12.8 3.5-2.4-2.1-3.3-5.5-2.3-8.5 1.9-5.4 8.5-7.2 12.8-3.5 2.4 2.1 3.3 5.5 2.3 8.5zM179.6 404c-1.9 5.4-8.5 7.2-12.8 3.5-2.4-2.1-3.3-5.5-2.3-8.5 1.9-5.4 8.5-7.2 12.8-3.5 2.4 2.1 3.3 5.5 2.3 8.5zm-2.3-287.5c-4.3 3.7-11 1.9-12.8-3.5-1-3-.1-6.5 2.3-8.5 4.3-3.7 11-1.9 12.8 3.5 1 3 .1 6.4-2.3 8.5zm35.4 76.3c-2.3-3.8-1-8.7 2.7-11 3.8-2.3 8.7-1 11 2.7l33.7 62.3c2.5 4.7 3.9 9.9 3.9 15.2v122c0 5-3 8-8 8s-8-3-8-8V262c0-2.7-.7-5.3-1.9-7.6l-33.4-61.6zm44.9 239c-5.6 1.1-10.5-3.8-9.4-9.4.6-3.1 3.1-5.7 6.3-6.3 5.6-1.1 10.5 3.8 9.4 9.4-.6 3.2-3.2 5.7-6.3 6.3zm0-336c-5.6 1.1-10.5-3.8-9.4-9.4.6-3.1 3.1-5.7 6.3-6.3 5.6-1.1 10.5 3.8 9.4 9.4-.6 3.2-3.2 5.7-6.3 6.3zm87.7 311.7c-4.3 3.7-11 1.9-12.8-3.5-1-3-.1-6.5 2.3-8.5 4.3-3.7 11-1.9 12.8 3.5 1 3 .1 6.4-2.3 8.5zm2.3-294.5c-1.9 5.4-8.5 7.2-12.8 3.5-2.4-2.1-3.3-5.5-2.3-8.5 1.9-5.4 8.5-7.2 12.8-3.5 2.4 2.1 3.3 5.5 2.3 8.5zm61.5 229.5c-1.9 5.4-8.5 7.2-12.8 3.5-2.4-2.1-3.3-5.5-2.3-8.5 1.9-5.4 8.5-7.2 12.8-3.5 2.4 2.1 3.3 5.5 2.3 8.5zM406.8 178c-4.3 3.7-11 1.9-12.8-3.5-1-3-.1-6.5 2.3-8.5 4.3-3.7 11-1.9 12.8 3.5 1 3 .1 6.4-2.3 8.5zm18.8 85.8c-5.6 1.1-10.5-3.8-9.4-9.4.6-3.1 3.1-5.7 6.3-6.3 5.6-1.1 10.5 3.8 9.4 9.4-.6 3.2-3.2 5.7-6.3 6.3z"></path>
                            </svg>
                          </div>
                          <div class="icon-box__content">
                            <h3 class="title">HOUR OF OPERATION</h3>
                            <p class="content">{store.contact.hoursOfOperation}</p>
                          </div>
                        </div>
                      </div>)}

                    </div>
                    </div>
                  </div>
                  {(store.contact.showMap)&&(
                  <div class="contact-page-map space-mb--r100" style={{width: "100%"}}>
                    <div class="container">
                      <div class="row">
                        <div class="col-lg-12">
                          <div class="google-map">
                            <iframe title="map" src={store.contact.mapUrl} allowfullscreen="" style={{width: "100%", height: 300}}></iframe>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  )}
                  <div class="contact-page-form">
                    <div class="container">
                      <div class="row">
                        <div class="col-lg-12">
                          <div class="section-title-container text-center space-mb--r80">
                            <h2 class="section-title ">Get in touch</h2>
                            </div>
                          </div>
                        </div>
                        <div >
                          <div class="ml-auto mr-auto col-lg-8">
                            <div class="lezada-form contact-form" >
                                <div class="row" >
                                  <div class="space-mb--40 col-md-6">
                                    <Input type="text" placeholder="First Name *" name="customerName" id="customerName" required=""/>
                                  </div>
                                  <div class="space-mb--40 col-md-6">
                                    <Input type="email" placeholder="Email *" name="customerEmail" id="customerEmail" required=""/>
                                  </div>
                                  <div class="space-mb--40 col-md-12">
                                    <Input type="text" placeholder="Subject" name="contactSubject" id="contactSubject"/>
                                  </div>
                                  <div class="space-mb--40 col-md-12">
                                    <textarea className="form-control "rows="4" placeholder="Message" name="contactMessage" id="contactMessage"></textarea>
                                  </div>
                                  <div class="text-center col-md-12">
                                    <Button type="submit" value="submit" id="submit" className="submitBnt btn btn-primary">submit</Button>
                                  </div>
                                </div>
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardContent>
                )}
              </CardActionArea>
            </Card>
            </Col>
          </Row>
        </Container>
    </React.Fragment>
  );
};


export default EditContact;
