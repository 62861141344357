const banks = [
    {
        value: '044',
        label: 'Access Bank',
    },
    {
        value: '063',
        label: 'Access Bank(Daimond)',
    },
    {
        value: '035A',
        label: 'ALAT by WEMA',
    },
    {
        value: '401',
        label: 'ASO savings and loans',
    },
    {
        value: '50931',
        label: 'Bowen Microfinance Bank',
    },
    {
        value: '50823',
        label: 'CEMCS Microfinance Bank',
    },
    {
        value: '023',
        label: 'Citibank Nigeria',
    },
    {
        value: '050',
        label: 'Ecobank Nigeria',
    },
    {
        value: '562',
        label: 'Ekondo Microfinance Bank',
    },
    {
        value: '070',
        label: 'Fidelity Bank',
    },
    {
        value: '011',
        label: 'First Bank of NIgeria',
    },
    {
        value: '214',
        label: 'First City Monument Bank',
    },
    {
        value: '00103',
        label: 'Globus Bank',
    },
    {
        value: '058',
        label: 'Guaranty Trust Bank',
    },
    {
        value: '50383',
        label: 'Hasal Microfinance Bank',
    },
    {
        value: '030',
        label: 'Heritage Bank',
    },
    {
        value: '301',
        label: 'Jaiz Bank',
    },
    {
        value: '082',
        label: 'Keystone Bank',
    },
    {
        value: '50211',
        label: 'Kuda Bank',
    },
    {
        value: '565',
        label: 'One Finance',
    },
    {
        value: '526',
        label: 'Parallex Bank',
    },
    {
        value: '076',
        label: 'Polaris Bank',
    },
    {
        value: '101',
        label: 'Providus Bank',
    },
    {
        value: '125',
        label: 'Rubies MFB',
    },
    {
        value: '51310',
        label: 'Sparkle Microfinance Bank',
    },
    {
        value: '221',
        label: 'Stanbic IBTC Bank',
    },
    {
        value: '068',
        label: 'Standard Chartered Bank',
    },
    {
        value: '232',
        label: 'Sterling Bank',
    },
    {
        value: '100',
        label: 'Suntrust Bank',
    },
    {
        value: '302',
        label: 'TAJ Bank',
    },
    {
        value: '51211',
        label: 'TCF MFB',
    },
    {
        value: '102',
        label: 'Titan Bank',
    },
    {
        value: '032',
        label: 'Union Bank of Nigeria',
    },
    {
        value: '033',
        label: 'United Bank For Africa',
    },
    {
        value: '215',
        label: 'Unity Bank',
    },
    {
        value: '566',
        label: 'VFD',
    },
    {
        value: '035',
        label: 'WEMA Bank',
    },
    {
        value: '057',
        label: 'Zenith Bank',
    },
];

export default banks;