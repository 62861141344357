import React from 'react';
import clsx from 'clsx';
import { makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { withRouter } from "react-router-dom";
import SettingsIcon from '@material-ui/icons/Settings';
import LocalGroceryStoreIcon from '@material-ui/icons/LocalGroceryStore';
import ReceiptIcon from '@material-ui/icons/Receipt';
import StorefrontIcon from '@material-ui/icons/Storefront';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import DiscountIcon from '@mui/icons-material/Discount';
import MenuIcon from '@material-ui/icons/Menu';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import Drawer from '@material-ui/core/Drawer';

//import Drawer from '@mui/material/Drawer';
import Avatar from '@mui/material/Avatar';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import BarChartIcon from '@mui/icons-material/BarChart';

import fire from "../Fire.js";

function logout() {
  fire.auth().signOut();
};

function useProfile() {
  const [profile, setProfile] = React.useState({});
  const user = fire.auth().currentUser;

  React.useEffect(() => {
    const unsubscribe = fire
    .firestore()
    .collection("users")
    .doc(user.uid)
    .onSnapshot(document => {
      setProfile(document.data());
    });
    return () => unsubscribe;
  }, [user.uid]);
  
  return profile;
};

const drawerWidth = 240;

const theme = createMuiTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: '#2f55d4',
    },
    secondary: {
      // This is green.A700 as hex.
      main: '#f56565',
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    background: "#2f55d4",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  menuButton1: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  drawer: {

  }
}));

const MyDrawer = props => {
  const { history } = props;
  const profile = useProfile();
  const classes = useStyles();
  const [open] = React.useState(false);
  const [openDrawer, setOpenDrawer] = React.useState(false);



  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    };

    setOpenDrawer(open);
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {/* <ListItem button key={"Analytics"} onClick={() => history.push('/')}>
          <ListItemIcon><BarChartIcon /></ListItemIcon>
          <ListItemText primary={"Analytics"} />
        </ListItem> */}
        <ListItem button key={"Marketplace"} onClick={() => history.push('/')}>
          <ListItemIcon><LocalGroceryStoreIcon /></ListItemIcon>
          <ListItemText primary={"Marketplace"} />
        </ListItem>
        <ListItem button key={"Store"} onClick={() => history.push('/Store')}>
          <ListItemIcon><StorefrontIcon /></ListItemIcon>
          <ListItemText primary={"Store"} />
        </ListItem>
        <ListItem button key={"MyCampaigns"} onClick={() => history.push('/MyCampaigns')}>
          <ListItemIcon><StorefrontIcon /></ListItemIcon>
          <ListItemText primary={"My Campaigns"} />
        </ListItem>
        <ListItem button key={"Products"} onClick={() => history.push('/Products')}>
          <ListItemIcon><LocalOfferIcon /></ListItemIcon>
          <ListItemText primary={"My Products"} />
        </ListItem>
        <ListItem button key={"Orders"} onClick={() => history.push('/Orders')}>
          <ListItemIcon><ReceiptIcon /></ListItemIcon>
          <ListItemText primary={"Orders"} />
        </ListItem>
        <ListItem button key={"Discounts"} onClick={() => history.push('/Discounts')}>
          <ListItemIcon><DiscountIcon /></ListItemIcon>
          <ListItemText primary={"Discount codes"} />
        </ListItem>
        <ListItem button key={"Shipping"} onClick={() => history.push('/Shipping')}>
          <ListItemIcon><LocalShippingIcon /></ListItemIcon>
          <ListItemText primary={"Shipping and delivery"} />
        </ListItem>
        <ListItem button key={"Referrals"} onClick={() => history.push('/Referrals')}>
          <ListItemIcon><DiscountIcon /></ListItemIcon>
          <ListItemText primary={"Referral program"} />
        </ListItem>
        <ListItem button key={"Settings"} onClick={() => history.push('/Settings')}>
          <ListItemIcon><SettingsIcon /></ListItemIcon>
          <ListItemText primary={"Settings"} />
        </ListItem>
        <ListItem button key={"Logout"} onClick={logout}>
          <ListItemIcon><ExitToAppIcon /></ListItemIcon>
          <ListItemText primary={"Logout"} />
        </ListItem>
      </List>
    </div>
  );

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <AppBar position="fixed" className={clsx(classes.appBar, open && classes.appBarShift)}>
            <Toolbar className={classes.toolbar}>
            <Button onClick={toggleDrawer("left", true)} style={{color: "#ffffff"}}><MenuIcon /></Button>
            <Drawer anchor={"left"} variant="temporary" open={openDrawer} onClose={toggleDrawer("left", false)}>
              {list("left")}
            </Drawer>
            <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
              SWHAG
            </Typography>
            <IconButton color="inherit" onClick={() => history.push('/Settings')} >
              <Avatar alt="Logo" src={profile.profilePicUrl} />
              <Typography component="h6" variant="h6" color="inherit" noWrap>
                {profile.businessName}
              </Typography>
            </IconButton>
            
            </Toolbar>
        </AppBar>
      </div>
    </ThemeProvider>
  );
}

export default withRouter(MyDrawer);