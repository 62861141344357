// React Basic and Bootstrap
import React from "react";
import { Link, useHistory } from "react-router-dom";
import { Container, Row, Col, Modal, ModalBody } from "reactstrap";
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';



import Pricing from "./Pricing.js";
import fire from "../Fire.js";
import firebase from 'firebase';

import ReactPixel from 'react-facebook-pixel';
import { PaystackButton } from 'react-paystack';

const options = {
  autoConfig: true, // set pixel's autoConfig
  debug: false, // enable logs
};
ReactPixel.init('782596949083299', options);



function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

function useStore() {
  const [store, setStore] = React.useState({});
  const user = fire.auth().currentUser;

  React.useEffect(() => {
    const unsubscribe = fire
    .firestore()
    .collection("users")
    .doc(user.uid)
    .collection("stores")
    .onSnapshot(querySnapshot => {
      querySnapshot.forEach(doc => {
        setStore(Object.assign(doc.data(), {storeId: doc.id,}));
      })
    });
    return () => unsubscribe;
  }, [user.uid]);
  
  return store;
};

const UpgradePlan = ({profile}) => {
  const user = fire.auth().currentUser;
  let history = useHistory();
  const store = useStore();
  const [open, setOpen] = React.useState(false);
  const [isModalOpen, setOpenModal] = React.useState(false);
  const [isModalCashBackOpen, setOpenCashBackModal] = React.useState(false);
  const [isModalCashPromoOpen, setOpenCashPromoModal] = React.useState(false);
  const [shouldShowPromoModal, setShouldShowPromoModal] = React.useState(false);

  const publicKey = "pk_live_9296b30945eba87a279e3d7ca33cefb6fc2fa261";
  const [plan, setPlanCode] = React.useState(null);
  const [planTitle, setPlanTitle] = React.useState(null);
  const [subaccounts, setSubaccounts] = React.useState([]);

  const [discount, setDiscount] = React.useState(0);
  const [subscriberCount, setSubscriberCount] = React.useState(0);
  const [isReferrar, setIsReferrar] = React.useState(false);

  let payButton;

  const componentProps = {
    email: user.email,
    publicKey,
    plan,
    split: (subaccounts.length > 0) ? {
      type: "flat",
      bearer_type: "account",
      subaccounts: subaccounts,
    } : null,
    onSuccess: () => {
      if (store.isSubscribed === false) {
        fire.firestore().collection("users").doc(user.uid).update({
          isSubscribed: true,
          plan: planTitle,
          lastPaymentDate: firebase.firestore.FieldValue.serverTimestamp(),
        });
        fire.firestore().collection("users").doc(user.uid).collection("stores").doc(store.storeId).update({
          isSubscribed: true,
          plan: planTitle,
          lastPaymentDate: firebase.firestore.FieldValue.serverTimestamp(),
        });
      } else {
      fire.firestore().collection("users").doc(user.uid).update({
        isSubscribed: true,
        plan: planTitle,
        dateSubscribed: firebase.firestore.FieldValue.serverTimestamp(),
        lastPaymentDate: firebase.firestore.FieldValue.serverTimestamp(),
      });
      fire.firestore().collection("users").doc(user.uid).collection("stores").doc(store.storeId).update({
        isSubscribed: true,
        plan: planTitle,
        dateSubscribed: firebase.firestore.FieldValue.serverTimestamp(),
        lastPaymentDate: firebase.firestore.FieldValue.serverTimestamp(),
        heroSliders: [{
          bgImage: "https://firebasestorage.googleapis.com/v0/b/swhag-1a9e3.appspot.com/o/images%2Fcollection%2Ffea1.jpg?alt=media&token=6ed8d9d7-af9f-420e-bfba-3133aedcd99d",
          button: "SHOP COLLECTION",
          url: "/shop/all",
          image: "",
          subtitle: "NEW ARRIVALS",
          title: "All collections"
        }],
        showAbout: true,
        showContact: true,
        showFAQ: true,
        showExplore: false,
      });
      fire.firestore().collection("stores").doc(store.storeId).update({
        isSubscribed: true,
        plan: planTitle,
        dateSubscribed: firebase.firestore.FieldValue.serverTimestamp(),
        lastPaymentDate: firebase.firestore.FieldValue.serverTimestamp(),
        heroSliders: [{
          bgImage: "https://firebasestorage.googleapis.com/v0/b/swhag-1a9e3.appspot.com/o/images%2Fcollection%2Ffea1.jpg?alt=media&token=6ed8d9d7-af9f-420e-bfba-3133aedcd99d",
          button: "SHOP COLLECTION",
          url: "/shop/all",
          image: "",
          subtitle: "NEW ARRIVALS",
          title: "All collections"
        }],
        showAbout: true,
        showContact: true,
        showFAQ: true,
        showExplore: false,
      });
      fire.firestore().collection("users").doc(user.uid).collection("stores").doc(store.storeId).collection("pages").doc("about").set({
        showPage: true,
        showAddress: true,
        showPhoneNumber: true,
        showEmail: true,
        showVideo: true,
        imageUrl: "https://firebasestorage.googleapis.com/v0/b/swhag-1a9e3.appspot.com/o/users%2FZdZhD29GW6fLVtKY4kiISVyH2oz2%2Fstores%2FIqrwd9FT2kEcI1LufrQ9%2Fabout%2FmainImage?alt=media&token=6eb8ba26-dbc9-42ad-8134-7073425cd1dc",
        description: "Lorem ipsum dolor sit amet, consectetur cing elit. Suspe                     ndisse suscipit sagittis leo sit estibulum issim Lorem ipsum                     dolor sit amet, consectetur cing elit. ipsum dolor sit amet,                     consectetur cing elit. Suspe ndisse suscipit sagittis leo                     sit es",
        videoBgImage: "https://firebasestorage.googleapis.com/v0/b/swhag-1a9e3.appspot.com/o/users%2FZdZhD29GW6fLVtKY4kiISVyH2oz2%2Fstores%2FIqrwd9FT2kEcI1LufrQ9%2Fabout%2FvideoBgImage?alt=media&token=441a37a4-a3cc-4d21-9a30-9c8475cb9185",
        videoTitle: "Our story",
        youTubeVideoId: "tdu5JS9OJQs",
        title: "Our story",
        subtitle: "Number 1 e-commerce store",
        email: user.email,
        phoneNumber: "08012345678",
        address: "Nigeria",
      });
      fire.firestore().collection("stores").doc(store.storeId).collection("pages").doc("about").set({
        showPage: true,
        showAddress: true,
        showPhoneNumber: true,
        showEmail: true,
        showVideo: true,
        imageUrl: "https://firebasestorage.googleapis.com/v0/b/swhag-1a9e3.appspot.com/o/users%2FZdZhD29GW6fLVtKY4kiISVyH2oz2%2Fstores%2FIqrwd9FT2kEcI1LufrQ9%2Fabout%2FmainImage?alt=media&token=6eb8ba26-dbc9-42ad-8134-7073425cd1dc",
        description: "Lorem ipsum dolor sit amet, consectetur cing elit. Suspe                     ndisse suscipit sagittis leo sit estibulum issim Lorem ipsum                     dolor sit amet, consectetur cing elit. ipsum dolor sit amet,                     consectetur cing elit. Suspe ndisse suscipit sagittis leo                     sit es",
        videoBgImage: "https://firebasestorage.googleapis.com/v0/b/swhag-1a9e3.appspot.com/o/users%2FZdZhD29GW6fLVtKY4kiISVyH2oz2%2Fstores%2FIqrwd9FT2kEcI1LufrQ9%2Fabout%2FvideoBgImage?alt=media&token=441a37a4-a3cc-4d21-9a30-9c8475cb9185",
        videoTitle: "Our story",
        youTubeVideoId: "tdu5JS9OJQs",
        title: "Our story",
        subtitle: "Number 1 e-commerce store",
        email: user.email,
        phoneNumber: "08012345678",
        address: "Nigeria",
      });
      fire.firestore().collection("users").doc(user.uid).collection("stores").doc(store.storeId).collection("pages").doc("contact").set({
        showPage: true,
        showMap: true,
        showAddress: true,
        showContactDetails: true,
        showHoursOfOperation: true,
        showForm: true,
        title: "Contact details",
        subtitle: "Come have a look",
        hoursOfOperation: "Monday – Friday : 09:00 – 20:00 Sunday & Saturday: 10:30 – 22:00",
        address: "Nigeria",
        mapUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d63429.70734569382!2d3.349673540764497!3d6.476423526320512!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b8b85fe40f7c1%3A0xce5cca20fbf266ab!2sNational%20theatre%20Nigeria!5e0!3m2!1sen!2sng!4v1659098514917!5m2!1sen!2sng",
      });
      fire.firestore().collection("stores").doc(store.storeId).collection("pages").doc("contact").set({
        showPage: true,
        showMap: true,
        showAddress: true,
        showForm: true,
        showContactDetails: true,
        showHoursOfOperation: true,
        title: "Contact details",
        subtitle: "Come have a look",
        hoursOfOperation: "Monday – Friday : 09:00 – 20:00 Sunday & Saturday: 10:30 – 22:00",
        address: "Nigeria",
        mapUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d63429.70734569382!2d3.349673540764497!3d6.476423526320512!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b8b85fe40f7c1%3A0xce5cca20fbf266ab!2sNational%20theatre%20Nigeria!5e0!3m2!1sen!2sng!4v1659098514917!5m2!1sen!2sng",
      });
      fire.firestore().collection("users").doc(user.uid).collection("stores").doc(store.storeId).collection("pages").doc("faq").set({
        showPage: true,
        questions: [
          {
            question: "What Shipping Methods are Available?",
            answer: "Depending on the item(s) you purchase here and the location to which the item(s) will be delivered, different shipping methods will be available. At checkout, you will be prompted to choose a variety of shipping methods.",
          },
          {
            question: "How to Track My Order?",
            answer: "Once your order has been shipped, you will receive an email with your tracking and shipping information. Simply sign in to your account to view a your orders",
          },
          {
            question: "How do I place an Order?",
            answer: "Click on a Product Photo or Product Name to see more detailed information. To place your order, choose the specification you want and enter the quantity, and click ‘Buy Now’.Please enter the required information such as Delivery Address, Quantity Type etc. Before clicking “Place Order”, please check your Order Details carefully. If you want to add a new Delivery Address, click ” Add a new address”. If you want to edit a current Delivery Address, click ‘Edit this address’. After confirming your Order, you will be automatically taken to the Payment page",
          },
          {
            question: "Who Should I Contact If I Have Any Queries?",
            answer: "You can contact our customer support team by email or mobile phone.",
          },
        ],
      });
      fire.firestore().collection("stores").doc(store.storeId).collection("pages").doc("faq").set({
        showPage: true,
        questions: [
          {
            question: "What Shipping Methods are Available?",
            answer: "Depending on the item(s) you purchase here and the location to which the item(s) will be delivered, different shipping methods will be available. At checkout, you will be prompted to choose a variety of shipping methods.",
          },
          {
            question: "How to Track My Order?",
            answer: "Once your order has been shipped, you will receive an email with your tracking and shipping information. Simply sign in to your account to view a your orders",
          },
          {
            question: "How do I place an Order?",
            answer: "Click on a Product Photo or Product Name to see more detailed information. To place your order, choose the specification you want and enter the quantity, and click ‘Buy Now’.Please enter the required information such as Delivery Address, Quantity Type etc. Before clicking “Place Order”, please check your Order Details carefully. If you want to add a new Delivery Address, click ” Add a new address”. If you want to edit a current Delivery Address, click ‘Edit this address’. After confirming your Order, you will be automatically taken to the Payment page",
          },
          {
            question: "Who Should I Contact If I Have Any Queries?",
            answer: "You can contact our customer support team by email or mobile phone.",
          },
        ],
      });
      const deployNetlifySite = fire.functions().httpsCallable('deployNetlifySite');
      deployNetlifySite({storeName: store.storeName, storeId: store.storeId, userUid: user.uid}).then(result => {
        console.log(result.data);
      });
      fire.firestore().collection("subscribers").doc("--stats--").update({
        count: firebase.firestore.FieldValue.increment(1)
      });
      fire.firestore().collection("subscribers").doc(store.storeId).set(Object.assign(store,{subscriberCount: subscriberCount}));
      if (discount > 0) {
        const cashBackEmail = fire.functions().httpsCallable('cashBackEmail');
        cashBackEmail({store: store, email: user.email, discount: discount, subscriberCount: subscriberCount}).then(result => {
          console.log(result.data);
        });
      }
      if(isReferrar === true) {
        fire.firestore().collection("users")
        .doc(store.referrerUserUid).collection("stores")
        .doc(store.referrerStoreId).collection("referrals")
        .doc(store.storeId)
        .update({
          dateSubscribed: firebase.firestore.FieldValue.serverTimestamp(),
          revenue: Number(1000),
        })
      }
      }
      setPlanCode(null);
      setPlanTitle(null);
      ReactPixel.trackSingle('782596949083299', 'Subscription', {currency: "NGN", value: 5999});
      fire.analytics().logEvent("subscribe_premium", { storeName: store.storeName, value: 5999})
    },
    onClose: () => {
    },
  };

  if (plan !== null) {
      payButton = (
        <PaystackButton {...componentProps} className="btn btn-primary">
          Subscribe to {planTitle} plan
        </PaystackButton>
      );
  };


  // const openModal = () => {
  //   setOpenModal(true);
  //   // const dateJoined = new Date(profile.dateJoined.seconds * 1000)
  //   // const cutOffDate = new Date("2022-08-01")
  //   //if (cutOffDate.getTime() > dateJoined.getTime()) {}
  //   fire.firestore().collection("subscribers").doc("--stats--").get().then((doc) => {
  //     setShouldShowPromoModal(true);
  //     const count = doc.data().count;
  //     setSubscriberCount(Number(count)+1)
  //     var discountPercentage = 0;
  //     if (count < 10) {
  //       discountPercentage = 90;
  //     } else if (count < 30) {
  //       discountPercentage = 70;
  //     } else if (count < 70) {
  //       discountPercentage = 50;
  //     } else if (count < 150) {
  //       discountPercentage = 30;
  //     } else if (count < 400) {
  //       discountPercentage = 15;
  //     } else {
  //       discountPercentage = 0;
  //     }
  //     setDiscount(discountPercentage)
  //     if (store.subaccount && (discountPercentage > 0)) {
  //       setSubaccounts([{share: 600000*(discountPercentage/100), subaccount: store.subaccount}])
  //     }
      
  //     if (store.referrer) {
  //       fire.firestore().collection("stores").doc(store.referrerStoreId).get().then((doc) => {
  //         setIsReferrar(true);
  //         if (store.subaccount && doc.data().subaccount) {
  //           if (discountPercentage === 0 ) {
  //             setDiscount(10)
  //             setSubaccounts([{share: 60000, subaccount: store.subaccount}, {share: 100000, subaccount: doc.data().subaccount}])
  //           }
  //           if ((discountPercentage > 0) && (discountPercentage < 51) ) {
  //             setDiscount(discountPercentage)
  //             setSubaccounts([{share: 600000*(discountPercentage/100), subaccount: store.subaccount}, {share: 100000, subaccount: doc.data().subaccount}])
  //           }

  //           if ((discountPercentage > 51) ) {
  //             setDiscount(50)
  //             setSubaccounts([{share: 300000, subaccount: store.subaccount}, {share: 100000, subaccount: doc.data().subaccount}])
  //           }


  //         } else {
  //           if (doc.data().subaccount){
  //             setSubaccounts([{share: 100000, subaccount: doc.data().subaccount}])
  //           }
  //         }
  //       });
  //     }
      
  //   })

  // };
  const openModal = () => {
    setOpenModal(true);
      
      if (store.referrer) {
        fire.firestore().collection("stores").doc(store.referrerStoreId).get().then((doc) => {
          setIsReferrar(true);
          if (store.subaccount && doc.data().subaccount) {
            setDiscount(10)
            setSubaccounts([{share: 60000, subaccount: store.subaccount}, {share: 100000, subaccount: doc.data().subaccount}])

          } else {
            if (doc.data().subaccount){
              setSubaccounts([{share: 100000, subaccount: doc.data().subaccount}])
            }
          }
        });
      }
      

  };

  const closeModal = () => {
    setOpenModal(false);
    // if (shouldShowPromoModal === true) {
    //   setOpenCashPromoModal(true)
    // }
    if (store.subaccount) {
    } else {
      setOpenCashBackModal(true)
    }
  };

  const closeCashBackModal = () => {
    setOpenCashBackModal(false);
  };

  const closePromoModal = () => {
    setOpenCashPromoModal(false);
    if (store.subaccount) {
    } else {
      setOpenCashBackModal(true)
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };


  const changePlan = (plan, title) => {
    setPlanCode(plan)
    setPlanTitle(title)
    closeModal()
  }

  const startFreeTrial = () => {
    fire.firestore().collection("users").doc(user.uid).update({
      plan: "trial",
      lastPaymentDate: firebase.firestore.FieldValue.serverTimestamp(),
    });
    fire.firestore().collection("users").doc(user.uid).collection("stores").doc(store.storeId).update({
      plan: "trial",
      lastPaymentDate: firebase.firestore.FieldValue.serverTimestamp(),
      heroSliders: [{
        bgImage: "https://firebasestorage.googleapis.com/v0/b/swhag-1a9e3.appspot.com/o/images%2Fcollection%2Ffea1.jpg?alt=media&token=6ed8d9d7-af9f-420e-bfba-3133aedcd99d",
        button: "SHOP COLLECTION",
        url: "/shop/all",
        image: "",
        subtitle: "NEW ARRIVALS",
        title: "All collections"
      }],
      showAbout: true,
      showContact: true,
      showFAQ: true,
      showExplore: false,
    });
    fire.firestore().collection("stores").doc(store.storeId).update({
      plan: "trial",
      lastPaymentDate: firebase.firestore.FieldValue.serverTimestamp(),
      heroSliders: [{
        bgImage: "https://firebasestorage.googleapis.com/v0/b/swhag-1a9e3.appspot.com/o/images%2Fcollection%2Ffea1.jpg?alt=media&token=6ed8d9d7-af9f-420e-bfba-3133aedcd99d",
        button: "SHOP COLLECTION",
        url: "/shop/all",
        image: "",
        subtitle: "NEW ARRIVALS",
        title: "All collections"
      }],
      showAbout: true,
      showContact: true,
      showFAQ: true,
      showExplore: false,
    });
    fire.firestore().collection("users").doc(user.uid).collection("stores").doc(store.storeId).collection("pages").doc("about").set({
      showPage: true,
      showAddress: true,
      showPhoneNumber: true,
      showEmail: true,
      showVideo: true,
      imageUrl: "https://firebasestorage.googleapis.com/v0/b/swhag-1a9e3.appspot.com/o/users%2FZdZhD29GW6fLVtKY4kiISVyH2oz2%2Fstores%2FIqrwd9FT2kEcI1LufrQ9%2Fabout%2FmainImage?alt=media&token=6eb8ba26-dbc9-42ad-8134-7073425cd1dc",
      description: "Lorem ipsum dolor sit amet, consectetur cing elit. Suspe                     ndisse suscipit sagittis leo sit estibulum issim Lorem ipsum                     dolor sit amet, consectetur cing elit. ipsum dolor sit amet,                     consectetur cing elit. Suspe ndisse suscipit sagittis leo                     sit es",
      videoBgImage: "https://firebasestorage.googleapis.com/v0/b/swhag-1a9e3.appspot.com/o/users%2FZdZhD29GW6fLVtKY4kiISVyH2oz2%2Fstores%2FIqrwd9FT2kEcI1LufrQ9%2Fabout%2FvideoBgImage?alt=media&token=441a37a4-a3cc-4d21-9a30-9c8475cb9185",
      videoTitle: "Our story",
      youTubeVideoId: "tdu5JS9OJQs",
      title: "Our story",
      subtitle: "Number 1 e-commerce store",
      email: user.email,
      phoneNumber: "08012345678",
      address: "Nigeria",
    });
    fire.firestore().collection("stores").doc(store.storeId).collection("pages").doc("about").set({
      showPage: true,
      showAddress: true,
      showPhoneNumber: true,
      showEmail: true,
      showVideo: true,
      imageUrl: "https://firebasestorage.googleapis.com/v0/b/swhag-1a9e3.appspot.com/o/users%2FZdZhD29GW6fLVtKY4kiISVyH2oz2%2Fstores%2FIqrwd9FT2kEcI1LufrQ9%2Fabout%2FmainImage?alt=media&token=6eb8ba26-dbc9-42ad-8134-7073425cd1dc",
      description: "Lorem ipsum dolor sit amet, consectetur cing elit. Suspe                     ndisse suscipit sagittis leo sit estibulum issim Lorem ipsum                     dolor sit amet, consectetur cing elit. ipsum dolor sit amet,                     consectetur cing elit. Suspe ndisse suscipit sagittis leo                     sit es",
      videoBgImage: "https://firebasestorage.googleapis.com/v0/b/swhag-1a9e3.appspot.com/o/users%2FZdZhD29GW6fLVtKY4kiISVyH2oz2%2Fstores%2FIqrwd9FT2kEcI1LufrQ9%2Fabout%2FvideoBgImage?alt=media&token=441a37a4-a3cc-4d21-9a30-9c8475cb9185",
      videoTitle: "Our story",
      youTubeVideoId: "tdu5JS9OJQs",
      title: "Our story",
      subtitle: "Number 1 e-commerce store",
      email: user.email,
      phoneNumber: "08012345678",
      address: "Nigeria",
    });
    fire.firestore().collection("users").doc(user.uid).collection("stores").doc(store.storeId).collection("pages").doc("contact").set({
      showPage: true,
      showMap: true,
      showAddress: true,
      showContactDetails: true,
      showHoursOfOperation: true,
      showForm: true,
      title: "Contact details",
      subtitle: "Come have a look",
      hoursOfOperation: "Monday – Friday : 09:00 – 20:00 Sunday & Saturday: 10:30 – 22:00",
      address: "Nigeria",
      mapUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d63429.70734569382!2d3.349673540764497!3d6.476423526320512!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b8b85fe40f7c1%3A0xce5cca20fbf266ab!2sNational%20theatre%20Nigeria!5e0!3m2!1sen!2sng!4v1659098514917!5m2!1sen!2sng",
    });
    fire.firestore().collection("stores").doc(store.storeId).collection("pages").doc("contact").set({
      showPage: true,
      showMap: true,
      showAddress: true,
      showForm: true,
      showContactDetails: true,
      showHoursOfOperation: true,
      title: "Contact details",
      subtitle: "Come have a look",
      hoursOfOperation: "Monday – Friday : 09:00 – 20:00 Sunday & Saturday: 10:30 – 22:00",
      address: "Nigeria",
      mapUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d63429.70734569382!2d3.349673540764497!3d6.476423526320512!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b8b85fe40f7c1%3A0xce5cca20fbf266ab!2sNational%20theatre%20Nigeria!5e0!3m2!1sen!2sng!4v1659098514917!5m2!1sen!2sng",
    });
    fire.firestore().collection("users").doc(user.uid).collection("stores").doc(store.storeId).collection("pages").doc("faq").set({
      showPage: true,
      questions: [
        {
          question: "What Shipping Methods are Available?",
          answer: "Depending on the item(s) you purchase here and the location to which the item(s) will be delivered, different shipping methods will be available. At checkout, you will be prompted to choose a variety of shipping methods.",
        },
        {
          question: "How to Track My Order?",
          answer: "Once your order has been shipped, you will receive an email with your tracking and shipping information. Simply sign in to your account to view a your orders",
        },
        {
          question: "How do I place an Order?",
          answer: "Click on a Product Photo or Product Name to see more detailed information. To place your order, choose the specification you want and enter the quantity, and click ‘Buy Now’.Please enter the required information such as Delivery Address, Quantity Type etc. Before clicking “Place Order”, please check your Order Details carefully. If you want to add a new Delivery Address, click ” Add a new address”. If you want to edit a current Delivery Address, click ‘Edit this address’. After confirming your Order, you will be automatically taken to the Payment page",
        },
        {
          question: "Who Should I Contact If I Have Any Queries?",
          answer: "You can contact our customer support team by email or mobile phone.",
        },
      ],
    });
    fire.firestore().collection("stores").doc(store.storeId).collection("pages").doc("faq").set({
      showPage: true,
      questions: [
        {
          question: "What Shipping Methods are Available?",
          answer: "Depending on the item(s) you purchase here and the location to which the item(s) will be delivered, different shipping methods will be available. At checkout, you will be prompted to choose a variety of shipping methods.",
        },
        {
          question: "How to Track My Order?",
          answer: "Once your order has been shipped, you will receive an email with your tracking and shipping information. Simply sign in to your account to view a your orders",
        },
        {
          question: "How do I place an Order?",
          answer: "Click on a Product Photo or Product Name to see more detailed information. To place your order, choose the specification you want and enter the quantity, and click ‘Buy Now’.Please enter the required information such as Delivery Address, Quantity Type etc. Before clicking “Place Order”, please check your Order Details carefully. If you want to add a new Delivery Address, click ” Add a new address”. If you want to edit a current Delivery Address, click ‘Edit this address’. After confirming your Order, you will be automatically taken to the Payment page",
        },
        {
          question: "Who Should I Contact If I Have Any Queries?",
          answer: "You can contact our customer support team by email or mobile phone.",
        },
      ],
    });
    const deployNetlifySite = fire.functions().httpsCallable('deployNetlifySite');
    deployNetlifySite({storeName: store.storeName, storeId: store.storeId, userUid: user.uid}).then(result => {
      console.log(result.data);
    });
    ReactPixel.trackSingle('782596949083299', 'FreeTrial', {currency: "NGN", value: 1});
    fire.analytics().logEvent("free_trial", { storeName: store.storeName,})
    history.push("/")
  }

  return (
    <React.Fragment>
      <section
        className="bg-home d-flex align-items-center"
        style={{ width: "100%", backgroundImage: `url(${"https://firebasestorage.googleapis.com/v0/b/swhag-1a9e3.appspot.com/o/images%2Fcomingsoon%2Fcomingsoon.jpg?alt=media&token=fe409a16-1fab-4482-b54d-0a0d379a5828"})` }}
      >
        <div className="bg-overlay"></div>
        <Container>
          <Row className="justify-content-center">
            <Col lg="8" md="12" className="text-center">
              <div className="text-uppercase title-dark text-white mt-2 mb-4 coming-soon">
                Upgrade to our premium plan to get your fully customizable e-commerce website
              </div>
            </Col>
          </Row>

          <Row>

            {(store.lastPaymentDate) ? 
            (
            <Col md="12" className="text-center">
              {payButton}
              {(plan === null)&&(
              <Link to="#" onClick={openModal} className="btn btn-primary mt-4 me-2" >
                Upgrade plan
              </Link>)}
              {(plan === null)&&(
              <Link to="#" onClick={() => window.open("https://shazzastore.com./")} className="btn btn-primary mt-4 me-2" >
                Preview example website
              </Link>
              )}
            </Col>
            ) : (
              <Col md="12" className="text-center">
                <Link to="#" onClick={startFreeTrial} className="btn btn-primary mt-4 me-2" >
                  Start 7 day Free Trial
                </Link>
                <Link to="#" onClick={() => window.open("https://shazzastore.com./")} className="btn btn-primary mt-4 me-2" >
                  Preview example website
                </Link>
              </Col>
              )
            }
          </Row>
          <Row className="justify-content-center">
            <Col lg="8" md="12" className="text-center">
              <h4 className="text-light para-desc para-dark mx-auto">
                Manage your wesbite from the {" "}
                <span className="text-primary fw-bold">
                  SWHAG
                </span>{" "}
                mobile app
              </h4>
              <p className="text-light para-desc para-dark mx-auto">
                Download our app and get started
              </p>
            </Col>
            <div className="mt-4" style={{display: 'flex', justifyContent: 'center'}}>
              <Link to="//apps.apple.com/gb/app/swhag/id1602909385" target="_blank" rel="noopener noreferrer" className="btn btn-primary mt-2 me-2">
                <i className="uil uil-apple"></i> Apple Store
              </Link>
              <Link to="//play.google.com/store/apps/details?id=co.swhag.app&hl=en_AU&gl=US"  target="_blank" rel="noopener noreferrer" className="btn btn-primary mt-2 me-2">
                <i className="uil uil-google-play"></i> Play Store
              </Link>
            </div>
          </Row>
          <Modal isOpen={isModalOpen} toggle={closeModal} centered size="xl">
            <Pricing changePlan={changePlan}/>
          </Modal>
          <Modal isOpen={isModalCashBackOpen} toggle={closeCashBackModal} centered size="lg">
            <ModalBody className="p-4">
              <Container fluid className="px-0">
                <h4 className="card-title">Get cashback</h4>
                <Typography sx={{ mt: 2, mb: 1 }}>
                  You are eligible for a cashback but we don't have correct bank account details from you.
                </Typography>
                <br/>
                <Typography sx={{ mt: 2, mb: 1 }}>
                  If you recently created your store then no need to worry we must still be processing your bank account details, simply come back and try again in 5 minutes
                </Typography>
                <br/>
                <Typography sx={{ mt: 2, mb: 1 }}>
                  If it has been longer than an hour since you created your store then there was an error processing your bank account details, please download our app and resolve this issue so we can process your cashback.
                </Typography>
                <div onClick={closeCashBackModal} className="btn btn-soft-primary ms-2">
                  Close
                </div>
              </Container>
            </ModalBody>
          </Modal>
          <Modal isOpen={isModalCashPromoOpen} toggle={closePromoModal} centered size="lg">
            <ModalBody className="p-4">
              <Container fluid className="px-0">
                <h4 className="card-title">Special Offer</h4>
                <Typography sx={{ mt: 2, mb: 1 }}>
                  Get up to 90% OFF on the premium plan
                </Typography>
                <Typography sx={{ mt: 2, mb: 1 }}>
                  We are running a special sale on our premium plan. We are giving this discount in the form of a cashback.
                  To ensure you recieve your cashback please download our app and check we have your preferred bank account details before purchasing. 
                </Typography>
                <Typography sx={{ mt: 2, mb: 1 }}>
                  Why cashback ?
                  <br/>
                  As this is a subscription we are unable to decrease the price of the plan but we can offer you a cashback the next working day.
                </Typography>
                <img src={"https://firebasestorage.googleapis.com/v0/b/swhag-1a9e3.appspot.com/o/images%2Fwebsite%2Fdc67a754-6d26-488f-b066-cbb7f48fcb07.JPG?alt=media&token=23014728-b3c8-4043-8504-0f3d14aa4c0f"} className="img-fluid rounded" alt="" />
                <div onClick={closePromoModal} className="btn btn-soft-primary ms-2">
                  Close
                </div>
              </Container>
            </ModalBody>
          </Modal>
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success">
              Your request has been sent successfully!
            </Alert>
          </Snackbar>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default UpgradePlan;
